import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  Card,
  CardHeader,
  Avatar,
  CardActions,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { Image } from '@mui/icons-material';
import { assocPath } from 'ramda';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import { getObjectPath } from 'utils/object';
import ImageField from './ImageField';
import { PdfPreview } from '@medely/web-components';

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = reject;
  });

const getImageSource = (source?: string, dataType?: string) => {
  if (source?.startsWith('https:')) {
    return source;
  }
  if (!dataType || source?.startsWith('data:')) {
    return source;
  }
  return `${dataType},${source}`;
};

const ImageInput = ({ source, defaultValue, label, aspectRatio, extensionSource }: any) => {
  const { setValue, watch } = useFormContext();
  const formValues = watch();
  const imageSource = getObjectPath(formValues, source.split('.'), defaultValue);
  const [dataType, setDataType] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const cropperRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pickerImgSrc = getImageSource(imageSource, dataType);
  const isPdf =
    pickerImgSrc?.includes('application/pdf') || pickerImgSrc?.toLowerCase().includes('.pdf');
  const imageRecord = assocPath(source.split('.'), pickerImgSrc, formValues ?? {});

  const handleEdit = () => setOpen(true);
  const handleFileSelect = async (event) => {
    const file = event.target?.files[0];
    if (!file) {
      return;
    }
    const base64 = await toBase64(file);
    setValue(source, base64, { shouldDirty: true });
    handleEdit();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemove = () => {
    setValue(source, null, { shouldDirty: true });
    if (extensionSource) {
      setValue(extensionSource, null);
    }
  };

  const handleRotate = () => cropperRef.current?.cropper.rotate(-90);

  const handleSave = () => {
    const isCropped = cropperRef.current && !isPdf;
    if (isCropped) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas() as HTMLCanvasElement;
      const [dataType, base64] = canvas.toDataURL(`image/png`, 0.8).split(',');
      setValue(source, base64, { shouldDirty: true });
      setDataType(dataType);
      if (extensionSource) {
        setValue(extensionSource, 'png');
      }
      setOpen(false);
    } else {
      setValue(source, pickerImgSrc.replace('data:', '').replace(/^.+,/, ''), {
        shouldDirty: true,
      });
      setDataType('data:application/pdf;base64');
      if (extensionSource) {
        setValue(extensionSource, 'pdf');
      }
      setOpen(false);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Card elevation={0} style={{ border: '1px solid #ddd' }}>
          <CardHeader
            title={label}
            avatar={
              <Avatar>
                <Image />
              </Avatar>
            }
          />
          {isPdf ? (
            <PdfPreview src={pickerImgSrc} />
          ) : (
            <ImageField record={imageRecord} source={source} extensionSource={extensionSource} />
          )}
          <CardActions>
            {imageSource && !isPdf && (
              <Button onClick={handleEdit} size="small">
                Edit
              </Button>
            )}
            <Button
              onClick={() => {
                fileInputRef.current?.click();
              }}
              size="small"
            >
              {imageSource ? 'Replace' : 'Upload'}
            </Button>
            {imageSource && (
              <Button onClick={handleRemove} size="small">
                Remove
              </Button>
            )}
          </CardActions>
        </Card>
        <input
          hidden
          accept="image/*, .pdf"
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
        />
      </Grid>
      <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          {isPdf ? (
            <PdfPreview src={pickerImgSrc} />
          ) : (
            <Cropper
              aspectRatio={aspectRatio}
              ref={cropperRef}
              src={pickerImgSrc}
              checkCrossOrigin={true}
              checkOrientation={false}
              style={{ maxHeight: '80vh', maxWidth: '100%' }}
              guides={false}
            />
          )}
        </DialogContent>
        <DialogActions>
          {!isPdf && (
            <IconButton color="primary" onClick={handleRotate} size="large">
              <Rotate90DegreesCcwIcon />
            </IconButton>
          )}
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageInput;

import List from './List';
import Edit from './Edit';
import Create from './Create';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'invoices',
  inputShow: gql`
    query invoices($input: InvoiceQueryInput) {
      invoices(input: $input) {
        id
        billing_group_id
        billing_group {
          name
        }
        statement_id
        assignment_id
        total_cents
        amount_due_cents
        status
        due_date
        paid_date
        days_late
        voucher_id
        __typename
      }
    }
  `,
  list: List,
  edit: Edit,
  show: Show,
  create: Create,
  options: {
    group: 'Billing',
    label: 'Invoices',
  },
};

import { identity } from 'ramda';
import axios from 'axios';
import config from 'config';
import { auth } from 'data_provider/utils';

const getToken = async function (config) {
  const token = await auth.currentUser?.getIdToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

const getAxios = () => {
  const axiosInstance = axios.create({ baseURL: config.rails_url });
  axiosInstance.interceptors.request.use(getToken, function (error) {
    return Promise.reject(error);
  });
  axiosInstance.interceptors.response.use(identity, (error) => {
    const errorMessage =
      error.response?.data?.message ??
      error.response?.data?._error ??
      error.message ??
      'Something went wrong';
    error.message = errorMessage;
    return Promise.reject(error);
  });
  return axiosInstance;
};

export default getAxios;

import React from 'react';
import { TextField, Datagrid, ReferenceManyField, Pagination, SimpleShowLayout } from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import IdField from 'components/IdField';

const PayoutsTab = () => {
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="Payout"
        target="assignment_id"
      >
        <Datagrid bulkActionButtons={false}>
          <IdField label="Payout ID" sortBy="id" />
          <DateTimeField label="Starts Time" source="starts_time" />
          <DateTimeField label="Ends Time" source="ends_time" />
          <DateTimeField label="W2 Submit by Time" source="w2_submit_by_time" />
          <TextField label="ADP ID" source="adp_id" />
          <TextField label="Stripe ID" source="stripe_id" />
          <TextField label="Stripe Transfer ID" source="stripe_transfer_id" />
          <DateTimeField label="Arrival Date" source="arrival_date" />
          <DateTimeField label="Processed On" source="processed_on" />
          <TextField label="Status" source="status" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(PayoutsTab);

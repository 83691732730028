import React from 'react';
import { AutocompleteArrayInput, Identifier, required } from 'react-admin';

interface AccountPermissionLocationInputProps {
  disabled: boolean;
  choices: { id: Identifier; name: string }[];
}

const AccountPermissionLocationInput = ({
  disabled,
  choices,
}: AccountPermissionLocationInputProps) => (
  <div style={{ position: 'relative' }}>
    {/* The disable props doesn't work. This element is to cover the input and making it unselectable */}
    {disabled && (
      <div
        style={{
          height: '100%',
          width: '100%',
          zIndex: 1,
          position: 'absolute',
          cursor: 'not-allowed',
        }}
      />
    )}
    <AutocompleteArrayInput
      key={`location_ids-${disabled}`}
      source="location_ids"
      choices={choices}
      disabled={disabled}
      validate={disabled ? undefined : required()}
      data-testid="account-permission_group_location"
    />
  </div>
);

export default AccountPermissionLocationInput;

import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import DisputesTab from 'components/tabs/DisputesTab';
import InvoiceShowActions from './ShowActions';
import ItemizedTransactionsTab from './tabs/ItemizedTransactionsTab';
import JobBillingsTab from 'components/tabs/JobBillingsTab';
import React from 'react';
import RevenueAdjustmentsTab from 'components/tabs/RevenueAdjustmentsTab';
import SummaryTab from './tabs/SummaryTab';
import { gql } from '@apollo/client';
import useCheckAccess from 'hooks/useCheckAccess';
import NotesTab from 'components/tabs/NotesTab';
import TimecardsTab from 'components/tabs/TimecardsTab';

const InvoicesShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<InvoiceShowActions />} data-testid="invoices-show">
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Summary">
          <SummaryTab />
        </Tab>
        <Tab label="Itemized Transactions" path="itemized-transactions">
          <ItemizedTransactionsTab />
        </Tab>
        {checkAccess('view', 'RevenueAdjustment', 'invoice_id') && (
          <Tab label="Revenue Adjustments" path="revenue-adjustments">
            <RevenueAdjustmentsTab target="invoice_id" />
          </Tab>
        )}
        {checkAccess('view', 'JobBilling', 'invoice_id') && (
          <Tab label="Job Billings" path="job-billings">
            <JobBillingsTab target="invoice_id" />
          </Tab>
        )}
        {checkAccess('view', 'Dispute', 'invoice_id') && (
          <Tab label="Disputes" path="disputes">
            <DisputesTab target="invoice_id" />
          </Tab>
        )}
        <Tab label="Notes" path="notes">
          <NotesTab />
        </Tab>
        <Tab label="Timecards" path="timecards">
          {checkAccess('view', 'Timecard', 'id') && <TimecardsTab />}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

InvoicesShow.query = gql`
  query GET_ONE($id: Int!) {
    invoice(id: $id) {
      id
      paid_date
      status
      created_at
      updated_at
      locked_at
      due_date
      total_cents
      amount_due_cents
      starts_time
      failed_reason
      failed_count
      timecard_ids
      ends_time
      processing_fee_cents
      stripe_invoice_number
      voucher_id
      voucher_processing_status
      assignment_id
      billing_group_id
      invoice_locked_balance_sheet_id
      invoice_paid_balance_sheet_id
      statement {
        id
        close_time
      }
      billing_group {
        plan
      }
    }
  }
`;

export default InvoicesShow;

import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import PolymorphicField from 'components/PolymorphicField';

const NotesShow = () => (
  <Show data-testid="notes-show">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField label="Author" source="author.account.name" />
      <PolymorphicField source="recipient" />
      <TextField source="text" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

NotesShow.query = gql`
  query GET_ONE($id: Int!) {
    note(id: $id) {
      id
      text
      recipient_type
      recipient_id
      recipient {
        ... on BillingGroup {
          id
          name
        }
        ... on Location {
          id
          name
        }
        ... on Professional {
          id
          name: nickname
        }
        ... on FinancialTransaction {
          id
          name: kind
        }
      }
      author_id
      author {
        id
        account {
          name
        }
      }
      created_at
      updated_at
    }
  }
`;

export default NotesShow;

import React from 'react';
import { Button, Dialog, DialogTitle, MenuItem } from '@mui/material';
import { DateTimeInput, SimpleForm, Toolbar } from 'react-admin';
import useDialog from 'hooks/useDialog';
import { PhoneScreenScheduledStatus } from '../ApplicationStatusControl';
import AdministratorInput from 'components/Filter/AdministratorInput';
import { ENUMS } from 'components/Enum/enums';
import { useFormContext } from 'react-hook-form';

interface PhoneScreenScheduledItemProps {
  updateApplicationStatus: (val: any) => void;
}

const PhoneScreenScheduledItem = ({ updateApplicationStatus }: PhoneScreenScheduledItemProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  return (
    <>
      <MenuItem onClick={openDialog}>Move to Phone Screen Scheduled (Manual)</MenuItem>
      <SchedulePhoneScreenDialog
        open={open}
        closeDialog={closeDialog}
        updateApplicationStatus={updateApplicationStatus}
      />
    </>
  );
};

const SchedulePhoneScreenToolBar = ({ closeDialog }) => {
  const { getValues } = useFormContext();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={closeDialog}>Cancel</Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!getValues().screening_recruiter_id || !getValues().phone_screen_date_and_time}
      >
        Submit
      </Button>
    </Toolbar>
  );
};

const SchedulePhoneScreenDialog = ({ open, closeDialog, updateApplicationStatus }) => {
  const submitUpdateApplicationStatus = ({
    screening_recruiter_id,
    phone_screen_date_and_time,
  }: any) => {
    updateApplicationStatus({
      application_status: PhoneScreenScheduledStatus,
      screening_recruiter_id,
      phone_screen_date_and_time,
    });
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Schedule Phone Screening</DialogTitle>
      <SimpleForm
        onSubmit={submitUpdateApplicationStatus}
        toolbar={<SchedulePhoneScreenToolBar closeDialog={closeDialog} />}
      >
        <AdministratorInput
          source="screening_recruiter_id"
          label="Screening Recruiter"
          filter={{
            role: [ENUMS.Administrator.role.support_agent],
          }}
          emptyText="Clear"
          fullWidth
        />
        <DateTimeInput
          label="Phone Screen Date And Time"
          source="phone_screen_date_and_time"
          fullWidth
        />
      </SimpleForm>
    </Dialog>
  );
};

export default PhoneScreenScheduledItem;

import React from 'react';
import { gql } from '@apollo/client';
import {
  SelectInput,
  BooleanInput,
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
} from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import ReferenceInput from 'components/ReferenceInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import VmsTypeInput from 'components/VmsTypeInput';
import HealthSystemInput from 'components/HealthSystemInput';
import MspInput from 'components/MspInput';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';

const BillingGroupsFilters = [
  <IdInput />,
  <EnumSelectInput alwaysOn source="plan" label="Plan" />,
  <ReferenceInput
    alwaysOn
    emptyText="All"
    label="Location Type"
    source="search.location_type_ids"
    reference="LocationType"
  >
    <SelectInput optionText="label" label="Location Type" />
  </ReferenceInput>,
  <VmsTypeInput />,
  <HealthSystemInput />,
  <MspInput />,
  <BooleanInput alwaysOn source="search.no_vms" label="Not A VMS" />,
  <BooleanInput alwaysOn source="search.has_balance" label="Has Balance" />,
  <TextInput label="Referral" source="search.referral" />,
  <TextInput label="UTM Source" source="search.utm_source" />,
  <TextInput label="UTM Campaign" source="search.utm_campaign" />,
  <TextInput alwaysOn label="Stripe ID" source="search.stripe_customer_id" />,
];

const BillingGroupsList = () => (
  <List hasCreate exporter={false} filters={BillingGroupsFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField resource="Company" source="company_id" />
      <TextField source="name" />
      <TextField source="status" />
      <TextField source="plan" />
      <TextField source="billing" />
      <BooleanField source="auto_pay" />
    </Datagrid>
  </List>
);

BillingGroupsList.query = gql`
  query GET_LIST($input: BillingGroupQueryInput) {
    _billingGroupsMeta(input: $input) {
      count
    }
    billingGroups(input: $input) {
      id
      name
      company_id
      status
      plan
      billing
      auto_pay
      locations {
        id
        name
      }
    }
  }
`;

export default BillingGroupsList;

import { gql } from '@apollo/client';
import dataProvider from './data_provider';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './data_provider/utils';
import { AuthProvider, UserIdentity } from 'react-admin';

const authQuery = gql`
  query AUTH {
    me {
      id
      account_type
      calculated_permissions
    }
  }
`;

/**
 * https://marmelab.com/react-admin/Authentication.html
 */

declare global {
  interface Window {
    Cypress: unknown;
  }
}

const getUser = async () => {
  const response = await dataProvider.client.query({
    fetchPolicy: 'network-only',
    query: authQuery,
  });
  const user = response.data.me;
  const role = user?.account_type ?? '';

  if (role === 'administrator') {
    return user;
  } else {
    throw new Error(`Return to https://medely.com/ to log in as a ${role}.`);
  }
};

const getPermissions = async () => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  } else {
    const userData = await getUser();
    localStorage.setItem('user', JSON.stringify(userData));
    return userData;
  }
};

const authProvider: AuthProvider = {
  login: async (params) => {
    const { username, password } = params;
    const userCredential = await signInWithEmailAndPassword(auth, username, password);
    await getUser();
    return userCredential.user;
  },
  logout: async (_options = {}, force = false) => {
    if (force || auth.currentUser) {
      await signOut(auth);
      localStorage.removeItem('user');
    }
  },
  checkAuth: () => {
    return new Promise((resolve, reject) => {
      if (auth.currentUser || !!window.Cypress) {
        getPermissions().then(resolve).catch(reject);
      } else {
        onAuthStateChanged(auth, (aUser) => {
          if (aUser) {
            getPermissions().then(resolve).catch(reject);
          } else {
            if (window.location.pathname === '/login') {
              return reject('/');
            }
            return reject();
          }
        });
      }
    });
  },
  getIdentity: () => {
    return new Promise((resolve, reject) => {
      if (auth.currentUser) {
        resolve(auth.currentUser as unknown as UserIdentity);
      } else {
        onAuthStateChanged(auth, (aUser) => {
          if (aUser) {
            resolve(aUser as unknown as UserIdentity);
          } else {
            reject();
          }
        });
      }
    });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    return new Promise((resolve, reject) => {
      if (auth.currentUser) {
        getPermissions().then(resolve);
      } else {
        onAuthStateChanged(auth, (aUser) => {
          if (aUser || !!window.Cypress) {
            getPermissions().then(resolve);
          } else {
            reject('invalid role');
          }
        });
      }
    });
  },
};

export default authProvider;

import { getResourceQueries } from './utils';
import { ApolloClient, gql } from '@apollo/client';
import inflection from 'inflection';

export default function getInputList(client: ApolloClient<any>) {
  return async (resource, params) => {
    const { search, pagination, sort, filter } = params;
    const { _input, search: filterSearch, q, ...restFilter } = filter;
    const queries = getResourceQueries(resource);
    const queryName = inflection.camelize(inflection.pluralize(resource), true);
    const query = gql`
    query ${queryName}($input: ${resource}QueryInput) {
      ${queryName}(input: $input) {
        id
        ${typeof _input.optionText === 'string' ? _input.optionText : ''}
        ${_input.gql ? _input.gql.join('\n') : ''}
      }
    }
  `;

    const response = await client.query({
      query,
      variables: {
        input: {
          search: { ...search, ...filterSearch, q },
          page: pagination ? pagination.page : 1,
          perPage: pagination ? pagination.perPage : 25,
          filter: restFilter,
          orderBy: sort
            ? {
                [sort.field]: sort.order === 'ASC',
              }
            : undefined,
        },
      },
    });

    const data = response.data[queries.list.name];
    return {
      data,
      total: data.length,
    };
  };
}

import React from 'react';
import { Button, Dialog, DialogTitle, MenuItem } from '@mui/material';
import { SimpleForm, Toolbar, required } from 'react-admin';
import useDialog from 'hooks/useDialog';
import { ApplicationStatus, ApprovedStatus } from '../ApplicationStatusControl';
import AdministratorInput from 'components/Filter/AdministratorInput';
import { ENUMS } from 'components/Enum/enums';
import { useFormContext } from 'react-hook-form';
import { ProfessionalStatusEnum } from 'typings/enums';

enum ApproveLabel {
  resume_review = 'Approve (Written Screen)',
  phone_screen_pending = 'Approve (Written Screen)',
}
enum ApproveConfirmationLabel {
  resume_review = 'Approve as Written Screen?',
  phone_screen_pending = 'Approve as Written Screen?',
}

interface ApproveItemProps {
  currentApplicationStatus: ApplicationStatus;
  updateApplicationStatus: (val: any) => void;
}

const ApproveItem = ({ currentApplicationStatus, updateApplicationStatus }: ApproveItemProps) => {
  const { open, closeDialog, openDialog } = useDialog();
  return (
    <>
      <MenuItem onClick={openDialog}>
        {ApproveLabel[currentApplicationStatus] ?? 'Approve'}
      </MenuItem>
      <ApproveDialog
        open={open}
        closeDialog={closeDialog}
        updateApplicationStatus={updateApplicationStatus}
        currentApplicationStatus={currentApplicationStatus}
      />
    </>
  );
};

const ApproveToolBar = ({ closeDialog }) => {
  const { getValues } = useFormContext();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={closeDialog}>Cancel</Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!getValues().screening_recruiter_id}
      >
        Submit
      </Button>
    </Toolbar>
  );
};

const ApproveDialog = ({
  open,
  closeDialog,
  updateApplicationStatus,
  currentApplicationStatus,
}) => {
  const submitUpdateApplicationStatus = ({ screening_recruiter_id }: any) => {
    updateApplicationStatus({
      application_status: ApprovedStatus,
      screening_recruiter_id,
      status: ProfessionalStatusEnum.active,
      phone_interviewed: !(currentApplicationStatus in ApproveLabel),
    });
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>
        {ApproveConfirmationLabel[currentApplicationStatus] ?? 'Approve application?'}
      </DialogTitle>
      <SimpleForm
        onSubmit={submitUpdateApplicationStatus}
        toolbar={<ApproveToolBar closeDialog={closeDialog} />}
      >
        <AdministratorInput
          source="screening_recruiter_id"
          label="Screening Recruiter"
          validate={required()}
          filter={{
            role: [ENUMS.Administrator.role.support_agent],
          }}
          alwaysOn
          emptyText="Clear"
        />
      </SimpleForm>
    </Dialog>
  );
};

export default ApproveItem;

import React from 'react';
import {
  ResourceContextProvider,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
} from 'react-admin';
import { Box, Button, Dialog, Typography } from '@mui/material';
import inflection from 'inflection';
import create from 'utils/api/create';
import { getResourceUrl } from 'data_provider/utils';

interface Props {
  initialValues?: object;
  resource?: string;
  children: React.ReactNode | React.ReactNode[];
  title?: string;
  subheader?: string;
  transform?: (values: any) => object;
  url?: string;
  buttonText?: string;
}

const QuickCreateButton = ({
  initialValues: defaultValues = {},
  resource = '',
  children,
  title = `Create ${humanize(resource ?? '')}`,
  subheader,
  transform,
  url,
  buttonText = '+ Create',
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const createResource = async (values) => {
    try {
      const payload = transform?.(values) ?? values;
      await create(url ?? getResourceUrl(resource), payload, resource);
      notify('Save was successful', { type: 'success' });
      refresh();
      setIsOpen(false);
    } catch (error: any) {
      notify(`${title} error: ${error.message}`, { type: 'warning' });
    }
  };

  return (
    <ResourceContextProvider value={resource}>
      <>
        <Button
          size="small"
          onClick={() => setIsOpen(true)}
          variant="outlined"
          color="primary"
          sx={{ width: 'fit-content' }}
          data-testid="quick-create-button"
        >
          {buttonText}
        </Button>

        <Dialog open={isOpen} fullWidth maxWidth="sm">
          <Typography variant="h6" px={3} pt={3} data-testid="quick-create-button-dialog-header">
            {title}
          </Typography>
          {subheader && (
            <Typography
              px={3}
              color="textSecondary"
              data-testid="quick-create-button-dialog-sub-header"
            >
              {subheader}
            </Typography>
          )}
          <SimpleForm
            record={{}}
            onSubmit={createResource}
            defaultValues={defaultValues}
            toolbar={
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <SaveButton label="Submit" data-testid="quick-create-button-dialog-save-button" />
                <Button
                  onClick={() => setIsOpen(false)}
                  data-testid="quick-create-button-dialog-cancel-button"
                >
                  Cancel
                </Button>
              </Toolbar>
            }
          >
            {React.Children.map(children, (child) => {
              return React.isValidElement(child) && child.props.fullWidth ? (
                <Box width="100%">{child}</Box>
              ) : (
                <Box>{child}</Box>
              );
            })}
          </SimpleForm>
        </Dialog>
      </>
    </ResourceContextProvider>
  );
};

export default QuickCreateButton;

const humanize = (str: string) => inflection.humanize(inflection.underscore(str));

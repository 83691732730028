import React from 'react';
import { gql } from '@apollo/client';
import { Show } from 'react-admin';
import RateSettingsShow from '../RateSettingsShow';

const LocationRateSettingShow = () => (
  <Show>
    <RateSettingsShow />
  </Show>
);

LocationRateSettingShow.query = gql`
  query locationRateSetting($id: Int!) {
    locationRateSetting(id: $id, include_parents: true) {
      id
      location_id
      active
      settings
      updated_at
    }
  }
`;

export default LocationRateSettingShow;

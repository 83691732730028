import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  Select,
  TextField,
  MenuItem,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useInput, required, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
  getSelectedMultiSelectValue,
  getSelectedSingleSelectValue,
} from 'resources/posting_fields/utils';

export interface IPostingField {
  field_type: string;
  id: number;
  label: string;
  required: boolean;
  show_on_assignment: boolean;
  show_on_per_diem: boolean;
  slug: string;
  description: string;
  constraints?: { label: string }[];
}

interface PostingFieldProps {
  postingField: IPostingField;
  defaultValue: string | undefined;
  hasError: boolean;
}

const PostingField = ({
  postingField,
  defaultValue,
  hasError,
}: PostingFieldProps): JSX.Element | null => {
  const {
    slug,
    label,
    required: fieldIsRequired,
    field_type,
    description,
    constraints,
  } = postingField;
  const source = `posting_field_contents.${slug}`;
  const {
    field: { onChange },
    isRequired,
  } = useInput({
    source,
    validate: fieldIsRequired ? [required()] : undefined,
    isRequired: fieldIsRequired,
  });
  const translate = useTranslate();
  const { getValues } = useFormContext();

  const handleChange = (value) => {
    onChange({
      label,
      description,
      content: value,
    });
  };
  const fieldLabel = fieldIsRequired ? `${label}*` : label;
  const value = getValues()?.posting_field_contents?.[slug]?.content;

  const renderErrorText = () => (
    <FormHelperText>{hasError && fieldIsRequired && translate('Required')}</FormHelperText>
  );

  const renderConstraints = () => {
    return constraints?.map((constraint) => (
      <MenuItem key={`${slug}-${constraint.label}`} value={constraint.label}>
        {constraint.label}
      </MenuItem>
    ));
  };

  useEffect(() => {
    // Set toggle value to false if defaultValue doesn't exist
    if (field_type === 'boolean' && !defaultValue) {
      handleChange('f');
    }
  }, []);

  switch (field_type) {
    case 'string':
      return (
        <FormControl required={isRequired} error={hasError}>
          <TextField
            fullWidth
            label={fieldLabel}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={defaultValue}
          />
          {renderErrorText()}
        </FormControl>
      );
    case 'number':
      return (
        <FormControl required={isRequired} error={hasError}>
          <TextField
            type="number"
            fullWidth
            label={fieldLabel}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={defaultValue}
          />
          {renderErrorText()}
        </FormControl>
      );
    case 'boolean':
      return (
        <FormControl required={isRequired} error={hasError}>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handleChange(e.target.checked ? 't' : 'f')}
                defaultChecked={defaultValue === 't'}
              />
            }
            label={fieldLabel}
          />
        </FormControl>
      );
    case 'single_select':
      return (
        <FormControl required={isRequired} error={hasError}>
          <FormControlLabel
            label={fieldLabel}
            control={
              <Select
                label={fieldLabel}
                value={getSelectedSingleSelectValue(value)}
                onChange={(e) => {
                  onChange({
                    label,
                    description,
                    content: JSON.stringify([e.target.value]),
                  });
                }}
              >
                {renderConstraints()}
              </Select>
            }
          />
          {renderErrorText()}
        </FormControl>
      );
    case 'multi_select':
      return (
        <FormControl required={isRequired} error={hasError}>
          <FormControlLabel
            label={fieldLabel}
            control={
              <Select
                multiple
                label={fieldLabel}
                value={getSelectedMultiSelectValue(value)}
                onChange={(e) => {
                  onChange({
                    label,
                    description,
                    content: JSON.stringify(e.target.value),
                  });
                }}
              >
                {renderConstraints()}
              </Select>
            }
          />
          {renderErrorText()}
        </FormControl>
      );
    default:
      return null;
  }
};

export default PostingField;

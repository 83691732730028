import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { BooleanField, Datagrid, BooleanInput } from 'react-admin';
import List from 'components/List';
import PositionInput from 'components/PositionInput';
import StateInput from 'components/StateInput';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';

const StatePositionRateSettingListFilters = [
  <IdInput />,
  <PositionInput emptyText="All" alwaysOn />,
  <StateInput label="State" source="state_id" alwaysOn resettable />,
  <BooleanInput alwaysOn source="active" />,
];

const StatePositionRateSettingList = () => (
  <List filters={StatePositionRateSettingListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <LinkField label="State" source="state.name" />
      <LinkField label="Position" source="position.display_name" />
      <LinkField label="Specialty" source="specialty.label" />
      <MoneyField label="Charge Rate" source="settings.charge_rate_cents" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

StatePositionRateSettingList.query = gql`
  query statePositionRateSettings($input: StatePositionRateSettingQueryInput) {
    _statePositionRateSettingsMeta(input: $input) {
      count
    }
    statePositionRateSettings(input: $input) {
      id
      state_id
      state_position_rate_setting_id
      state_position_rate_setting_active
      position_id
      specialty_id
      state {
        id
        name
      }
      position {
        id
        display_name
      }
      specialty {
        id
        label
      }
      settings {
        fee_percent
        w2_fee_percent
        charge_rate_cents
        margin_percent_1099
        margin_w2_tcv_cents
        on_call_fee_percent
        margin_percent_w2_tcv
        margin_percent_w2_local
        margin_percent_w2_travel
        charge_overtime_multiplier
        payout_overtime_multiplier
        charge_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        payout_on_call_hourly_rate_currency
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        w2
        ten99_assignment
        ten99_per_diem
      }
      active
    }
  }
`;

export default StatePositionRateSettingList;

import React from 'react';
import { TopToolbar, EditButton, useRecordContext } from 'react-admin';
import ApplyUserBalanceButton from './ApplyUserBalanceButton';
import { GetAppOutlined } from '@mui/icons-material';
import PdfButton from 'components/PdfButton';
import { Button } from '@mui/material';
import config from 'config';
import useRequestCsv from 'hooks/useRequestCsv';
import EditVoucherIdButton from './EditVoucherIdButton';

const InvoiceShowActions = () => {
  const record = useRecordContext();
  const downloadCSV = useRequestCsv();
  if (!record) {
    return null;
  }

  const showEditButton = !record.locked_at;
  const showEditVoucherIdButton = record.locked_at && record.billing_group.plan === 'enterprise_w2';

  return (
    <TopToolbar
      sx={{
        justifyContent: 'flex-start',
      }}
    >
      <ApplyUserBalanceButton />
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() =>
          downloadCSV({
            filename: `invoice_${record.id}`,
            url: `${config.rails_url}/v3/admin/invoices/${record.id}.csv`,
          })
        }
        startIcon={<GetAppOutlined />}
      >
        CSV
      </Button>
      <PdfButton
        size="small"
        variant="outlined"
        color="primary"
        href={`/invoices/${record.id}`}
        download={`invoice_${record.id}.pdf`}
        startIcon={<GetAppOutlined />}
      >
        PDF
      </PdfButton>
      {showEditButton && <EditButton />}
      {showEditVoucherIdButton && <EditVoucherIdButton invoice={record} />}
    </TopToolbar>
  );
};

export default InvoiceShowActions;

import jwtDecode from 'jwt-decode';
import Axios from 'axios';
import config from 'config';

const getTokenExpMs = (token: string) => (token ? jwtDecode<{ exp: number }>(token).exp * 1000 : 0);

export function expirationStrategy(intervalMs: number) {
  const isExpiring = () => getTokenExpMs(tokenStorage.get()) <= Date.now() + intervalMs;
  let intervalId;
  const run = () => {
    intervalId = setInterval(() => {
      if (isExpiring()) {
        refreshToken();
      }
    }, intervalMs);
  };
  return {
    run,
    clear: () => clearInterval(intervalId),
  };
}

export const tokenStorage = {
  get: () => localStorage.getItem('token') || '',
  set: (token: string) => localStorage.setItem('token', token),
};

export function getDecodedToken() {
  const token = tokenStorage.get();
  return token ? jwtDecode<{ administrator: any }>(token) : null;
}

export function getAdminFromToken() {
  const tokenData = getDecodedToken();
  const { id, role, email } = tokenData?.administrator ?? {};
  return { id, role, email };
}

export function refreshToken() {
  return Axios({
    url: `${config.rails_url}/auth_token`,
    method: 'POST',
  }).then((res) => {
    if (res.headers.authorization) {
      tokenStorage.set(res.headers.authorization);
    }
  });
}

import React from 'react';
import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import { useRecordContext } from 'ra-core';
import { Typography, CircularProgress } from '@mui/material';

enum Step {
  address,
  background_check,
  credentials,
  direct_deposit,
}

const onboardingSteps = [
  {
    label: 'Address Needed',
    name: Step.address,
    isComplete: (professional) =>
      Boolean(professional.account?.address_first) &&
      Boolean(professional.account?.address_city) &&
      Boolean(professional.account?.address_zip) &&
      Boolean(professional.account?.state_id),
  },
  {
    label: 'Background Check Needed',
    name: Step.background_check,
    isComplete: (professional) =>
      professional.background_check_pqs?.some((pq) => pq.status === 'approved'),
  },
  {
    label: 'Credentials Needed',
    name: Step.credentials,
    isComplete: (professional) => !professional.missingConditions?.length,
  },
  {
    label: 'Direct Deposit Needed',
    name: Step.direct_deposit,
    isComplete: (professional) =>
      professional.stripe_accounts?.some((a) => Boolean(a.external_accounts)),
  },
];

const saasOnboardinSteps = onboardingSteps.filter((step) => step.name === Step.credentials);

const MissingOnboardingStepsField = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const isSaas = Boolean(record?.account?.company_id);
  const { data = { data: [] }, isLoading } = useQuery(
    ['professional', 'conditions', { professional_id: record?.id }],
    () =>
      dataProvider.getList('Condition', {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: {
          field: 'id',
          order: 'desc',
        },
        filter: {
          search: {
            missing_conditions: {
              for_on_boarding: true,
              for_application: false,
              professional_id: record?.id,
            },
          },
        },
      }),
  );
  const recordsWithConditions = { ...record, missingConditions: data.data };
  const steps = isSaas ? saasOnboardinSteps : onboardingSteps;
  const missedRequirements = steps
    .filter((step) => !step.isComplete(recordsWithConditions))
    .map((step) => step.label)
    .join(', ');
  if (isLoading) {
    return <CircularProgress />;
  }
  return <Typography variant="body2">{missedRequirements}</Typography>;
};

MissingOnboardingStepsField.defaultProps = {
  label: 'Missing Onboarding Steps',
  sortable: false,
};

export default MissingOnboardingStepsField;

import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import DateInput from 'components/DateInput';
import { ENUMS } from 'components/Enum/enums';
import ProfessionalInput from 'components/ProfessionalInput';
import StateInput from 'components/StateInput';

const degreeOptions = Object.keys(ENUMS.Education.degree_type)
  .filter((i) => isNaN(+i))
  .map((key) => ({
    name: key,
    id: ENUMS.Education.degree_type[key],
  }));

const EducationsCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput
        source="school_name"
        data-testid="professional-education_create_school_name_field"
      />
      <SelectInput
        source="degree_type"
        choices={degreeOptions}
        data-testid="professional-education_create_degree_type_field"
      />
      <TextInput
        source="field_of_study"
        data-testid="professional-education_create_field_of_study_field"
      />
      <StateInput data-testid="professional-education_create_state_field" />
      <DateInput source="start_date" />
      <DateInput source="end_date" />
    </SimpleForm>
  </Create>
);

export default EducationsCreate;

import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';

const EducationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField label="Professional ID" source="professional_id" />
      <TextField label="School Name" source="school_name" />
      <TextField label="Degree Type" source="degree_type" />
      <TextField label="Field of Study" source="field_of_study" />
      <TextField label="State" source="state.name" />
      <DateTimeField label="Start Date" source="start_date" />
      <DateTimeField label="End Date" source="end_date" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

EducationShow.query = gql`
  query GET_ONE($id: Int!) {
    education(id: $id) {
      id
      school_name
      degree_type
      professional_id
      field_of_study
      location
      start_date
      end_date
      created_at
      updated_at
      state_id
      state {
        id
        name
      }
    }
  }
`;

export default EducationShow;

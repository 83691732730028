import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import {
  FormDataConsumer,
  SelectInput,
  SelectArrayInput,
  useInput,
  useChoicesContext,
} from 'react-admin';
import ReferenceArrayInput from './ReferenceArrayInput';
import _ from 'lodash';

const SpecialtySelect = ({ multiple = false, hideIfEmpty = false, ...rest }) => {
  const { allChoices } = useChoicesContext();
  if (!allChoices?.length && hideIfEmpty) {
    return null;
  }
  return multiple ? <SelectArrayInput {...rest} /> : <SelectInput {...rest} />;
};

const Select = ({ multiple, hideIfEmpty, ...props }) => {
  return multiple ? (
    <ReferenceArrayInput {...props}>
      <SpecialtySelect
        multiple
        hideIfEmpty={hideIfEmpty}
        optionText="label"
        {...getChildrenProps(props)}
      />
    </ReferenceArrayInput>
  ) : (
    <ReferenceInput {...props}>
      <SpecialtySelect
        hideIfEmpty={hideIfEmpty}
        resettable
        optionText="label"
        {...getChildrenProps(props)}
      />
    </ReferenceInput>
  );
};

const SpecialtyInput = ({
  multiple = false,
  hideIfEmpty = false,
  positionSource = '',
  forJobs = true,
  exclude = [],
  ...props
}) => {
  const source = props.source || (multiple ? 'specialty_ids' : 'specialty_id');
  const { field } = useInput({ source });

  if (!positionSource && !props.positionIds) {
    return (
      <Select
        {...props}
        hideIfEmpty={hideIfEmpty}
        multiple={multiple}
        sx={{ minWidth: 256 }}
        source={source}
      />
    );
  }

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const positionIds =
          props.positionIds || _.get<number[]>(formData, positionSource.split('.')) || [];

        if (positionIds.length === 0) {
          if (field.value.length > 0) {
            field.onChange(undefined);
          }
          return null;
        }
        const filter = {
          search:
            formData.location_id || formData.location_ids
              ? {
                  for_locations: {
                    location_ids: formData.location_ids || [formData.location_id],
                    for_job: forJobs,
                    position_ids: positionIds,
                  },
                  exclude_ids: exclude,
                }
              : { position_ids: positionIds, exclude_ids: exclude },
        };

        return (
          <Select
            {...props}
            hideIfEmpty={hideIfEmpty}
            resettable
            reference="Specialty"
            filter={filter}
            multiple={multiple}
            sx={{ minWidth: 256 }}
            source={source}
            data-testid="specialty-input"
          />
        );
      }}
    </FormDataConsumer>
  );
};

SpecialtyInput.defaultProps = {
  label: 'Specialty',
  reference: 'Specialty',
  sort: { field: 'label', order: 'ASC' },
  perPage: 300,
};

export default React.memo(SpecialtyInput);

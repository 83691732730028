import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, NumberInput, TextField } from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import List from 'components/List';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import { currentTimeZone } from 'utils/time';
import IdInput from 'components/Filter/IdInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateFilter from 'components/Filter/DateFilter';

const TimecardsListFilters = [
  <IdInput />,
  <NumberInput label="Professional ID" source="professional_id" alwaysOn />,
  <NumberInput label="Assignment ID" source="assignment_id" alwaysOn />,
  <EnumSelectInput emptyText="All" source="status" alwaysOn />,
  <DateFilter
    label="Starts Date From"
    source="search.starts_time_from"
    time_zone={currentTimeZone()}
    alwaysOn
  />,
  <DateFilter
    label="Starts Date To"
    source="search.starts_time_to"
    time_zone={currentTimeZone()}
    alwaysOn
  />,
];

const TimecardsList = () => (
  <List
    exporter={false}
    filters={TimecardsListFilters}
    data-testid="timecards-list"
    hasCreate={false}
  >
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField label="Status" source="status" />
      <DateTimeField label="Starts Time" source="starts_time" timeZone={currentTimeZone()} />
      <DateTimeField label="Ends Time" source="ends_time" timeZone={currentTimeZone()} />
      <LinkField label="Assignment ID" source="assignment.id" />
      <LinkField label="Professional ID" source="professional.id" />
      <DateTimeField label="Submit by Time" source="submit_by" timeZone={currentTimeZone()} />
      <DateTimeField label="Approve by Time" source="approve_by" timeZone={currentTimeZone()} />
    </Datagrid>
  </List>
);

TimecardsList.query = gql`
  query GET_LIST($input: TimecardQueryInput) {
    _timecardsMeta(input: $input) {
      count
    }
    timecards(input: $input) {
      id
      status
      starts_time
      ends_time
      assignment_id
      assignment {
        id
      }
      professional_id
      professional {
        id
      }
      submit_by
      approve_by
      signature_url
      aws_signature_url
    }
  }
`;

export default TimecardsList;

import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, BooleanField, BooleanInput, NumberInput } from 'react-admin';
import { IdFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import IdField from 'components/IdField';
import List from 'components/List';

const BalanceSheetsFilters = [
  <IdFilter />,
  <EnumSelectInput label="Month" source="search.month" alwaysOn sort="" />,
  <NumberInput label="Year" alwaysOn source="search.year" />,
  <BooleanInput label="Closed" alwaysOn source="search.closed" />,
];

const BalanceSheetList = () => (
  <List title="Financial Reports" exporter={false} filters={BalanceSheetsFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Date" source="date" />
      <TextField label="Month" source="month" />
      <TextField label="Year" source="year" />
      <BooleanField label="Closed" source="closed" />
    </Datagrid>
  </List>
);

BalanceSheetList.query = gql`
  query GET_LIST($input: BalanceSheetQueryInput) {
    balanceSheets(input: $input) {
      id
      total_cents
      closed
      date
      month
      year
      first_datetime
    }
  }
`;

export default BalanceSheetList;

import React from 'react';
import { Create } from 'react-admin';
import PositionRateSettingsForm from '../PositionRateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const LocationPositionRateSettingCreate = () => (
  <Create transform={getTransform('location_id', 'settings')}>
    <PositionRateSettingsForm settingsField="settings" />
  </Create>
);

export default LocationPositionRateSettingCreate;

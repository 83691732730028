import React, { Fragment } from 'react';
import { Button, Link } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import UploadFileButton from 'components/UploadFileButton';

const CSV_TEMPLATE_URL =
  'https://medely-public-files.s3.us-west-2.amazonaws.com/csv_templates/location_position_rate_settings_bulk_upload_template.csv';

const BulkUploadButtons = () => (
  <Fragment>
    <UploadFileButton
      endpoint="v3/admin/location_position_rate_settings/import"
      accept="text/csv"
      text="Import&nbsp;CSV"
    />
    <Link href={CSV_TEMPLATE_URL}>
      <Button size="small" variant="outlined" startIcon={<DownloadIcon />}>
        Download CSV Template
      </Button>
    </Link>
  </Fragment>
);

export default BulkUploadButtons;

import React from 'react';
import {
  Edit,
  TextInput,
  BooleanInput,
  DateTimeInput,
  useCreate,
  useEditController,
  useRedirect,
  useNotify,
  TabbedForm,
  FormTab,
  useGetList,
  FormDataConsumer,
} from 'react-admin';
import VmsTypeInput from 'components/VmsTypeInput';
import HealthSystemInput from 'components/HealthSystemInput';
import MspInput from 'components/MspInput';
import useCheckAccess from 'hooks/useCheckAccess';
import { equals, omit } from 'ramda';
import RestrictedInput from 'components/RestrictedInput';
import AccountManagerInput from 'components/Filter/AccountManagerInput';
import SalesAssociateInput from 'components/Filter/SalesAssociateInput';
import SaasFeesFormTab from './tabs/SaasFeesFormTab';
import { sanatizeNullishFromObject } from 'utils/object';
import ReferralSourceInput from 'components/ReferralSourceInput';

const CompanyEdit = () => {
  const checkAccess = useCheckAccess();
  const [create] = useCreate();
  const { record, save } = useEditController({ mutationMode: 'pessimistic' });
  const { data: saasBillingGroups } = useGetList('BillingGroup', {
    filter: { saas_primary: true, company_id: record?.id },
  });
  const saasBillingGroupId = saasBillingGroups?.[0]?.id;
  const redirect = useRedirect();
  const notify = useNotify();

  const updateCompany = async (data) => {
    const redirectTo = `/Company/${data.id}/show`;
    const hasSetUpInvoicing = data.set_up_invoicing;
    data = omit(['set_up_invoicing'], data);
    const hasChanged = !equals(sanatizeNullishFromObject(data), sanatizeNullishFromObject(record));

    let notifyMessage = '';
    if (hasChanged) {
      notifyMessage = `Company updated ${hasSetUpInvoicing ? 'and billing group created' : ''}`;
    } else {
      if (hasSetUpInvoicing) {
        notifyMessage = 'Billing group created';
      }
    }

    const onSuccess = () => {
      notify(notifyMessage, { type: 'success' });
      redirect(redirectTo);
    };

    if (hasChanged) {
      await save?.(data, { onSuccess });
    }
    if (hasSetUpInvoicing) {
      await create(
        'BillingGroup',
        {
          data: {
            name: `${data.name} Billing Group`,
            company_id: data.id,
            status: 'active',
            plan: 'standard',
            billing: 'weekly_billing',
            auto_pay: 'false',
            statement_fee_percent: 0.04,
            line_of_credit_cents: 2000000,
            net_term: 30,
            check_line_of_credit: true,
            tier: 'Low risk (new)',
            past_due_allowance_amount_cents: 100_000_000,
          },
        },
        { onSuccess },
      );
    }
  };

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <TabbedForm
        mode="onTouched"
        record={{ ...record, saas_billing_group_id: saasBillingGroupId }}
        onSubmit={updateCompany}
      >
        <FormTab label="Details">
          {checkAccess('update', 'Company', 'name') && <TextInput label="Name" source="name" />}
          {checkAccess('update', 'Company', 'phone_number') && (
            <TextInput label="Phone Number" source="phone_number" />
          )}
          {checkAccess('update', 'Company', 'vms_type_id') && <VmsTypeInput />}
          {checkAccess('update', 'Company', 'health_system_id') && <HealthSystemInput />}
          {checkAccess('update', 'Company', 'msp_id') && <MspInput />}
          {checkAccess('update', 'Company', 'verified') && (
            <BooleanInput label="Verified" source="verified" />
          )}
          {checkAccess('update', 'Company', 'saas_credentialing_enabled') && (
            <>
              <BooleanInput
                label="SaaS Credentialing Enabled"
                source="saas_credentialing_enabled"
              />
              <FormDataConsumer>
                {({ formData }) => {
                  if (
                    formData.saas_credentialing_enabled &&
                    checkAccess('update', 'Company', 'saas_scheduling_enabled')
                  ) {
                    return (
                      <BooleanInput
                        label="SaaS Scheduling Enabled"
                        source="saas_scheduling_enabled"
                      />
                    );
                  }
                }}
              </FormDataConsumer>
            </>
          )}
          {checkAccess('update', 'BillingGroup') && (
            <BooleanInput label="Set Up Invoicing" source="set_up_invoicing" />
          )}
          {checkAccess('update', 'Company', 'uid') && <TextInput label="UID" source="uid" />}
          {checkAccess('update', 'Company', 'onboarding_complete') && (
            <BooleanInput label="Onboarding Complete" source="onboarding_complete" />
          )}
          {checkAccess('update', 'Company', 'rfm') && <TextInput label="RFM" source="rfm" />}
          {checkAccess('update', 'Company', 'referral_source') && (
            <ReferralSourceInput filter={{ applies_to_facilities: true }} />
          )}
          {checkAccess('update', 'Company', 'other_referral_source') && (
            <FormDataConsumer>
              {({ formData }) =>
                formData.referral_source_id === 9 && (
                  <TextInput label="Other Referral Source" source="other_referral_source" />
                )
              }
            </FormDataConsumer>
          )}
          {checkAccess('update', 'Company', 'disabled_at') && (
            <DateTimeInput label="Disabled At" source="disabled_at" />
          )}
          {checkAccess('update', 'Company', 'last_time_job_posted_at') && (
            <DateTimeInput label="Last Time Job Posted" source="last_time_job_posted_at" />
          )}
          {checkAccess('update', 'Company', 'detailed_notes') && (
            <TextInput label="Detailed Notes" source="detailed_notes" />
          )}
          <RestrictedInput
            component={SalesAssociateInput}
            action="update"
            source="sales_associate_id"
            allowEmpty
            emptyValue={null}
            emptyText="Clear"
          />
          <RestrictedInput
            component={AccountManagerInput}
            action="update"
            source="account_manager_id"
            allowEmpty
            emptyValue={null}
            emptyText="Clear"
          />
        </FormTab>

        {checkAccess('update', 'Company', [
          'saas_per_job_flat_fee_cents',
          'saas_per_seat_monthly_cents',
          'saas_base_monthly_cents',
        ]) && (
          <FormTab label="Saas Fees">
            <SaasFeesFormTab />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default CompanyEdit;

import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, BooleanField } from 'react-admin';
import List from 'components/List';
import EnumField from 'components/Enum/EnumField';
import IdInput from 'components/Filter/IdInput';
import IdField from 'components/IdField';
import { SearchFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const AdminFilters = [
  <IdInput />,
  <SearchFilter alwaysOn />,
  <EnumSelectInput resettable source="role" alwaysOn />,
  <EnumSelectInput resettable source="department" alwaysOn />,
];

const AdministratorsList = () => (
  <List filters={AdminFilters} exporter={false} data-testid="administrators-list">
    <Datagrid bulkActionButtons={false}>
      <IdField label="ID" sortBy="id" />
      <TextField label="First Name" source="account.first_name" />
      <TextField label="Last Name" source="account.last_name" />
      <EnumField label="Role" source="role" />
      <TextField label="Department" source="department" />
      <TextField label="Email" sortable={false} sortBy="email" source="account.email" />
      <TextField
        label="Phone"
        sortable={false}
        sortBy="phone_number"
        source="account.phone_number"
      />
      <TextField label="Status" source="status" />
      <BooleanField label="Paused" source="paused" />
    </Datagrid>
  </List>
);

AdministratorsList.query = gql`
  query GET_LIST($input: AdministratorQueryInput) {
    _administratorsMeta(input: $input) {
      count
    }
    administrators(input: $input) {
      id
      account {
        id
        first_name
        last_name
        email
        phone_number
      }
      role
      department
      paused
      status
    }
  }
`;

export default AdministratorsList;

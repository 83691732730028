import React from 'react';
import {
  NumberInput,
  BooleanInput,
  SimpleForm,
  useResourceContext,
  useRecordContext,
} from 'react-admin';
import PositionInput from 'components/PositionInput';
import MoneyInput from 'components/MoneyInput';
import SpecialtyInput from 'components/SpecialtyInput';
import StateInput from 'components/StateInput';
import MarketInput from 'components/MarketInput';
import useCheckAccess from 'hooks/useCheckAccess';
import LocationInput from 'components/LocationInput';
import FacilityAndProfessionalRateInput from './LocationAndProfessionalRateInput';
import { pick } from 'ramda';
import W2And1099SubSettingsForm from './W2And1099SubSettingsForm';

const getRateSource = (record, resource) => {
  if (resource === 'MarketPositionRateSetting' || record?.market_id) {
    return 'market_id';
  } else if (resource === 'StatePositionRateSetting' || record?.state_id) {
    return 'state_id';
  } else if (resource === 'LocationPositionRateSetting' || record?.location_id) {
    return 'location_id';
  }
  throw new Error('No rate representation found');
};

const getRateReferenceComponent = (source) => {
  if (source === 'market_id') {
    return MarketInput;
  } else if (source === 'state_id') {
    return StateInput;
  } else if (source === 'location_id') {
    return LocationInput;
  }
  return () => null;
};

const formatCents = (cents: number) =>
  !!cents ? (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null;

const PositionRateSettingsForm = ({ settingsField = null }: { settingsField: string }) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const checkAccess = useCheckAccess();
  const source = getRateSource(record, resource);
  const RateReferenceComponent = getRateReferenceComponent(source);
  let stateMinimumProfessionalRate = 1700;

  const inheritedSettings = record?.settings;
  const getInherited = (key: string) => (inheritedSettings ? '' + inheritedSettings[key] : null);

  const defaultValues =
    record &&
    pick(
      [
        record && 'id',
        'position_id',
        'state_id',
        'location_id',
        'specialty_id',
        'active',
        settingsField,
      ],
      record,
    );

  // TODO: Git revert this commit (from FIN-908) when https://medely.atlassian.net/browse/FIN-1264 is implemented
  if (record?.location?.state_id && [1, 9, 49].includes(record.location.state_id)) {
    stateMinimumProfessionalRate = {
      1: 1867, // California
      9: 1729, // Colorado
      49: 1869, // Washington
    }[record.location.state_id];
  }

  return (
    <SimpleForm defaultValues={defaultValues}>
      <RateReferenceComponent />
      <PositionInput />
      <SpecialtyInput source="specialty_id" positionSource="position_id" />
      <BooleanInput label="Active" source="active" />
      <FacilityAndProfessionalRateInput
        source={settingsField}
        inheritedSettings={inheritedSettings}
        stateMinimumProfessionalRate={stateMinimumProfessionalRate}
      />
      <MoneyInput
        label="Location On Call Rate"
        source={`${settingsField}.charge_on_call_hourly_rate_cents`}
        helperText={formatCents(inheritedSettings?.charge_on_call_hourly_rate_cents)}
      />
      <NumberInput
        label="On Call Percentage Fee"
        source={`${settingsField}.on_call_fee_percent`}
        helperText={getInherited('on_call_fee_percent')}
      />
      <NumberInput
        label="Margin 1099 Percent"
        source={`${settingsField}.margin_percent_1099`}
        helperText={getInherited('margin_percent_1099')}
      />
      <NumberInput
        label="Margin W2 Local Percent"
        source={`${settingsField}.margin_percent_w2_local`}
        helperText={getInherited('margin_percent_w2_local')}
      />
      <NumberInput
        label="Margin W2 Travel Percent"
        source={`${settingsField}.margin_percent_w2_travel`}
        helperText={getInherited('margin_percent_w2_travel')}
      />
      <NumberInput
        label="Margin W2 TCV Percent"
        source={`${settingsField}.margin_percent_w2_tcv`}
        helperText={getInherited('margin_percent_w2_tcv')}
      />
      <MoneyInput
        label="Margin W2 TCV"
        source={`${settingsField}.margin_w2_tcv_cents`}
        helperText={formatCents(inheritedSettings?.margin_w2_tcv_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 2 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier2_travel_amount_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 2 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_tcv_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier2_travel_amount_tcv_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 1 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier1_travel_amount_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 1 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_tcv_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier1_travel_amount_tcv_cents)}
      />
      {resource !== 'StatePositionRateSetting' && checkAccess('update', resource, 'settings') && (
        <W2And1099SubSettingsForm source={settingsField} inheritedSettings={inheritedSettings} />
      )}
    </SimpleForm>
  );
};

export default PositionRateSettingsForm;

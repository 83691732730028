import React from 'react';
import { Create, SimpleForm, NumberInput, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateTimeInput from 'components/DateTimeInput';
import { startOfDay, endOfDay } from 'date-fns/fp';
import useCheckAccess from 'hooks/useCheckAccess';

const defaultValues = {
  starts_time: startOfDay(new Date()),
  ends_time: endOfDay(new Date()),
  status: 'pending',
};

const InvoicesCreate = () => {
  const checkAccess = useCheckAccess();

  return (
    <Create redirect="show">
      <SimpleForm defaultValues={defaultValues}>
        {checkAccess('create', 'Invoice', 'billing_group_id') && (
          <NumberInput label="Billing Group ID" source="billing_group_id" />
        )}
        {checkAccess('create', 'Invoice', 'status') && (
          <EnumSelectInput label="Status" source="status" disabled />
        )}
        {checkAccess('create', 'Invoice', 'starts_time') && (
          <DateTimeInput label="Starts Time" source="starts_time" />
        )}
        {checkAccess('create', 'Invoice', 'ends_time') && (
          <DateTimeInput label="Ends Time" source="ends_time" />
        )}
        {checkAccess('create', 'Invoice', 'assignment_id') && (
          <NumberInput label="Assignment ID" source="assignment_id" />
        )}
        {checkAccess('create', 'Invoice', 'voucher_id') && (
          <TextInput label="Voucher ID" source="voucher_id" />
        )}
        {checkAccess('create', 'Invoice', 'voucher_processing_status') && (
          <EnumSelectInput label="Voucher Process Status" source="voucher_processing_status" />
        )}
      </SimpleForm>
    </Create>
  );
};

export default InvoicesCreate;

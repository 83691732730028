import React from 'react';
import {
  Datagrid,
  TextField,
  SelectInput,
  BooleanInput,
  ArrayField,
  ChipField,
  SingleFieldList,
  FunctionField,
} from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import IdInput from 'components/Filter/IdInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { MarketFilter, AccountManagerFilter } from 'components/Filter';
import LinkField from 'components/LinkField';
import List from 'components/List';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import ReferenceInput from 'components/ReferenceInput';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import IdField from 'components/IdField';
import ProfessionalInput from 'components/ProfessionalInput';
import LocationInput from 'components/LocationInput';

const JobBillingsFilters = [
  <IdInput />,
  <ProfessionalInput source="search.professional_id" alwaysOn />,
  <LocationInput source="search.location_id" alwaysOn />,
  <EnumSelectInput label="Job Status" source="search.job_status" alwaysOn />,
  <MarketFilter alwaysOn />,
  <AccountManagerFilter alwaysOn source="search.account_manager_id" />,
  <PositionInput alwaysOn multiple source="search.position_ids" />,
  <SpecialtyInput
    alwaysOn
    multiple
    source="search.specialty_ids"
    positionSource="search.position_ids"
  />,
  <ReferenceInput
    alwaysOn
    reference="LocationType"
    label="Location Type"
    source="search.location_type_id"
  >
    <SelectInput resettable optionText="label" />
  </ReferenceInput>,
  <BooleanInput label="First job_billing posted" source="search.first_job_posted" alwaysOn />,
  <BooleanInput label="First job_billing booked" source="search.first_job_booked" alwaysOn />,
];

const JobBillingsList = () => (
  <List exporter={false} filters={JobBillingsFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <FunctionField
        label="Professional ID"
        render={(v) =>
          v.job?.professional_id ? (
            <Link to={`/professional/${v.job.professional_id}/show`}>{v.job.professional_id}</Link>
          ) : null
        }
      />
      <FunctionField
        label="Location ID"
        render={(v) =>
          v.job?.location_id ? (
            <Link to={`/location/${v.job.location_id}/show`}>{v.job.location_id}</Link>
          ) : null
        }
      />
      <DateTimeField
        label="Job Starts Time"
        source="job.starts_time"
        tzSource="job.location.timezone_lookup"
      />
      <DateTimeField
        label="Job Ends Time"
        source="job.ends_time"
        tzSource="job.location.timezone_lookup"
      />
      <LinkField label="Invoice ID" source="invoice_id" />
      <TextField label="Status" source="job.status" />
      <ArrayField label="Positions" source="job.positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <TextField label="Market" source="job.location.market.display_name" />
    </Datagrid>
  </List>
);

JobBillingsList.query = gql`
  query GET_LIST($input: JobBillingQueryInput) {
    _jobBillingsMeta(input: $input) {
      count
    }
    jobBillings(input: $input) {
      job_id
      payout_id
      invoice_id
      id
      past_month_adjustment
      total_hours
      total_on_call_hours
      payout_total_amount_cents
      payout_total_amount_currency
      charge_total_amount_cents
      charge_total_amount_currency
      payout_regular_hours
      payout_overtime_hours
      payout_double_hours
      charge_regular_hours
      charge_overtime_hours
      charge_double_hours
      payout_overtime_amount_cents
      charge_overtime_amount_cents
      payout_double_amount_cents
      charge_double_amount_cents
      charge_callback_hours
      charge_callback_amount_cents
      payout_callback_hours
      payout_callback_amount_cents
      payout_regular_amount_cents
      charge_regular_amount_cents
      total_breaks_minutes
      job {
        professional_id
        location_id
        positions {
          display_name
        }
        status
        location {
          market {
            display_name
          }
          timezone_lookup
        }
        starts_time
        ends_time
      }
    }
  }
`;

export default JobBillingsList;

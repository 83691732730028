import { gql } from '@apollo/client';
import * as React from 'react';
import { Datagrid, TextField, FunctionField } from 'react-admin';
import { ProfessionalFilters } from './ProfessionalFilters';
import * as R from 'ramda';
import { DisplayHelpers } from 'utils/helpers';
import { Badge, Link } from '@mui/material';
import { NotesOutlined, DescriptionOutlined } from '@mui/icons-material';
import List from 'components/List';
import { Link as RouterLink } from 'react-router-dom';
import IdField from 'components/IdField';
import MissingOnboardingStepsField from 'resources/professionals/MissingOnboardingStepsField';
import NursysField from './NursysField';
import PhoneScreening from './PhoneScreening';
import _ from 'lodash';

const ProfessionalsList = () => (
  <List exporter={false} filters={ProfessionalFilters}>
    <Datagrid bulkActionButtons={false} data-testid="professionals-table">
      <IdField />
      <TextField
        label="Name"
        source="account.name"
        sortable={false}
        data-testid="professionals-name_field"
      />
      <FunctionField
        label="Position(s)"
        render={DisplayHelpers.professional.positionNames}
        data-testid="professionals-positions_field"
      />
      <FunctionField
        source="created_at"
        label="Added"
        render={DisplayHelpers.professional.dateAdded}
        data-testid="professionals-added_field"
      />
      <PhoneScreening data-testid="phone-screening" />
      <TextField
        label="Phone"
        sortable={false}
        source="account.phone_number"
        data-testid="professionals-phone_field"
      />
      <TextField
        label="Email"
        sortable={false}
        source="account.email"
        data-testid="professionals-email_field"
      />
      <NursysField />
      <FunctionField
        label="Status"
        render={DisplayHelpers.humanizeProp('status')}
        data-testid="professionals-status_field"
      />
      <TextField
        label="Quick Note"
        sortable={false}
        source="quick_note"
        data-testid="professionals-quick_note_field"
      />
      <MissingOnboardingStepsField />
      <FunctionField
        label="App Status"
        render={DisplayHelpers.humanizeProp('application_status')}
        data-testid="professionals-app_status_field"
      />
      <FunctionField
        label="Kind"
        render={DisplayHelpers.humanizeProp('kind')}
        data-testid="professionals-kind_field"
      />
      <FunctionField
        data-testid="professionals-notes_field"
        label="Notes"
        render={(v) => (
          <Link component={RouterLink} to={`/Professional/${v.id}/show/notes`}>
            <Badge
              color="primary"
              badgeContent={_.flowRight<any, any, any, any>(
                R.length,
                R.defaultTo([]),
                _.property('notes'),
              )(v)}
            >
              <NotesOutlined />
            </Badge>
          </Link>
        )}
      />

      {/* TODO: Update this to Professional */}
      <FunctionField
        data-testid="professionals-background_field"
        label="Background"
        render={(v: any) =>
          Boolean(v.background_check_pqs?.[0]?.metadata?.candidate_id) && (
            <Link
              target="_blank"
              href={`https://dashboard.checkr.com/candidates/${v.background_check_pqs[0].metadata.candidate_id}`}
            >
              {DisplayHelpers.professional.status(v.background_check_pqs[0].status)}
            </Link>
          )
        }
      />
      <FunctionField
        data-testid="professionals-resume_field"
        label="Resume"
        render={(v) => (
          <Link component={RouterLink} to={`/Professional/${v.id}/show/resume`}>
            <DescriptionOutlined />
          </Link>
        )}
      />
    </Datagrid>
  </List>
);

ProfessionalsList.query = gql`
  query GET_LIST($input: ProfessionalQueryInput) {
    _professionalsMeta(input: $input) {
      count
    }
    professionals(input: $input) {
      id
      status
      created_at
      application_status
      kind
      phone_screen_date_and_time
      calendly_invitee_uuid
      quick_note
      account {
        id
        name
        phone_number
        email
        address_first
        address_city
        address_zip
        state_id
        company_id
      }
      background_check_pqs {
        id
        status
        metadata
      }
      positions {
        id
        display_name
      }
      nursysEnrollments {
        id
        professional_error
        status
      }
      notes {
        id
      }
      stripe_accounts {
        external_accounts {
          id
        }
      }
    }
  }
`;

export default ProfessionalsList;

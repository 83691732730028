import React from 'react';
import { BooleanInput, SelectInput, TextInput, AutocompleteInput, NumberInput } from 'react-admin';
import ReferenceInput from 'components/ReferenceInput';
import IdInput from 'components/Filter/IdInput';
import { AccountManagerFilter, SearchFilter, StateFilter } from 'components/Filter';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import { ENUMS } from 'components/Enum/enums';
import { choicesFromEnum } from 'utils/helpers';
import {
  LicenseKindEnum,
  BackgroundCheckReportStatusEnum,
  AssignmentRequestStatus,
  ProfessionalQualificationStatus,
} from 'typings/enums';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import StateInput from 'components/StateInput';
import MarketInput from 'components/MarketInput';
import DateFilter from 'components/Filter/DateFilter';

export const ProfessionalFilters = [
  <SearchFilter alwaysOn />,
  <IdInput data-testid="professionals-id_filter" />,
  <TextInput
    alwaysOn
    label="Email"
    source="search.email"
    data-testid="professionals-email_filter"
  />,
  <EnumSelectInput alwaysOn source="search.status" label="Status" />,
  <EnumSelectInput alwaysOn source="application_status" label="App Status" sort={null} d />,
  <EnumSelectInput alwaysOn source="kind" label="Kind" />,
  <BooleanInput source="search.is_saas_professional" label="BYOP Professionals Only" />,
  <BooleanInput
    alwaysOn
    source="search.ignore_dl"
    label="Ignore DL"
    data-testid="professionals-ignore_DL_filter"
  />,
  <BooleanInput
    alwaysOn
    source="search.has_onboarded_positions"
    label="Has Onboarded Positions"
    data-testid="professionals-has_onboarded_positions_filter"
  />,
  <BooleanInput
    alwaysOn
    source="search.all_positions_onboarded"
    label="All Positions Onboarded"
    data-testid="professionals-all_positions_onboarded_filter"
  />,
  <SelectInput
    alwaysOn
    label="Credential Status"
    source="search.qualification_status"
    choices={choicesFromEnum(ProfessionalQualificationStatus)}
    data-testid="professionals-credentials_status_filter"
  />,
  <PositionInput
    alwaysOn
    multiple
    label="Positions (Any)"
    source="search.position_ids"
    data-testid="professionals-positions_any_filter"
  />,
  <PositionInput
    multiple
    scope="all"
    label="Positions (All)"
    source="search.position_ids_all"
    data-testid="professionals-positions_all_filter"
  />,
  <SpecialtyInput
    alwaysOn
    multiple
    source="search.specialty_ids"
    positionSource="search.position_ids"
  />,
  <PositionInput
    multiple
    label="Travel Position"
    source="search.travel_position_ids"
    data-testid="professionals-travel_position_filter"
  />,
  <MarketInput source="search.market_id" data-testid="professionals-market_filter" />,
  <StateInput
    label="State (Travel)"
    source="search.travel_state_id"
    data-testid="professionals-travel_state_filter"
  />,
  <StateInput
    label="State (Current Address)"
    source="search.state_id"
    data-testid="professionals-current_state_filter"
  />,
  <StateInput
    label="State (Markets)"
    source="search.market_state_id"
    data-testid="professionals-market_state_filter"
  />,
  <StateInput
    label="State (Stripe Account)"
    source="search.stripe_state_id"
    data-testid="professionals-stripe_state_filter"
  />,
  <TextInput
    label="Phone Search"
    source="search.phone_number"
    alwaysOn
    data-testid="professionals-phone_search_filter"
  />,
  <SelectInput
    label="Credential Kind"
    source="search.credential_kind"
    choices={choicesFromEnum(LicenseKindEnum)}
    data-testid="professionals-credential_kind_filter"
  />,
  <ReferenceInput reference="Qualification" source="search.credential_type">
    <AutocompleteInput
      label="Credential Type"
      optionText="long_name"
      data-testid="professionals-search_credential_filter"
    />
  </ReferenceInput>,
  <BooleanInput
    label="Credential Required"
    source="search.credential_required"
    data-testid="professionals-credential_required_filter"
  />,
  <BooleanInput
    label="On 1099 Assignment"
    source="search.on_1099_assignment"
    data-testid="professionals-on_1099_assignment_filter"
  />,
  <BooleanInput
    label="On W2 Assignment"
    source="search.on_w2_assignment"
    data-testid="professionals-on_w2_assignment_filter"
  />,
  <BooleanInput
    label="Has Balance"
    source="search.has_balance"
    data-testid="professionals-has_balance_filter"
  />,
  <BooleanInput
    label="SMS Notification"
    source="search.sms_notification"
    data-testid="professionals-sms_notification_filter"
  />,
  <BooleanInput
    label="Has Compact License"
    source="search.has_compact_license"
    data-testid="professionals-has_compact_license_filter"
  />,
  <StateFilter label="RN License For State" source="search.has_rn_state_license" />,
  <SelectInput
    label="Expiring Credentials"
    source="search.expiring_credentials"
    choices={[
      { id: 0, name: 'Expired' },
      { id: 7, name: '7 Days' },
      { id: 30, name: '30 Days' },
      { id: 60, name: '60 Days' },
    ]}
    data-testid="professionals-expiring_credentials_filter"
  />,
  <BooleanInput
    label="Profile Approved"
    source="search.profile_approved"
    data-testid="professionals-profile_approved_filter"
  />,
  <DateFilter source="search.profile_approved_since" label="Profile Approved Since" />,
  <AccountManagerFilter
    alwaysOn
    source="search.recruiter_id"
    filter={{ role: [ENUMS.Administrator.role.recruiter] }}
    label="Recruiter"
    data-testid="professionals-recruiter_filter"
  />,
  <AccountManagerFilter
    alwaysOn
    source="search.screening_recruiter_id"
    filter={{ role: [ENUMS.Administrator.role.support_agent], status: 'active' }}
    label="Screening Recruiter"
    data-testid="professionals-screening_recruiter_filter"
  />,
  <BooleanInput
    alwaysOn
    label="No Recruiter"
    source="search.no_recruiter"
    data-testid="professionals-no_recruiter_filter"
  />,
  <BooleanInput alwaysOn label="Required Qualifications" source="search.required_qualifications" />,
  <BooleanInput
    source="search.has_booked_jobs"
    label="Has Booked Jobs"
    data-testid="professionals-has_booked_jobs_filter"
  />,
  <SelectInput
    label="Background Check Status"
    choices={choicesFromEnum(BackgroundCheckReportStatusEnum)}
    source="search.background_check_status"
    data-testid="professionals-background_check_status_filter"
  />,
  <SelectInput
    label="Profile Strength"
    source="search.profile_strength"
    choices={[
      { id: 20, name: '20%' },
      { id: 40, name: '40%' },
      { id: 60, name: '60%' },
      { id: 80, name: '80%' },
      { id: 100, name: '100%' },
    ]}
    data-testid="professionals-profile_strength_filter"
  />,
  <SelectInput
    label="Signup Assignment"
    source="search.signup_assignment"
    choices={[
      { id: 0, name: 'Yes' },
      { id: 1, name: 'No' },
      { id: 2, name: '1099' },
      { id: 3, name: 'W2' },
    ]}
    data-testid="professionals-signup_assignment_filter"
  />,
  <SelectInput
    label="Applied to Assignment"
    source="search.applied_to_assignment"
    choices={[
      { id: 0, name: 'All' },
      { id: 1, name: 'W2' },
      { id: 2, name: '1099' },
    ]}
    data-testid="professionals-applied_to_assignment_filter"
  />,
  <SelectInput
    label="Assignment Application Status"
    source="search.assignment_request_status"
    choices={choicesFromEnum(AssignmentRequestStatus)}
    data-testid="professionals-assignment_request_status_filter"
  />,
  <SelectInput
    label="Work Hours"
    source="preferred_hours_per_week"
    choices={[
      { id: 10, name: '10' },
      { id: 20, name: '20' },
      { id: 30, name: '30' },
      { id: 40, name: '40' },
    ]}
    data-testid="professionals-work_hours_filter"
  />,
  <BooleanInput
    label="Welcome Box Sent"
    source="search.welcome_box_sent"
    data-testid="professionals-welcome_box_sent_filter"
  />,
  <DateFilter label="Created At" source="search.created_at" />,
  <NumberInput
    label="Assignment ID"
    source="search.signup_assignment_id"
    data-testid="professionals-assignment_id_filter"
  />,
  <ReferenceInput
    label="Referral Source"
    reference="ReferralSource"
    source="search.referral_source_id"
  >
    <AutocompleteInput
      optionText="label"
      label="Referral Source"
      data-testid="professionals-referral_source_id_filter"
    />
  </ReferenceInput>,
  <TextInput
    label="UTM Source"
    source="search.utm_source"
    data-testid="professionals-utm_source_filter"
  />,
  <TextInput
    label="UTM Campaign"
    source="search.utm_campaign"
    data-testid="professionals-utm_campaign_filter"
  />,
  <EnumSelectInput
    source="search.contract_value"
    label="Contract Value"
    data-testid="professionals-contract_value_filter"
  />,
  <TextInput
    label="Reject Reason"
    source="search.reject_reason"
    data-testid="professionals-reject_reason_filter"
  />,
  <TextInput
    label="Archive Reason"
    source="search.archive_reason"
    data-testid="professionals-archive_reason_filter"
  />,
  <BooleanInput
    label="Phone Interviewed"
    source="search.phone_interviewed"
    data-testid="professionals-phone_interviewed_filter"
  />,
  <BooleanInput label="Possible Duplicate" source="possible_duplicate" />,
  <DateFilter label="Created At From" source="search.created_at_from" />,
  <DateFilter label="Created At To" source="search.created_at_to" />,
  <BooleanInput alwaysOn label="Awaiting CAPs" source="search.awaiting_caps" />,
];

import React, { useState } from 'react';
import {
  SimpleForm,
  TextInput,
  Confirm,
  useNotify,
  useCreate,
  useResourceContext,
  Create,
  SaveContextProvider,
  required,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import MoneyInput from 'components/MoneyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateTimeInput from 'components/DateTimeInput';
import useDialog from 'hooks/useDialog';
import useCheckAccess from 'hooks/useCheckAccess';
import { currentTimeZone } from 'utils/time';

const FinancialTransactionsCreate = () => {
  const checkAccess = useCheckAccess();
  const dialog = useDialog();
  const [message, setMessage] = useState('');
  const [previousValues, setPreviousValues] = useState({});
  const resource = useResourceContext();
  const [mutate, { isLoading }] = useCreate();
  const navigate = useNavigate();
  const notify = useNotify();
  const handleSave = (values) => {
    return mutate(
      resource,
      { data: values },
      {
        onSuccess: ({ id }) => {
          console.log(values);
          setPreviousValues({});
          setMessage('');
          notify('Transaction has been successfully created', { type: 'success' });
          navigate(`/FinancialTransaction/${id}/show`);
        },
        onError: (error: any) => {
          if (error.response?.status === 409) {
            setPreviousValues(values);
            setMessage(error.message);
            dialog.openDialog();
          } else {
            notify(error.message, { type: 'warning' });
          }
        },
      },
    );
  };
  const handleConfirm = () => {
    dialog.closeDialog();
    handleSave({ ...previousValues, override_uniqueness: true });
  };

  return (
    <>
      <Create>
        <SaveContextProvider
          value={{ save: handleSave, saving: isLoading, mutationMode: 'pessimistic' }}
        >
          <SimpleForm>
            {checkAccess('create', 'FinancialTransaction', 'billing_group_id') && (
              <TextInput
                label="Billing Group ID"
                source="billing_group_id"
                validate={[required()]}
              />
            )}
            {checkAccess('create', 'FinancialTransaction', 'transaction_id') && (
              <TextInput label="Transaction ID" source="transaction_id" />
            )}
            {checkAccess('create', 'FinancialTransaction', 'amount_cents') && (
              <MoneyInput label="Amount" source="amount_cents" validate={[required()]} />
            )}
            {checkAccess('create', 'FinancialTransaction', 'revenue_date') && (
              <DateTimeInput
                label="Revenue Date"
                source="revenue_date"
                validate={[required()]}
                time_zone={currentTimeZone()}
              />
            )}
            {checkAccess('create', 'FinancialTransaction', 'data_source') && (
              <EnumSelectInput label="Data Source" source="data_source" validate={[required()]} />
            )}
            {checkAccess('create', 'FinancialTransaction', 'kind') && (
              <EnumSelectInput label="Kind" source="kind" sort={false} validate={[required()]} />
            )}
          </SimpleForm>
        </SaveContextProvider>
      </Create>
      <Confirm
        isOpen={dialog.open}
        title="The similar record already exists"
        content={message}
        confirm="Create Duplicate"
        confirmColor="primary"
        cancel="Go Back"
        onClose={dialog.closeDialog}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default FinancialTransactionsCreate;

import { calculateHours, getRates } from './calculations';
import { differenceInDays, parseISO } from 'date-fns';
import { useMemo } from 'react';

const useAssignmentCalculator = (values: any, rateSetting?: any) => {
  const {
    ends_date,
    starts_date,
    charge_base_hourly_rate_cents,
    gross_margin,
    payout_overtime_multiplier,
    charge_overtime_multiplier,
    payout_double_overtime_multiplier,
    charge_double_overtime_multiplier,
    payout_callback_multiplier,
    payout_max_weekly_regular_hours,
    payout_max_daily_regular_hours,
    payout_max_daily_ot_hours,
    charge_max_weekly_regular_hours,
    charge_max_daily_regular_hours,
    charge_max_daily_ot_hours,
    charge_on_call_hourly_rate_cents,
    min_taxable_rate_cents,
    max_lodging_per_diem_cents,
    max_mie_per_diem_cents,
    on_call_fee_percent,
    hours_per_shift,
    number_of_shifts_per_week,
    is_w2,
    is_extension,
    vms_fee_burden_multiplier,
    other_burden_multiplier,
    medical_burden_cents,
    social_security_burden_multiplier,
    federal_unemployment_percent_burden_multiplier,
    federal_unemployment_max_burden_cents,
    state_unemployment_percent_burden_multiplier,
    state_unemployment_max_burden_cents,
    medicare_burden_multiplier,
    non_billable_orientation_burden_hours,
    billable_orientation_hours,
    travel_reimbursement_burden_cents,
    local_only,
    lodging_per_diem_cents,
    mie_per_diem_cents,
    status,
    compliance_burden_cents,
    adp_fee_burden_cents,
    assignment_bonus_tier2_travel_amount_cents,
    assignment_bonus_tier2_travel_amount_tcv_cents,
    assignment_bonus_tier1_travel_amount_cents,
    assignment_bonus_tier1_travel_amount_tcv_cents,
    one_time_bonus_burden_cents,
  } = values;

  /**************************/
  /*  hours/basic pay info  */
  /**************************/
  // calculate number of weeks and total shifts for the assignment based on start and end date - used for some calculations below
  const totalDays = differenceInDays(parseISO(ends_date), parseISO(starts_date)) + 1;
  const numberOfWeeks = totalDays / 7;
  // hours_per_shift already has break time deducted.
  // The backend will deduct breaks for the show view and JobDetailsTab will deduct it for the edit view.
  const hoursPerShiftMinusBreaks = hours_per_shift;

  // calculate charge hours
  const {
    total_regular_hours: charge_total_regular_hours,
    total_overtime_hours: charge_total_overtime_hours,
    total_double_hours: charge_total_double_hours,
    total_time,
  } = calculateHours(
    hoursPerShiftMinusBreaks,
    number_of_shifts_per_week,
    charge_max_weekly_regular_hours,
    charge_max_daily_regular_hours,
    charge_max_daily_ot_hours,
  );

  // calculate payout hours
  const {
    total_regular_hours: payout_total_regular_hours,
    total_overtime_hours: payout_total_overtime_hours,
    total_double_hours: payout_total_double_hours,
  } = calculateHours(
    hoursPerShiftMinusBreaks,
    number_of_shifts_per_week,
    payout_max_weekly_regular_hours,
    payout_max_daily_regular_hours,
    payout_max_daily_ot_hours,
  );

  // calculate weighted hours
  const charge_weighted_hours =
    charge_total_regular_hours +
    charge_total_overtime_hours * charge_overtime_multiplier +
    charge_total_double_hours * charge_double_overtime_multiplier;

  const payout_weighted_hours =
    payout_total_regular_hours +
    payout_total_overtime_hours * payout_overtime_multiplier +
    payout_total_double_hours * payout_double_overtime_multiplier;

  // calculate total charge amount, profit, total pay, and minimum taxable pay
  const total_charge_amount =
    charge_weighted_hours * charge_base_hourly_rate_cents * numberOfWeeks +
    billable_orientation_hours * charge_base_hourly_rate_cents;
  const profit = total_charge_amount * gross_margin;
  const total_pay = total_charge_amount - profit;
  const min_taxable_pay = payout_weighted_hours * min_taxable_rate_cents * numberOfWeeks;

  /*************/
  /*  burdens  */
  /*************/
  // non rate-related burdens
  const vmsBurdens = vms_fee_burden_multiplier * total_charge_amount;
  const otherBurdens = other_burden_multiplier * total_charge_amount;
  const medicalBurdens = medical_burden_cents * numberOfWeeks;
  const travelBurdens = local_only ? 0 : travel_reimbursement_burden_cents * numberOfWeeks;

  const non_rate_related_burdens = is_w2
    ? Math.round(
        vmsBurdens +
          otherBurdens +
          medicalBurdens +
          travelBurdens +
          compliance_burden_cents +
          adp_fee_burden_cents,
      )
    : 0;

  let calculatedBonus = 0;
  if (!is_w2) {
    calculatedBonus = 0;
  } else if (total_charge_amount >= assignment_bonus_tier2_travel_amount_tcv_cents) {
    calculatedBonus = assignment_bonus_tier2_travel_amount_cents;
  } else if (total_charge_amount >= assignment_bonus_tier1_travel_amount_tcv_cents) {
    calculatedBonus = assignment_bonus_tier1_travel_amount_cents;
  } else {
    calculatedBonus = 0;
  }

  const calculatedTravelReimbursement =
    numberOfWeeks <= 13 ? 3846 : Math.round(50000 / numberOfWeeks);

  // rate-related burdens
  const taxableMultipliers1 = is_w2
    ? social_security_burden_multiplier +
      federal_unemployment_percent_burden_multiplier +
      state_unemployment_percent_burden_multiplier +
      medicare_burden_multiplier +
      non_billable_orientation_burden_hours / (payout_weighted_hours * numberOfWeeks) +
      billable_orientation_hours / (payout_weighted_hours * numberOfWeeks)
    : 0;

  const taxableMultipliers2 = is_w2
    ? social_security_burden_multiplier +
      medicare_burden_multiplier +
      non_billable_orientation_burden_hours / (payout_weighted_hours * numberOfWeeks) +
      billable_orientation_hours / (payout_weighted_hours * numberOfWeeks)
    : 0;

  const maxUnemploymentBurdensCents =
    state_unemployment_max_burden_cents * state_unemployment_percent_burden_multiplier +
    federal_unemployment_max_burden_cents * federal_unemployment_percent_burden_multiplier;

  const minTaxablePayBurdens1 = min_taxable_pay * taxableMultipliers1;
  const minTaxablePayBurdens2 = min_taxable_pay * taxableMultipliers2 + maxUnemploymentBurdensCents;
  const min_taxable_pay_burdens = Math.min(minTaxablePayBurdens1, minTaxablePayBurdens2);
  const pay_minus_non_pay_burdens =
    total_pay - non_rate_related_burdens - one_time_bonus_burden_cents;
  /**************/
  /*  stipends  */
  /**************/

  // stipends based on form inputs
  // calculated values are used in useEffect hooks in the form components
  const lodging = lodging_per_diem_cents;
  const mie = mie_per_diem_cents;

  const min_taxable_pay_w_taxable_burdens = min_taxable_pay + min_taxable_pay_burdens;
  const dailyPayForStipendCalc = Math.round(
    pay_minus_non_pay_burdens / totalDays - min_taxable_pay_w_taxable_burdens / totalDays,
  );

  const calculatedLodging =
    is_w2 && !local_only
      ? Math.min(
          Math.max(dailyPayForStipendCalc - max_mie_per_diem_cents, 0),
          max_lodging_per_diem_cents,
        )
      : 0;
  const calculatedMie = is_w2
    ? Math.max(Math.min(dailyPayForStipendCalc - calculatedLodging, max_mie_per_diem_cents), 0)
    : 0;

  /*****************/
  /*  taxable pay  */
  /*****************/
  const taxable_pay_with_rate_related_burdens =
    pay_minus_non_pay_burdens - Math.round(lodging * totalDays) - Math.round(mie * totalDays);
  const taxablePay1 = taxable_pay_with_rate_related_burdens / (1 + taxableMultipliers1);
  const taxablePay2 =
    (taxable_pay_with_rate_related_burdens - maxUnemploymentBurdensCents) /
    (1 + taxableMultipliers2);
  const taxable_pay = Math.max(taxablePay1, taxablePay2);
  // const taxable_pay = taxablePay1;
  const taxable_base_rate = Math.round(taxable_pay / (payout_weighted_hours * numberOfWeeks));
  const taxable_blended_rate =
    taxable_pay / (hoursPerShiftMinusBreaks * number_of_shifts_per_week * numberOfWeeks);

  /******************************************/
  /*  formatting values to be used in form  */
  /******************************************/
  const chargeRates = getRates(
    charge_base_hourly_rate_cents,
    charge_overtime_multiplier,
    charge_double_overtime_multiplier,
    {
      total_regular_hours: charge_total_regular_hours,
      total_overtime_hours: charge_total_overtime_hours,
      total_double_hours: charge_total_double_hours,
      total_time,
    },
  );

  const chargeValues = useMemo(
    () => ({
      hours: {
        total_regular_hours: charge_total_regular_hours,
        total_overtime_hours: charge_total_overtime_hours,
        total_double_hours: charge_total_double_hours,
        total_time,
      },
      rates: chargeRates,
    }),
    [
      chargeRates,
      charge_total_double_hours,
      charge_total_overtime_hours,
      charge_total_regular_hours,
      total_time,
    ],
  );
  const displayablePay =
    taxable_pay + Math.round(lodging * totalDays) + Math.round(mie * totalDays);
  const payout_base_rate = Math.round(displayablePay / (payout_weighted_hours * numberOfWeeks));
  const payout_blended_rate =
    displayablePay / (hoursPerShiftMinusBreaks * number_of_shifts_per_week * numberOfWeeks);

  const payoutValues = useMemo(
    () => ({
      hours: { total_time: total_time },
      rates: {
        blended_rate: Math.round(payout_blended_rate),
        base_rate: Math.round(payout_base_rate),
      },
      taxableRates: {
        mie_per_diem_cents: Math.round((mie * total_time) / 7),
        lodging_per_diem_cents: Math.round((lodging * total_time) / 7),
        taxable_blended_rate,
        total_taxable_amount: Math.round(taxable_pay),
        taxable_rate: Math.round(taxable_base_rate),
      },
    }),
    [
      lodging,
      mie,
      payout_base_rate,
      payout_blended_rate,
      taxable_base_rate,
      taxable_blended_rate,
      taxable_pay,
      total_time,
    ],
  );

  const payoutOnCallHourlyRateCents = Math.round(
    (1 - on_call_fee_percent) * charge_on_call_hourly_rate_cents,
  );

  let calculatedGrossMargin = gross_margin;
  if (rateSetting) {
    const {
      margin_percent_1099,
      margin_percent_w2_local,
      margin_percent_w2_tcv,
      margin_percent_w2_travel,
      margin_w2_tcv_cents,
    } = rateSetting;
    if (!is_w2) {
      calculatedGrossMargin = margin_percent_1099;
    } else if (local_only) {
      calculatedGrossMargin = margin_percent_w2_local;
    } else if (total_charge_amount >= margin_w2_tcv_cents && !is_extension) {
      calculatedGrossMargin = margin_percent_w2_tcv;
    } else {
      calculatedGrossMargin = margin_percent_w2_travel;
    }
  }

  return {
    payoutValues,
    chargeValues,
    totalPayCents: Math.round(displayablePay),
    totalNonTaxablePayCents: displayablePay - taxable_pay,
    totalTaxablePayCents: Math.round(taxable_pay),
    // payout_base_rate is a rounded value calculated using multipliers.
    // So because of this, totalWeeklyPayCents will fluctuate slightly if you change mulitpliers.
    totalWeeklyPayCents: Math.round(payout_base_rate * payout_weighted_hours),
    weeklyNonTaxableIncomeCents: Math.round((displayablePay - taxable_pay) / numberOfWeeks),
    weeklyTaxableIncomeCents: Math.round(taxable_pay / numberOfWeeks),
    numberOfWeeks,
    calculatedGrossMargin,
    profit,
    hours_per_shift: hoursPerShiftMinusBreaks,
    number_of_shifts_per_week,
    is_w2,
    status,
    calculatedLodging,
    calculatedMie,
    payout_base_rate,
    payoutOnCallHourlyRateCents,
    callBackRateCents: payoutValues.taxableRates.taxable_rate * payout_callback_multiplier,
    calculatedBonus,
    calculatedTravelReimbursement,
  };
};

export default useAssignmentCalculator;

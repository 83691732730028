import React from 'react';
import { gql } from '@apollo/client';
import {
  TopToolbar,
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  useRecordContext,
  FunctionField,
  WithRecord,
  useGetOne,
  useGetRecordId,
} from 'react-admin';
import AccountsTab from './tabs/AccountsTab';
import RefundFormButton from 'resources/refunds/RefundForm/RefundFormButton';
import useCheckAccess from 'hooks/useCheckAccess';
import LocationsTab from './tabs/LocationsTab';
import BillingGroupsTab from 'components/tabs/BillingGroupsTab';
import InfoTab from './tabs/InfoTab';
import RequirementsTab from './tabs/RequirementsTab';
import { Button, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';
import SaasFeesTab from './tabs/SaasFeesTab';

const CompanyActions = () => {
  const record = useRecordContext();
  const couldBeRefunded = record?.balance_cents > 0;

  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/companies/${record?.id}/professionals_import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      {couldBeRefunded && <RefundFormButton billingGroupId={record.id as number} />}
      {record?.id && record?.saas_credentialing_enabled && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {hiddenInput}
          <Button
            disabled={uploading}
            onClick={openFilePrompt}
            variant="outlined"
            size="small"
            endIcon={uploading ? <CircularProgress size={20} /> : undefined}
          >
            Import&nbsp;Professionals&nbsp;CSV
          </Button>
        </div>
      )}
      <EditButton />
    </TopToolbar>
  );
};

const CompaniesShow = () => {
  const checkAccess = useCheckAccess();

  const id = useGetRecordId();
  const { data: record } = useGetOne('Company', { id });
  if (!record) {
    return null;
  }

  const isSaasCredentialingEnabled = !!record?.saas_credentialing_enabled;
  const canShowSaasFeesTab =
    isSaasCredentialingEnabled &&
    checkAccess('update', 'Company', [
      'saas_per_job_flat_fee_cents',
      'saas_per_seat_monthly_cents',
      'saas_base_monthly_cents',
    ]);

  return (
    <Show actions={<CompanyActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <InfoTab />
        </Tab>

        <Tab label="Accounts" path="accounts">
          <AccountsTab />
        </Tab>

        {checkAccess('view', 'Location') && (
          <Tab label="Locations" path="locations">
            <LocationsTab />
          </Tab>
        )}

        {checkAccess('view', 'BillingGroup') && (
          <Tab label="Billing Groups" path="billing-groups">
            <BillingGroupsTab target="company_id" />
          </Tab>
        )}

        {checkAccess('view', 'Requirement') && (
          <Tab label="Requirements" path="requirements">
            <RequirementsTab />
          </Tab>
        )}
        {canShowSaasFeesTab && (
          <Tab label="Saas Fees" path="saas-fees">
            <SaasFeesTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

CompaniesShow.query = gql`
  query GET_ONE($id: Int!) {
    company(id: $id) {
      id
      name
      phone_number
      vms_type {
        id
        label
      }
      health_system {
        id
        label
      }
      msp {
        id
        label
      }
      verified
      saas_credentialing_enabled
      saas_scheduling_enabled
      uid
      onboarding_complete
      rfm
      referral
      disabled_at
      last_time_job_posted_at
      detailed_notes
      sales_associate_id
      account_manager_id
      saas_per_job_flat_fee_cents
      saas_base_monthly_cents
      saas_per_seat_monthly_cents
      referral_source_id
      referral_source {
        id
        name
        label
      }
      other_referral_source
    }
  }
`;

export default CompaniesShow;

import React from 'react';
import { FunctionField } from 'react-admin';

const TravelProfilePercentField = () => {
  return (
    <FunctionField
      label="Travel Profile %"
      sortable
      render={(r) => {
        let result = 0;
        if (!r.has_missing_work_history && !r.has_unexplained_work_gaps) {
          result += 30;
        }
        if (!r.has_missing_education) {
          result += 15;
        }
        if (!r.has_missing_ehr_software) {
          result += 10;
        }
        if (!r.has_missing_references) {
          result += 25;
        }
        if (!r.has_missing_availability) {
          result += 10;
        }
        if (!r.has_missing_identity) {
          result += 10;
        }
        return result;
      }}
    />
  );
};

TravelProfilePercentField.defaultProps = {
  label: 'Travel Profile %',
};

export default TravelProfilePercentField;

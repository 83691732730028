import { useGetList } from 'react-admin';
import { ProfessionalQualificationStatus } from 'typings/enums';
import { FilterEnum } from './CredentialsFilter';

// Note: Pq = ProfessionalQualification

const useProsQualifications = (professionalId: number, filterForRequired = false) => {
  const additionalFilters = filterForRequired
    ? {
        qualification_positions: {
          for_application: false,
          for_on_boarding: true,
        },
      }
    : {};

  const { data: proQualifications } = useGetList('ProfessionalQualification', {
    filter: {
      professional_id: professionalId,
      search: {
        qualification_types: [
          'q_and_a',
          'license',
          'certification',
          'document',
          'facility_onboarding',
          'signed_document',
        ],
        ...additionalFilters,
      },
    },
    pagination: { page: 1, perPage: 100 },
  });

  return (proQualifications ?? []).map((pq) => ({
    ...pq.qualification,
    ...pq,
  }));
};

const isPqArchived = ({ status }: any) => status === ProfessionalQualificationStatus.archived;

const useProsConditions = (professionalId: number) => {
  const { data: conditionData } = useGetList('Condition', {
    filter: {
      search: {
        condition_types: [
          'license',
          'certification',
          'document',
          'facility_onboarding',
          'signed_document',
        ],
        missing_conditions: {
          for_application: false,
          for_on_boarding: false,
          professional_id: professionalId,
        },
      },
    },
  });

  return conditionData ? Object.values(conditionData) : [];
};

const getMatchingPq = (proQualifications, qualification) => {
  const existingProQualification = proQualifications.find(
    ({ qualification_id }: any) => qualification_id === qualification.id,
  );
  return existingProQualification;
};

type UseCredentialsReturn = Record<FilterEnum, unknown[]>;

const useCredentials = (professionalId): UseCredentialsReturn => {
  const conditions = useProsConditions(professionalId);
  const allPqs = useProsQualifications(professionalId);
  const approvedRequiredPqs = useProsQualifications(professionalId, true).filter(
    (pq) => !isPqArchived(pq),
  );

  const notArchivedPqs = allPqs.filter((pq) => !isPqArchived(pq));
  const archivedPqs = allPqs.filter(isPqArchived);

  const missingQualifications: any[] = [];
  const requiredQualifications = conditions.flatMap(({ qualifications }) => qualifications);
  requiredQualifications.forEach((qualification: any) => {
    const proQualification = getMatchingPq(notArchivedPqs, qualification);
    if (!proQualification) {
      const row = { ...qualification };
      delete row.id;
      missingQualifications.push(row);
    }
  });

  const requiredPqIds = approvedRequiredPqs.map(({ id }) => id);
  const optionalPqs = notArchivedPqs.filter(({ id }) => !requiredPqIds.includes(id));

  return {
    [FilterEnum.ALL]: allPqs,
    [FilterEnum.MEDELY_REQUIRED]: [...approvedRequiredPqs, ...missingQualifications],
    [FilterEnum.OPTIONAL]: optionalPqs,
    [FilterEnum.ARCHIVED]: archivedPqs,
  };
};

export default useCredentials;

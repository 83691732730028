import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  FormDataConsumer,
  useRecordContext,
} from 'react-admin';
import { startOfMonth, endOfMonth } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import MoneyInput from 'components/MoneyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateTimeInput from 'components/DateTimeInput';
import useCheckAccess from 'hooks/useCheckAccess';

const RevenueDateField = () => {
  const record = useRecordContext();
  const revenueDate = record?.revenue_date ? new Date(record.revenue_date) : new Date();
  const pstRevenueDate = utcToZonedTime(revenueDate, 'America/Los_Angeles');
  const dateRange = {
    from: startOfMonth(pstRevenueDate),
    to: endOfMonth(pstRevenueDate),
  };
  return (
    <DateTimeInput
      label="Revenue Date"
      source="revenue_date"
      minDate={dateRange.from}
      maxDate={dateRange.to}
      helperText="Pacific Time"
    />
  );
};

const FinancialTransactionsEdit = () => {
  const checkAccess = useCheckAccess();
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        {checkAccess('update', 'FinancialTransaction', 'billing_group_id') && (
          <FormDataConsumer>
            {({ formData }) => {
              const itemizedRransactionsBillingGroupIds = Array.from(
                new Set(
                  formData.itemized_transactions?.map((t) => Number(t.billing_group_id)) ?? [],
                ),
              );
              return (
                <NumberInput
                  label="Billing Group ID"
                  source="billing_group_id"
                  validate={(value) => {
                    if (itemizedRransactionsBillingGroupIds.length) {
                      return itemizedRransactionsBillingGroupIds.includes(Number(value))
                        ? undefined
                        : `Should be one of (${itemizedRransactionsBillingGroupIds})`;
                    }
                    return undefined;
                  }}
                />
              );
            }}
          </FormDataConsumer>
        )}
        {checkAccess('update', 'FinancialTransaction', 'transaction_id') && (
          <TextInput label="Transaction ID" source="transaction_id" />
        )}
        {checkAccess('update', 'FinancialTransaction', 'amount_cents') && (
          <FormDataConsumer>
            {({ formData }) => (
              <MoneyInput
                label="Amount"
                source="amount_cents"
                disabled={formData.itemized_transactions?.length}
              />
            )}
          </FormDataConsumer>
        )}
        {checkAccess('update', 'FinancialTransaction', 'revenue_date') && <RevenueDateField />}
        {checkAccess('update', 'FinancialTransaction', 'data_source') && (
          <EnumSelectInput label="Data Source" source="data_source" />
        )}
        {checkAccess('update', 'FinancialTransaction', 'kind') && (
          <EnumSelectInput label="Kind" source="kind" sort={false} />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default FinancialTransactionsEdit;

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import useDialog from 'hooks/useDialog';
import { RejectedStatus } from '../ApplicationStatusControl';

interface RejectItemProps {
  updateApplicationStatus: (val: any) => void;
}

const reasonChoices = [
  'Falsifying Document',
  'Falsifying Resume',
  'No relevant experience',
  'Not a good fit - Bad Attitude',
  'Duplicate Account',
  'Other',
];

const RejectItem = ({ updateApplicationStatus }: RejectItemProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  return (
    <>
      <MenuItem onClick={openDialog}>Reject</MenuItem>
      <RejectDialog
        open={open}
        closeDialog={closeDialog}
        updateApplicationStatus={updateApplicationStatus}
      />
    </>
  );
};

const RejectDialog = ({ open, closeDialog, updateApplicationStatus }) => {
  const [rejectReason, setRejectReason] = useState('');
  const [other, setOther] = useState('');
  const isOtherSelected = rejectReason === 'Other';

  const submitUpdateApplicationStatus = () => {
    let reason = rejectReason;
    if (isOtherSelected) {
      reason += ` - ${other}`;
    }
    updateApplicationStatus({ application_status: RejectedStatus, reject_reason: reason });
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Reject Reason</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={rejectReason}
          label="Select Reject Reason"
          onChange={({ target }) => setRejectReason(target.value as string)}
          required
          style={{ width: '550px', marginBottom: '20px' }}
        >
          {reasonChoices.map((reason) => (
            <MenuItem key={reason} value={reason}>
              {reason}
            </MenuItem>
          ))}
        </Select>
        {isOtherSelected && (
          <TextField
            label="Reject Details"
            value={other}
            style={{ width: '550px' }}
            required
            onChange={({ target }) => setOther(target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          disabled={!rejectReason || (isOtherSelected && !other)}
          onClick={submitUpdateApplicationStatus}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectItem;

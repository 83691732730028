import React, { useMemo } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import _ from 'lodash';

const ID = '_id';

const getLink = (source: string, record: any, typename: string | null | undefined) => {
  if (source.slice(-3) === ID) {
    const id = record[source];
    typename = typename || source.split(ID)[0];
    return `/${typename}/${id}/show`;
  }
  const splitPathDelimiter = source.includes('.') ? '.' : '_';
  const path = source.split(splitPathDelimiter).slice(0, -1);
  const id = _.get<any>(record, path.concat('id'));
  typename = typename || _.get<any>(record, path.concat('__typename'));
  return `/${typename}/${id}/show`;
};

const LinkField = (props) => {
  const { source, to, children, typename, resource } = props;
  const record = useRecordContext(props);
  const url = useMemo(() => {
    if (Boolean(to)) {
      if (typeof to === 'function') {
        return to(record);
      }
      return to;
    }

    return getLink(source, record, resource ?? typename);
  }, [to, source, typename, resource, record]);

  return (
    <Typography data-testid="link-field">
      <Link component={RRLink} to={url}>
        {children || _.get(record, source.split('.'))}
      </Link>
    </Typography>
  );
};

export default LinkField;

import Heading from 'components/Heading';
import React from 'react';
import { NumberField, BooleanField, SimpleShowLayout } from 'react-admin';

const W2And1099SubSettingsShow = ({ source }: { source: string }) => {
  return (
    <SimpleShowLayout sx={{ padding: 0 }}>
      <Heading>1099 Settings</Heading>
      <NumberField
        source={`${source}.ten99_per_diem.charge_overtime_multiplier`}
        label="Charge Overtime Multiplier"
      />
      <NumberField
        source={`${source}.ten99_per_diem.charge_double_overtime_multiplier`}
        label="Charge Double Overtime Multiplier"
      />
      <NumberField
        source={`${source}.ten99_per_diem.charge_max_daily_regular_hours`}
        label="Charge Max Daily Regular Hours"
      />
      <NumberField
        source={`${source}.ten99_per_diem.charge_max_daily_overtime_hours`}
        label="Charge Max Daily Overtime Hours"
      />
      <NumberField
        source={`${source}.ten99_per_diem.charge_max_weekly_regular_hours`}
        label="Charge Max Weekly Regular Hours"
      />
      <BooleanField
        source={`${source}.ten99_per_diem.charge_seventh_day_rule_enabled`}
        label="Charge Seventh Day Rule Enabled"
      />
      <NumberField
        source={`${source}.ten99_per_diem.payout_overtime_multiplier`}
        label="Payout Overtime Multiplier"
      />
      <NumberField
        source={`${source}.ten99_per_diem.payout_double_overtime_multiplier`}
        label="Payout Double Overtime Multiplier"
      />
      <NumberField
        source={`${source}.ten99_per_diem.payout_max_daily_regular_hours`}
        label="Payout Max Daily Regular Hours"
      />
      <NumberField
        source={`${source}.ten99_per_diem.payout_max_daily_overtime_hours`}
        label="Payout Max Daily Overtime Hours"
      />
      <NumberField
        source={`${source}.ten99_per_diem.payout_max_weekly_regular_hours`}
        label="Payout Max Weekly Regular Hours"
      />
      <BooleanField
        source={`${source}.ten99_per_diem.payout_seventh_day_rule_enabled`}
        label="Payout Seventh Day Rule Enabled"
      />
      <Heading>W2 Settings</Heading>
      <NumberField
        source={`${source}.w2.charge_overtime_multiplier`}
        label="Charge Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2.charge_double_overtime_multiplier`}
        label="Charge Double Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2.charge_max_daily_regular_hours`}
        label="Charge Max Daily Regular Hours"
      />
      <NumberField
        source={`${source}.w2.charge_max_daily_overtime_hours`}
        label="Charge Max Daily Overtime Hours"
      />
      <NumberField
        source={`${source}.w2.charge_max_weekly_regular_hours`}
        label="Charge Max Weekly Regular Hours"
      />
      <BooleanField
        source={`${source}.w2.charge_seventh_day_rule_enabled`}
        label="Charge Seventh Day Rule Enabled"
      />
      <NumberField
        source={`${source}.w2.payout_overtime_multiplier`}
        label="Payout Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2.payout_double_overtime_multiplier`}
        label="Payout Double Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2.payout_max_daily_regular_hours`}
        label="Payout Max Daily Regular Hours"
      />
      <NumberField
        source={`${source}.w2.payout_max_daily_overtime_hours`}
        label="Payout Max Daily Overtime Hours"
      />
      <NumberField
        source={`${source}.w2.payout_max_weekly_regular_hours`}
        label="Payout Max Weekly Regular Hours"
      />
      <BooleanField
        source={`${source}.w2.payout_seventh_day_rule_enabled`}
        label="Payout Seventh Day Rule Enabled"
      />
      <Heading>W2 Marketplace</Heading>
      <NumberField
        source={`${source}.w2_per_diem.charge_overtime_multiplier`}
        label="Charge Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2_per_diem.charge_double_overtime_multiplier`}
        label="Charge Double Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2_per_diem.charge_max_daily_regular_hours`}
        label="Charge Max Daily Regular Hours"
      />
      <NumberField
        source={`${source}.w2_per_diem.charge_max_daily_overtime_hours`}
        label="Charge Max Daily Overtime Hours"
      />
      <NumberField
        source={`${source}.w2_per_diem.charge_max_weekly_regular_hours`}
        label="Charge Max Weekly Regular Hours"
      />
      <BooleanField
        source={`${source}.w2_per_diem.charge_seventh_day_rule_enabled`}
        label="Charge Seventh Day Rule Enabled"
      />
      <NumberField
        source={`${source}.w2_per_diem.payout_overtime_multiplier`}
        label="Payout Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2_per_diem.payout_double_overtime_multiplier`}
        label="Payout Double Overtime Multiplier"
      />
      <NumberField
        source={`${source}.w2_per_diem.payout_max_daily_regular_hours`}
        label="Payout Max Daily Regular Hours"
      />
      <NumberField
        source={`${source}.w2_per_diem.payout_max_daily_overtime_hours`}
        label="Payout Max Daily Overtime Hours"
      />
      <NumberField
        source={`${source}.w2_per_diem.payout_max_weekly_regular_hours`}
        label="Payout Max Weekly Regular Hours"
      />
      <BooleanField
        source={`${source}.w2_per_diem.payout_seventh_day_rule_enabled`}
        label="Payout Seventh Day Rule Enabled"
      />
    </SimpleShowLayout>
  );
};

export default W2And1099SubSettingsShow;

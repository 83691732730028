import React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  SimpleShowLayout,
  NumberField,
  BooleanField,
} from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import TimestampFields from 'components/TimestampFields';
import MoneyField from 'components/MoneyField';

const JobProfileTab = () => {
  const checkAccess = useCheckAccess();
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        label=""
        pagination={<Pagination />}
        reference="JobProfile"
        target="job_id"
      >
        <Datagrid bulkActionButtons={false}>
          {checkAccess('view', 'JobProfile', 'id') && <IdField />}
          {checkAccess('view', 'JobProfile', 'job_id') && <LinkField label="Job" source="job_id" />}
          {checkAccess('view', 'JobProfile', 'marketplace') && (
            <BooleanField source="marketplace" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_kind') && <TextField source="payout_kind" />}
          {checkAccess('view', 'JobProfile', 'active') && <BooleanField source="active" />}
          {checkAccess('view', 'JobProfile', 'bonus_one_time_cents') && (
            <MoneyField label="Bonus One Time" source="bonus_one_time_cents" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_base_hourly_rate_cents') && (
            <MoneyField label="Charge Base Hourly Rate" source="charge_base_hourly_rate_cents" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_callback_multiplier') && (
            <NumberField source="charge_callback_multiplier" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_double_overtime_multiplier') && (
            <NumberField source="charge_double_overtime_multiplier" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_max_daily_ot_hours') && (
            <NumberField source="charge_max_daily_ot_hours" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_max_daily_regular_hours') && (
            <NumberField source="charge_max_daily_regular_hours" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_max_weekly_regular_hours') && (
            <NumberField source="charge_max_weekly_regular_hours" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_on_call_hourly_rate_cents') && (
            <MoneyField
              label="Charge On Call Hourly Rate"
              source="charge_on_call_hourly_rate_cents"
            />
          )}
          {checkAccess('view', 'JobProfile', 'charge_overtime_multiplier') && (
            <NumberField source="charge_overtime_multiplier" />
          )}
          {checkAccess('view', 'JobProfile', 'charge_seventh_day_rule_enabled') && (
            <BooleanField source="charge_seventh_day_rule_enabled" />
          )}
          {checkAccess('view', 'JobProfile', 'override_rates') && (
            <BooleanField source="override_rates" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_base_hourly_rate_cents') && (
            <MoneyField label="Payout Base Hourly Rate" source="payout_base_hourly_rate_cents" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_callback_multiplier') && (
            <NumberField source="payout_callback_multiplier" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_double_overtime_multiplier') && (
            <NumberField source="payout_double_overtime_multiplier" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_max_daily_ot_hours') && (
            <NumberField source="payout_max_daily_ot_hours" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_max_daily_regular_hours') && (
            <NumberField source="payout_max_daily_regular_hours" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_max_weekly_regular_hours') && (
            <NumberField source="payout_max_weekly_regular_hours" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_on_call_hourly_rate_cents') && (
            <MoneyField
              label="Payout On Call Hourly Rate"
              source="payout_on_call_hourly_rate_cents"
            />
          )}
          {checkAccess('view', 'JobProfile', 'payout_overtime_multiplier') && (
            <NumberField source="payout_overtime_multiplier" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_seventh_day_rule_enabled') && (
            <BooleanField source="payout_seventh_day_rule_enabled" />
          )}
          {checkAccess('view', 'JobProfile', 'payout_taxable_hourly_rate_cents') && (
            <MoneyField
              label="Payout Taxable Hourly Rate"
              source="payout_taxable_hourly_rate_cents"
            />
          )}
          {checkAccess('view', 'JobProfile', 'created_at') && <TimestampFields />}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default JobProfileTab;

import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import AccountPermissionGroupsInput from './AccountPermissionGroupInput';
import CompanyInput from 'components/CompanyInput';

const AccountsCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput
        source="email"
        validate={[required()]}
        name="email"
        data-testid="create_account-email_field"
      />
      <CompanyInput />
      <AccountPermissionGroupsInput />
    </SimpleForm>
  </Create>
);

export default AccountsCreate;

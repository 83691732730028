import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import {
  useRefresh,
  useNotify,
  useRecordContext,
  TextInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  useTheme,
  required,
  maxValue,
  minValue,
} from 'react-admin';
import create from 'utils/api/create';
import MoneyInput from 'components/MoneyInput';
import useBillingGroup from 'hooks/useBillingGroup';
import { DisplayHelpers } from 'utils/helpers';

const FormToolbar = (props) => {
  const [theme] = useTheme();
  return (
    <Toolbar
      {...props}
      sx={{
        bgcolor: theme.palette?.mode === 'dark' ? '#414141' : '#FCFDFF',
        justifyContent: 'flex-end',
      }}
    >
      <SaveButton label="Apply" alwaysEnable />
    </Toolbar>
  );
};

const ApplyUserBalanceButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const { data: billingGroup } = useBillingGroup(record.billing_group_id);
  if (!billingGroup) {
    return null;
  }

  const applyUserBalance = async (data) => {
    try {
      await create(
        `/v3/admin/itemized_transactions`,
        {
          invoice_id: record.id,
          amount_cents: data.amount_cents,
          notes: !data.notes ? undefined : data.notes,
        },
        'ItemizedTransaction',
      );
      notify('User Balance Applied', { type: 'success' });
      refresh();
      setOpen(false);
    } catch (error) {
      const errorMessage =
        (error as any).response.data.message ?? (error as any).message ?? 'Something went wrong';
      notify(errorMessage, { type: 'warning' });
    }
  };

  if (['pending', 'paid'].includes(record.status)) {
    return null;
  }
  return (
    <>
      <Button onClick={() => setOpen(true)} size="small" variant="outlined" color="primary">
        Apply Billing Group Balance
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Apply Billing Group Balance</DialogTitle>
        <DialogContent>
          <Typography variant="body2" pb={1}>
            Billing Group Balance: {DisplayHelpers.money(billingGroup.balance_cents)}
          </Typography>
          <Typography variant="body2">
            Invoice Amount Due: {DisplayHelpers.money(record.amount_due_cents)}
          </Typography>
          <SimpleForm
            defaultValues={{
              amount_cents: Math.min(record.amount_due_cents, billingGroup.balance_cents),
              notes: '',
            }}
            onSubmit={applyUserBalance}
            toolbar={<FormToolbar />}
          >
            <MoneyInput
              label="Amount to apply"
              source="amount_cents"
              validate={[
                required(),
                minValue(1, 'Must apply more than $0'),
                maxValue(
                  billingGroup.balance_cents,
                  'Cannot apply more than the billing group balance',
                ),
                maxValue(
                  record.amount_due_cents,
                  'Cannot apply more than the amount due on the invoice',
                ),
              ]}
            />
            <TextInput source="notes" />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApplyUserBalanceButton;

import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const ProfessionalInput = (props) => (
  <ReferenceInput gql={['id', 'name']} {...props}>
    <AutocompleteInput
      optionText={(o) => `${o?.id}`}
      {...getChildrenProps(props)}
      data-testid="professional-input"
    />
  </ReferenceInput>
);

ProfessionalInput.defaultProps = {
  label: 'Professional',
  source: 'professional_id',
  reference: 'Professional',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
};

export default ProfessionalInput;

import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';

const ItemizedTransactionsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="ItemizedTransaction"
      target="billing_group_id"
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          label="ID"
          render={(v) => <LinkField to={`/ItemizedTransaction/${v.id}/show`}>{v.id}</LinkField>}
        />
        <FunctionField
          label="Financial Transaction ID"
          render={(v) => {
            if (v.financial_transaction_id) {
              return (
                <LinkField to={`/FinancialTransaction/${v.financial_transaction_id}/show`}>
                  {v.financial_transaction_id}
                </LinkField>
              );
            }
          }}
        />
        <MoneyField label="Amount" source="amount_cents" />
        <TextField source="from_source" />
        <TextField source="to_source" />
        <FunctionField
          label="InvoiceId"
          render={(v) =>
            v.invoice && <LinkField to={`/Invoice/${v.invoice.id}/show`}>{v.invoice.id}</LinkField>
          }
        />
        <TextField source="notes" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(ItemizedTransactionsTab);

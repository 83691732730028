import getOne from './graphql/getOne';
import getList from './graphql/getList';
import create from './rails/create';
import update from './rails/update';
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import config from '../config';
import getMany from './graphql/getMany';
import getManyReference from './graphql/getManyReference';
import getInputList from './graphql/getInputList';
import impersonate from './rails/impersonate';
import remove from './rails/remove';
import { auth } from './utils';
import getAxios from 'getAxios';
import { DataProvider } from 'react-admin';

const axios = getAxios();

const getToken = async () => {
  const token = await auth.currentUser?.getIdToken();
  return `Bearer ${token}`;
};

const client = new ApolloClient({
  uri: `${config.graphql_url}/graphql`,
  cache: new InMemoryCache(),
  link: from([
    setContext(async () => ({
      headers: {
        authorization: await getToken(),
      },
    })),
    new RetryLink(),
    new HttpLink({
      uri: `${config.graphql_url}/graphql`,
      fetchOptions: {
        mode: 'cors',
        signal: new AbortController().signal,
      },
    }),
  ]),
});

type impersonateFunc = (id: number) => Promise<{ data: { token: any } }>;

const dataProvider: DataProvider & { impersonate: impersonateFunc } = {
  getList: getList(client),
  getInputList: getInputList(client),
  getMany: getMany(client),
  getManyReference: getManyReference(client),
  getOne: getOne(client),
  updateMany: function () {
    return new Promise(() => {
      console.log('updated_many');
    });
  },
  deleteMany: function () {
    return new Promise(() => {
      console.log('updated_many');
    });
  },
  create: create(axios),
  update: update(axios),
  impersonate: impersonate(axios),
  delete: remove(axios),
  client,
};

export default dataProvider;

import RichTextInput from 'components/RichTextInput';
import React from 'react';
import {
  ArrayInput,
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  SelectInput,
  SimpleFormIterator,
  Labeled,
  FormDataConsumer,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { validateSlug } from 'utils/custom-validations';

const SIGNED_DOCUMENT_HELPER_TEXT = 'Set a valid "template_id" value from Hello Sign.';

const validateTemplateId = (value: unknown) => {
  return value && !value['template_id']?.length ? SIGNED_DOCUMENT_HELPER_TEXT : undefined;
};

const QualificationCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput label="Long Name" source="long_name" validate={[required()]} />
      <TextInput label="Short Name" source="short_name" validate={[required()]} />
      <TextInput label="Slug" source="slug" validate={[required(), validateSlug]} />
      <SelectInput
        label="Qualification Type"
        source="qualification_type"
        choices={[
          { id: 'license', name: 'License' },
          { id: 'certification', name: 'Certification' },
          { id: 'document', name: 'Document' },
          { id: 'q_and_a', name: 'Question' },
          { id: 'signed_document', name: 'Signed Document' },
        ]}
        validate={[required()]}
      />
      <Labeled label="Instructions">
        <RichTextInput source="instructions" />
      </Labeled>
      <ArrayInput label="Qualification Attributes" source="qualification_attributes_attributes">
        <SimpleFormIterator>
          <TextInput label="Name" source="name" validate={[required()]} />
          <TextInput label="Slug" source="slug" validate={[required(), validateSlug]} />
          <SelectInput
            label="Field Type"
            source="field_type"
            choices={[
              { id: 'expiration_date', name: 'Expiration Date' },
              { id: 'pdf', name: 'PDF' },
              { id: 'image_front', name: 'Image Front' },
              { id: 'image_back', name: 'Image Back' },
              { id: 'state', name: 'State' },
              { id: 'boolean', name: 'Boolean' },
              { id: 'license_number', name: 'License Number' },
              { id: 'text', name: 'Text' },
              { id: 'signed_document', name: 'Signed Document' },
            ]}
            validate={[required()]}
          />
          <FormDataConsumer>
            {({ getSource, scopedFormData }) =>
              scopedFormData.field_type === 'signed_document' && (
                <JsonInput
                  helperText={SIGNED_DOCUMENT_HELPER_TEXT}
                  source={getSource('data')}
                  validate={[required(), validateTemplateId]}
                />
              )
            }
          </FormDataConsumer>
          <BooleanInput label="Required" source="required" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default QualificationCreate;

import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const CompanyInput = (props) => (
  <ReferenceInput gql={['name']} {...props}>
    <AutocompleteInput
      optionText={(val) => {
        return val ? `${val.name}:${val.id}` : undefined;
      }}
      sx={{ minWidth: 256 }}
      {...getChildrenProps(props)}
    />
  </ReferenceInput>
);

CompanyInput.defaultProps = {
  label: 'Company',
  source: 'company_id',
  reference: 'Company',
  sort: { field: 'name', order: 'ASC' },
  perPage: 25,
};

export default CompanyInput;

import React from 'react';
import { Edit } from 'react-admin';
import PositionRateSettingsForm from '../PositionRateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const MarketPositionRateSettingEdit = () => {
  return (
    <Edit
      mutationMode="pessimistic"
      transform={getTransform('market_id', 'market_position_rate_setting')}
    >
      <PositionRateSettingsForm settingsField="market_position_rate_setting" />
    </Edit>
  );
};

export default MarketPositionRateSettingEdit;

import * as React from 'react';
import { NumberField, useRecordContext } from 'react-admin';
import _ from 'lodash';

const MoneyField = (props) => {
  const { label, source, currencySource = '', style, sortable } = props;
  const record = useRecordContext();
  const valueCents = _.get<number | null>(record, source.split('.')) ?? 0;
  const currency = record?.[currencySource] ?? 'USD';
  return (
    <NumberField
      data-testid="money-field"
      label={label}
      source={source}
      record={{ ...record, [source]: valueCents / 100 }}
      options={{ style: 'currency', currency }}
      style={style}
      sortable={sortable}
    />
  );
};

export default MoneyField;

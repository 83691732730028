import { omit, pick } from 'ramda';

export const getTransform = (rateLevel: string, settingsName: string) => (data: any) => {
  const values = {
    ...data,
    settings: {
      ...data[settingsName],
      ten99_assignment: data[settingsName].ten99_per_diem,
    },
  };
  const settings = values.settings;
  const keysToOmit = Object.keys(settings).filter((key) => settings[key] == null);
  keysToOmit.push('pro_rate_cents', '__typename');
  values.settings = omit(keysToOmit, settings);

  ['w2', 'ten99_per_diem', 'ten99_assignment'].forEach((subSettingKey) => {
    const subSetting = values.settings[subSettingKey];
    if (subSetting) {
      const subKeysToOmit = Object.keys(subSetting).filter((key) => subSetting[key] === null);
      values.settings[subSettingKey] = omit(subKeysToOmit, subSetting);
    }
  });

  return pick([rateLevel, 'position_id', 'specialty_id', 'active', 'settings'], values);
};

import React from 'react';
import { Typography, Link } from '@mui/material';
import { useRecordContext } from 'ra-core';

const MetabaseUrlField = () => {
  const record = useRecordContext();
  const { __typename, id } = record;
  const url = `https://metabase.medely.com/question/1660-audited-actions?audit_type=${__typename}&audit_id=${id}`;
  return (
    <Typography data-testid="url-field">
      <Link href={url}>{url}</Link>
    </Typography>
  );
};

MetabaseUrlField.defaultProps = {
  label: 'Audits',
};

export default MetabaseUrlField;

import * as React from 'react';
import {
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  TextField,
  NumberField,
  ChipField,
  SingleFieldList,
  ReferenceManyField,
  useRecordContext,
  BooleanField,
  FunctionField,
} from 'react-admin';
import {
  Link as MUILink,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FormControl,
} from '@mui/material';
import TimestampFields from 'components/TimestampFields';
import DateTimeField from 'components/DateTimeField';
import EnumField from 'components/Enum/EnumField';
import MoneyField from 'components/MoneyField';
import { ENUMS } from 'components/Enum/enums';
import { Link } from 'react-router-dom';
import NursysField from '../NursysField';
import MetabaseUrlField from 'components/MetabaseUrlField';
import useCheckAccess from 'hooks/useCheckAccess';
import Heading from 'components/Heading';
import IdField from 'components/IdField';
import { DisplayHelpers } from 'utils/helpers';

const ProfileTab = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();
  return (
    <SimpleShowLayout>
      <TextField label="ID" source="id" data-testid="professional_show-id_field" />
      <TextField label="Email" source="account.email" data-testid="professional_show-email_field" />
      <TextField
        label="SSN Last 4"
        source="account.ssn_last_4"
        data-testid="professional_show-ssn_field"
      />
      <FunctionField
        label="Account"
        data-testid="professional_show-account_field"
        render={() => <Link to={`/account/${record.account_id}/show`}>{record.account_id}</Link>}
      />
      <TextField source="kind" data-testid="professional_show-kind_field" />
      <TextField
        label="First Name"
        source="account.first_name"
        data-testid="professional_show-first_name_field"
      />
      <TextField
        label="Middle Name"
        source="account.middle_name"
        data-testid="professional_show-middle_name_field"
      />
      <TextField
        label="Last Name"
        source="account.last_name"
        data-testid="professional_show-last_name_field"
      />
      <TextField
        label="Nickname"
        source="nickname"
        data-testid="professional_show-nickname_field"
      />
      <TextField
        label="Phone"
        source="account.phone_number"
        data-testid="professional_show-phone_number_field"
      />
      <TextField
        label="Paylocity ID"
        source="paylocity_external_id"
        data-testid="professional_show-paylocity_id_field"
      />
      <DateTimeField
        format="MM/dd/yyyy"
        label="Date of Birth"
        source="dob"
        data-testid="professional_show-dob_field"
      />
      <FunctionField
        data-testid="professional_show-recruiter_field"
        label="Recruiter"
        render={(r) =>
          r.recruiter &&
          r.recruiter.account && (
            <Link to={`/Administrator/${r.recruiter_id}/show`}>{r.recruiter.account.name}</Link>
          )
        }
      />
      <BooleanField
        label="W2 Eligible"
        source="w2_eligible"
        data-testid="professional_show-w2_eligible_field"
      />
      <BooleanField
        label="W2 Onboarding Completed"
        source="w2_onboarding_complete"
        data-testid="professional_show-w2_onboarding_complete_field"
      />
      <TextField
        label="Cancellation Ration"
        source="cancellation_ratio"
        data-testid="professional_show-cancellation_ratio_field"
      />
      <TextField
        label="Late Clock In Ratio"
        source="late_clockin_ratio"
        data-testid="professional_show-late_clockin_ratio_field"
      />
      <MoneyField label="Booked Total" source="booked_total_cents" />
      <BooleanField
        label="SMS Notification"
        source="account.sms_notification"
        data-testid="professional_show-sms_notification_field"
      />
      <BooleanField
        label="Email Notification"
        source="account.email_notification"
        data-testid="professional_show-email_notification_field"
      />
      <BooleanField
        label="Travel Email Notification"
        source="travel_email_notification"
        data-testid="professional_show-travel_email_notification_field"
      />
      <TextField
        label="Travel Email Preferences"
        source="travel_email_preferences"
        data-testid="professional_show-travel_email_preferences_field"
      />
      <TextField
        label="Invite Code"
        source="invite_code"
        data-testid="professional_show-invite_code_field"
      />
      <FunctionField
        label="Referral Source"
        render={(r) => {
          const referralSource = r?.referral_source?.label;
          const reason =
            referralSource === 'Other'
              ? `Other - ${r?.other_referral_source ?? ''}`
              : referralSource;
          return reason || '-';
        }}
        data-testid="professional_show-referral_source_field"
      />
      <ArrayField label="Markets" source="markets">
        <SingleFieldList linkType={false} data-testid="professional_show-markets_field">
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <TextField
        label="Stripe External Account ID"
        source="stripe_external_account_id"
        data-testid="professional_show-stripe_external_account_id_field"
      />
      <TextField
        label="Bank Last"
        source="bank_last"
        data-testid="professional_show-bank_last_field"
      />
      <TextField source="detailed_notes" data-testid="professional_show-detailed_notes_field" />
      <TextField source="status" data-testid="professional_show-status_field" />

      <TextField
        label="App Status"
        source="application_status"
        data-testid="professional_show-application_status_field"
      />
      {record.application_status === 'rejected' && <TextField source="reject_reason" />}
      {record.application_status === 'archived' && <TextField source="archive_reason" />}
      <BooleanField
        data-testid="professional_show-phone_interviewed_field"
        source="phone_interviewed"
        valueLabelTrue="YES"
        valueLabelFalse="NO"
        looseValue
      />

      <NursysField />
      <NumberField
        label="Profile Strength"
        source="profile_strength"
        data-testid="professional_show-profile_strength_field"
      />
      <BooleanField
        label="Drug Screening Completed"
        source="drug_screening_completed"
        data-testid="professional_show-drug_screening_completed_field"
      />
      <NumberField label="RFM Recency" source="rfmr" data-testid="professional_show-rfmr_field" />
      <NumberField label="RFM Frequency" source="rfmf" data-testid="professional_show-rfmf_field" />
      <TextField label="RFM" source="rfm" data-testid="professional_show-rfm_field" />
      <ArrayField
        label="Background Checks"
        source="background_check_pqs"
        data-testid="professional_show-background_checks_field"
      >
        <Datagrid bulkActionButtons={false}>
          <IdField />
          <EnumField resource="BackgroundCheckStatus" source="status" />
          <FunctionField
            label="Checkr Report Status"
            render={(v) => {
              if (!v?.metadata?.report_status) {
                return '';
              }

              return DisplayHelpers.professional.status(v.metadata.report_status);
            }}
          />
        </Datagrid>
      </ArrayField>
      <BooleanField
        label="Background Check Authorized"
        source="background_check_authorized"
        data-testid="professional_show-background_check_authorized_field"
      />
      <ArrayField
        label="Supplemental Background Checks"
        source="universal_background_checks"
        data-testid="professional_show-supplemental_background_checks_field"
      >
        <Datagrid bulkActionButtons={false}>
          <IdField />
          <TextField
            label="Order Status"
            resource="UniversalBackgroundCheck"
            source="order_status"
          />
          <TextField
            label="Order Number"
            resource="UniversalBackgroundCheck"
            source="order_number"
          />
          <FunctionField
            label="Order URL"
            render={(r) => (
              <MUILink target="_blank" href={r.order_url}>
                {r.order_url}
              </MUILink>
            )}
          />
        </Datagrid>
      </ArrayField>
      <TextField
        label="Travel State Names"
        source="travel_state_names"
        data-testid="professional_show-travel_state_names_field"
      />

      {checkAccess('actions', 'Professional', 'caps') && (
        <FunctionField
          label="CAPs Check Status"
          render={(r) =>
            r.capsReports && r.capsReports.length
              ? ENUMS.CapsReport.status[r.capsReports[0].status]
              : 'n/a'
          }
        />
      )}

      <Heading>Local Address</Heading>
      <FormControl>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                padding="normal"
                size="small"
                data-testid="professional_show-address_first_label"
              >
                Address First
              </TableCell>
              <TableCell
                padding="normal"
                size="small"
                data-testid="professional_show-address_second_label"
              >
                Address Second
              </TableCell>
              <TableCell padding="normal" size="small" data-testid="professional_show-city_label">
                City
              </TableCell>
              <TableCell padding="normal" size="small" data-testid="professional_show-state_label">
                State
              </TableCell>
              <TableCell padding="normal" size="small" data-testid="professional_show-zip_label">
                Zip
              </TableCell>
              <TableCell
                padding="normal"
                size="small"
                data-testid="professional_show-latitude_label"
              >
                Latitude
              </TableCell>
              <TableCell
                padding="normal"
                size="small"
                data-testid="professional_show-longitude_label"
              >
                Longitude
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell padding="normal" size="small">
                <TextField
                  label="Address First"
                  source="account.address_first"
                  data-testid="professional_show-address_first_field"
                />
              </TableCell>
              <TableCell padding="normal" size="small">
                <TextField
                  label="Address Second"
                  source="account.address_second"
                  data-testid="professional_show-address_second_field"
                />
              </TableCell>
              <TableCell padding="normal" size="small">
                <TextField
                  label="City"
                  source="account.address_city"
                  data-testid="professional_show-city_field"
                />
              </TableCell>
              <TableCell padding="normal" size="small">
                <TextField
                  label="State"
                  source="account.state.abbreviation"
                  data-testid="professional_show-state_field"
                />
              </TableCell>
              <TableCell padding="normal" size="small">
                <TextField
                  label="Zip"
                  source="account.address_zip"
                  data-testid="professional_show-zip_field"
                />
              </TableCell>
              <TableCell padding="normal" size="small">
                <NumberField
                  label="Latitude"
                  options={{ maximumFractionDigits: 10 }}
                  source="account.coordinates.latitude"
                  data-testid="professional_show-latitude_field"
                />
              </TableCell>
              <TableCell padding="normal" size="small">
                <NumberField
                  label="Longitude"
                  options={{ maximumFractionDigits: 10 }}
                  source="account.coordinates.longitude"
                  data-testid="professional_show-longitude_field"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </FormControl>

      <Heading>Taxable Address</Heading>
      <ReferenceManyField
        label="Active Stripe Account"
        reference="StripeAccount"
        target="professional_id"
        filter={{
          active: true,
        }}
      >
        <Datagrid
          rowClick="show"
          bulkActionButtons={false}
          data-testid="professional_show-taxable_address_table"
        >
          <TextField label="ID" source="id" data-testid="professional_show-taxable_address_id" />
          <BooleanField
            label="Active"
            source="active"
            data-testid="professional_show-taxable_address_active"
          />
          <BooleanField
            label="Verified"
            source="verified"
            data-testid="professional_show-taxable_address_verified"
          />
          <TextField
            label="Address First"
            source="address_first"
            data-testid="professional_show-taxable_address_address_first"
          />
          <TextField
            label="Address Second"
            source="address_second"
            data-testid="professional_show-taxable_address_address_second"
          />
          <TextField
            label="City"
            source="address_city"
            data-testid="professional_show-taxable_address_city"
          />
          <TextField
            label="State"
            source="state.abbreviation"
            data-testid="professional_show-taxable_address_state"
          />
          <TextField
            label="Zip"
            source="address_zip"
            data-testid="professional_show-taxable_address_zip"
          />
          <NumberField
            label="Latitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.latitude"
            data-testid="professional_show-taxable_address_latitude"
          />
          <NumberField
            label="Longitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.longitude"
            data-testid="professional_show-taxable_address_longitude"
          />
        </Datagrid>
      </ReferenceManyField>

      <TextField
        label="Legacy Lead ID"
        source="migrated_lead_id"
        data-testid="professional_show-migrated_lead_id_field"
      />

      <TimestampFields />

      {checkAccess('view', 'Audit') && <MetabaseUrlField />}

      {checkAccess('view', 'EmergencyContact') && (
        <ReferenceManyField
          label="Emergency Contacts"
          reference="EmergencyContact"
          target="professional_id"
          data-testid="professional_show-emergency_contact_field"
        >
          <Datagrid rowClick="show" bulkActionButtons={false}>
            <IdField />
            <TextField label="First Name" source="first_name" />
            <TextField label="Last Name" source="last_name" />
            <TextField label="Phone Number" source="phone_number" />
          </Datagrid>
        </ReferenceManyField>
      )}
      <BooleanField
        label="Welcome Box Sent"
        source="welcome_box_sent"
        data-testid="professional_show-welcome_box_sent_field"
      />
    </SimpleShowLayout>
  );
};

export default ProfileTab;

import { useGetList, Identifier } from 'react-admin';

// Billing Manager = billing_manager
// Schedule Manager = scheduler
// Company Admin = company_admin
// Credential Manager = professional_manager
// Schedule Viewer = view_only
// Facility Manager = facility_manager

type SuggestedRoles =
  | 'billing_manager'
  | 'scheduler'
  | 'company_admin'
  | 'professional_manager'
  | 'view_only'
  | 'facility_manager';

type PermissionGroupMappedRoles = Record<SuggestedRoles, Identifier> & Record<string, Identifier>;

const usePermissionGroupRoles = (): PermissionGroupMappedRoles => {
  const { data } = useGetList('PermissionGroup', {});

  const permissionGroups: PermissionGroupMappedRoles = Object.values(data ?? []).reduce(
    (acc, permissionGroup) => {
      acc[permissionGroup.role] = permissionGroup.id;
      return acc;
    },
    {} as PermissionGroupMappedRoles,
  );

  return permissionGroups;
};

export default usePermissionGroupRoles;

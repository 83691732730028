import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  useRecordContext,
  TopToolbar,
  EditButton,
} from 'react-admin';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';
import TimestampFields from 'components/TimestampFields';
import useCheckAccess from 'hooks/useCheckAccess';

const Actions = () => {
  const record = useRecordContext();
  const showEditButton = !record?.invoice?.locked_at;
  return <TopToolbar>{showEditButton && <EditButton />}</TopToolbar>;
};

const RevenueAdjustmentsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<Actions />}>
      <SimpleShowLayout>
        {checkAccess('view', 'RevenueAdjustment', 'id') && <TextField label="ID" source="id" />}
        {checkAccess('view', 'RevenueAdjustment', 'job_id') && (
          <LinkField label="Job ID" source="job.id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'dispute_id') && (
          <LinkField label="Dispute ID" source="dispute_id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'adjustment_type') && (
          <TextField label="Adjustment Type" source="adjustment_type" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'invoice_id') && (
          <LinkField label="Invoice ID" source="invoice.id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'timecard_id') && (
          <LinkField label="Timecard ID" source="timecard.id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'balance_sheet_id') && (
          <LinkField label="Balance Sheet ID" source="balance_sheet.id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'amount_cents') && (
          <MoneyField label="Amount of Cents" source="amount_cents" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'primary_adjustment') && (
          <BooleanField label="Primary Adjustment" source="primary_adjustment" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'past_month_adjustment') && (
          <BooleanField label="Past Month Adjustment" source="past_month_adjustment" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'notes') && (
          <TextField label="Notes" source="notes" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'description') && (
          <TextField label="Professional Notes" source="description" />
        )}
        <TimestampFields />
      </SimpleShowLayout>
    </Show>
  );
};

RevenueAdjustmentsShow.query = gql`
  query GET_ONE($id: Int!) {
    revenueAdjustment(id: $id) {
      id
      job {
        id
      }
      balance_sheet {
        id
      }
      invoice {
        id
        locked_at
      }
      dispute_id
      primary_adjustment
      timecard {
        id
      }
      amount_cents
      notes
      adjustment_type
      amount_currency
      description
      past_month_adjustment
      created_at
      updated_at
    }
  }
`;

export default RevenueAdjustmentsShow;

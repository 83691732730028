import { getAdminFromToken } from './token';

export const configureRollbar = () => {
  const admin = getAdminFromToken();
  // @ts-ignore
  Rollbar.configure({
    payload: {
      admin,
      environment: process.env.NODE_ENV,
    },
  });
};

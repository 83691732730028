import React from 'react';
import { Create } from 'react-admin';
import RateSettingsForm from '../RateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const StateRateSettingCreate = () => (
  <Create transform={getTransform('state_id', 'settings')}>
    <RateSettingsForm />
  </Create>
);

export default StateRateSettingCreate;

import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import JsonInput from 'components/JsonInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useCheckAccess from 'hooks/useCheckAccess';
import AccountPermissionGroupsInput from './AccountPermissionGroupInput';
import StateInput from 'components/StateInput';

const AccountsEdit = () => {
  const checkAccess = useCheckAccess();
  const hasAccountUpdatePermission = (permission: string) =>
    checkAccess('update', 'Account', permission);

  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        {hasAccountUpdatePermission('status') && (
          <EnumSelectInput label="Status" source="status" alwaysOn />
        )}
        {hasAccountUpdatePermission('first_name') && (
          <TextInput label="First Name" source="first_name" data-testid="edit_account-first_name" />
        )}
        {hasAccountUpdatePermission('middle_name') && (
          <TextInput
            label="Middle Name"
            source="middle_name"
            data-testid="edit_account-middle_name"
          />
        )}
        {hasAccountUpdatePermission('last_name') && (
          <TextInput label="Last Name" source="last_name" data-testid="edit_account-last_name" />
        )}
        {hasAccountUpdatePermission('email') && (
          <TextInput label="Email" source="email" data-testid="edit_account-email" />
        )}
        {hasAccountUpdatePermission('email') && (
          <TextInput
            label="Password"
            source="password"
            type="password"
            data-testid="edit_account-password"
          />
        )}
        {hasAccountUpdatePermission('phone_number') && (
          <TextInput
            label="Phone Number"
            source="phone_number"
            data-testid="edit_account-phone_number"
          />
        )}
        {hasAccountUpdatePermission('ssn_last_4') && (
          <TextInput label="SSN Last 4" source="ssn_last_4" data-testid="edit_account-ssn" />
        )}
        {hasAccountUpdatePermission('address_first') && (
          <TextInput
            label="Address First"
            source="address_first"
            data-testid="edit_account-address_first"
          />
        )}
        {hasAccountUpdatePermission('address_second') && (
          <TextInput
            label="Address Second"
            source="address_second"
            data-testid="edit_account-address_second"
          />
        )}
        {hasAccountUpdatePermission('address_city') && (
          <TextInput label="City" source="address_city" data-testid="edit_account-city" />
        )}
        {hasAccountUpdatePermission('state_id') && <StateInput data-testid="edit_account-state" />}
        {hasAccountUpdatePermission('address_zip') && (
          <TextInput label="Zip" source="address_zip" data-testid="edit_account-zip" />
        )}
        {hasAccountUpdatePermission('external_id') && (
          <TextInput
            label="External ID"
            source="external_id"
            data-testid="edit_account-external_id"
          />
        )}
        {hasAccountUpdatePermission('notification_settings') && (
          <JsonInput source="notification_settings" rootKey="Notification Settings" />
        )}
        <AccountPermissionGroupsInput />
      </SimpleForm>
    </Edit>
  );
};

export default AccountsEdit;

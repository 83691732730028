//DON'T COPY THIS FORMAT INTO ANYWHERE ELSE
import React, { useCallback, useEffect } from 'react';
import {
  required,
  AutocompleteArrayInput,
  Identifier,
  useGetList,
  FormDataConsumer,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import usePermissionGroupRoles from './usePermissionGroupRoles';
import AccountPermissionLocationInput from './AccountPermissionLocationInput';
import AccountPermissionBillingGroupInput from './AccountPermissionBillingGroupInput';
import { equals } from 'ramda';
import { AccountType } from 'components/Enum/enums';

//DON'T COPY THIS FORMAT INTO ANYWHERE ELSE
const AccountPermissionGroupsInput = () => {
  const permissionGroups = usePermissionGroupRoles();
  const { setValue } = useFormContext();
  const company_id = useWatch({ name: 'company_id' });
  const permission_group_ids = useWatch({ name: 'permission_group_ids' });
  const billing_group_ids = useWatch({ name: 'billing_group_ids' });
  const location_ids = useWatch({ name: 'location_ids' });
  const account_type = useWatch({ name: 'account_type' });

  const permissionGroupValidation = () => {
    if (permission_group_ids?.length > 1 && hasCompanyAdminPerm(permission_group_ids)) {
      return 'Company Admin cannot be combined with another role';
    }
    if (
      permission_group_ids?.length > 1 &&
      permission_group_ids?.includes(permissionGroups.facility_manager)
    ) {
      return 'Facility Manager cannot be combined with another role';
    }
    if (
      permission_group_ids?.includes(permissionGroups.scheduler) &&
      permission_group_ids?.includes(permissionGroups.view_only)
    ) {
      return 'Schedule Manager cannot be combined with Schedule Viewer';
    }
  };

  const hasCompanyAdminPerm = useCallback(
    (permissionGroupIds: Identifier[] = []) =>
      permissionGroupIds.includes(permissionGroups.company_admin),
    [permissionGroups.company_admin],
  );

  const shouldLocationInputDisabled = () => {
    if (!permission_group_ids || !company_id) {
      return true;
    }

    const onlyHasBillingGroupPerm =
      permission_group_ids.length === 1 &&
      permission_group_ids[0] === permissionGroups.billing_manager;
    if (onlyHasBillingGroupPerm || hasCompanyAdminPerm(permission_group_ids)) {
      return true;
    }

    return false;
  };

  const shouldBillingGroupInputDisabled = () => {
    if (!permission_group_ids || !company_id || hasCompanyAdminPerm(permission_group_ids)) {
      return true;
    }

    const onlyHasBillingGroupPerm =
      permission_group_ids.length === 1 &&
      permission_group_ids[0] === permissionGroups.billing_manager;
    if (onlyHasBillingGroupPerm) {
      return false;
    }
    return true;
  };

  const hasCompanySelected = !!company_id;
  const isLocationInputDisabled = shouldLocationInputDisabled();
  const isBillingGroupInputDisabled = shouldBillingGroupInputDisabled();
  const setSelectedLocationIds = useCallback(
    (values) => setValue('location_ids', values),
    [setValue],
  );
  const setSelectedBillingGroupIds = useCallback(
    (values) => setValue('billing_group_ids', values),
    [setValue],
  );

  const { data: locationsData = [] } = useGetList(
    'Location',
    {
      filter: { company_id: company_id },
      pagination: { perPage: Infinity, page: 1 },
    },
    { enabled: hasCompanySelected },
  );
  //DON'T COPY THIS FORMAT INTO ANYWHERE ELSE
  const { data: billingGroupsData = [] } = useGetList(
    'BillingGroup',
    {
      filter: { company_id: company_id, search: { has_locations: true } },
      pagination: { perPage: Infinity, page: 1 },
    },
    { enabled: hasCompanySelected },
  );

  // Resets selected when permission group changes
  //DON'T COPY THIS FORMAT INTO ANYWHERE ELSE
  useEffect(() => {
    let billingGroupIds: Identifier[] = billing_group_ids;
    let locationIds: Identifier[] = location_ids;
    if (hasCompanyAdminPerm(permission_group_ids)) {
      billingGroupIds = billingGroupsData.map((billingGroup) => billingGroup.id);
      locationIds = locationsData.map((location) => location.id);
    } else if (permission_group_ids?.length === 0) {
      billingGroupIds = [];
      locationIds = [];
    }

    if (!equals(billingGroupIds, billing_group_ids)) {
      setSelectedBillingGroupIds(billingGroupIds);
    }
    if (!equals(locationIds, location_ids)) {
      setSelectedLocationIds(locationIds);
    }
  }, [
    hasCompanyAdminPerm,
    setSelectedLocationIds,
    setSelectedBillingGroupIds,
    permission_group_ids,
    billing_group_ids,
    location_ids,
    locationsData,
    billingGroupsData,
  ]);

  // Handles location cascading to billing group
  //DON'T COPY THIS FORMAT INTO ANYWHERE ELSE
  useEffect(() => {
    if (!isLocationInputDisabled && isBillingGroupInputDisabled && Array.isArray(location_ids)) {
      const isSaasPro = account_type === 'professional' && company_id;
      const locationsBillingGroupIds = location_ids.reduce((acc, locationId) => {
        const location = locationsData.find(({ id }) => id === locationId);
        if (location) {
          const locationBillingGroups = location.billing_groups?.map(({ id }) => id) ?? [];
          acc.push(...locationBillingGroups);
        }
        return acc;
      }, []);

      const uniqLocationsBillingGroupIds = Array.from(new Set(locationsBillingGroupIds));
      if (!equals(uniqLocationsBillingGroupIds, billing_group_ids) && !isSaasPro) {
        setSelectedBillingGroupIds(uniqLocationsBillingGroupIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsData, location_ids]);

  // Handles billing group cascading to location
  //DON'T COPY THIS FORMAT INTO ANYWHERE ELSE
  useEffect(() => {
    if (
      !isBillingGroupInputDisabled &&
      isLocationInputDisabled &&
      Array.isArray(billing_group_ids)
    ) {
      const billingGroupsLocationIds = billing_group_ids.reduce((acc, billingGroupId) => {
        const billingGroup = billingGroupsData.find(({ id }) => id === billingGroupId);
        if (billingGroup) {
          const billingGroupLocations = billingGroup.locations?.map(({ id }) => id) ?? [];
          acc.push(...billingGroupLocations);
        }
        return acc;
      }, []);

      const uniqBillingGroupsLocationIds = Array.from(new Set(billingGroupsLocationIds));
      if (!equals(uniqBillingGroupsLocationIds, location_ids)) {
        setSelectedLocationIds(uniqBillingGroupsLocationIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingGroupsData, billing_group_ids]);

  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (
          [AccountType.PROFESSIONAL, AccountType.ADMINISTRATOR].includes(formData?.account_type)
        ) {
          return null;
        }

        return (
          <>
            <ReferenceArrayInput
              reference="PermissionGroup"
              label="Role(s)"
              perPage={50}
              source="permission_group_ids"
              filter={{ account_type: 'facility' }}
            >
              <AutocompleteArrayInput
                optionText="display_name"
                validate={[required(), permissionGroupValidation]}
                data-testid="account-permission_group"
              />
            </ReferenceArrayInput>
            <AccountPermissionLocationInput
              choices={locationsData
                .filter(({ status }) => status === 'active')
                .map(({ id, name }) => ({
                  id,
                  name: `${name}:${id}`,
                }))}
              disabled={isLocationInputDisabled}
            />
            <AccountPermissionBillingGroupInput
              choices={billingGroupsData.map(({ id, name }) => ({ id, name: `${name}:${id}` }))}
              disabled={isBillingGroupInputDisabled}
            />
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export default AccountPermissionGroupsInput;

import React, { memo } from 'react';
import { SelectInput } from 'react-admin';

const RefundFormType = () => (
  <SelectInput
    label="Please select"
    source="type"
    fullWidth
    placeholder="Select check or card or bank debit"
    choices={[
      {
        id: 'check',
        name: 'Check',
      },
      {
        id: 'credit',
        name: 'Card',
      },
      {
        id: 'bank_debit',
        name: 'Bank debit',
      },
      {
        id: 'ach',
        name: 'ACH',
      },
      {
        id: 'stripe_ach_credit_transfer',
        name: 'Stripe ACH',
      },
    ]}
  />
);

export default memo(RefundFormType);

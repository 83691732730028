import dataProviderUpdate from 'data_provider/rails/update';
import getAxios from 'getAxios';

const axios = getAxios();
const update = async (url: string, data: object, previousData, resource = '') => {
  try {
    const params = { id: null, data, previousData: previousData || data };
    await dataProviderUpdate(axios)(resource, params, url);
  } catch (error) {
    throw error;
  }
};

export default update;

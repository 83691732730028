import React from 'react';
import { CreateButton, FilterButton, TopToolbar } from 'react-admin';
import { Button, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';

export const LocationListActions = () => {
  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/locations_import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      <FilterButton />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hiddenInput}
        <Button
          disabled={uploading}
          onClick={openFilePrompt}
          variant="outlined"
          size="small"
          endIcon={uploading ? <CircularProgress size={20} /> : undefined}
        >
          Import&nbsp;CSV
        </Button>
      </div>
      <CreateButton />
    </TopToolbar>
  );
};

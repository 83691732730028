import RichTextInput from 'components/RichTextInput';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  Edit,
  Labeled,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

const QualificationEdit = () => (
  <Edit redirect="show">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="long_name" />
      <TextField source="slug" />
      <SelectInput
        choices={[
          { id: 'licence', name: 'Licence' },
          { id: 'certification', name: 'Certification' },
          { id: 'document', name: 'Document' },
          { id: 'q_and_a', name: 'Question & Answer' },
          { id: 'signed_document', name: 'Signed Document' },
        ]}
        source="qualification_type"
      />
      <SelectInput
        choices={[
          { id: 'active', name: 'Active' },
          { id: 'inactive', name: 'Inactive' },
        ]}
        source="status"
      />
      <Labeled label="Instructions">
        <RichTextInput source="instructions" />
      </Labeled>
      <ReferenceManyField
        label="Qualification Attributes"
        reference="QualificationAttribute"
        target="qualification_id"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="ID" source="id" />
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="field_type" />
          <BooleanField source="required" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
);

export default QualificationEdit;

import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
} from 'react-admin';
import ChargePositionRateSettingsTab from 'components/tabs/ChargePositionRateSettingsTab';
import PayPositionRateSettingsTab from 'components/tabs/PayPositionRateSettingsTab';
import RateSettingsTab from './tabs/RateSettingsTab';
import useCheckAccess from 'hooks/useCheckAccess';
import TimestampFields from 'components/TimestampFields';

const MarketShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show data-testid="market-show">
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <NumberField label="ID" source="id" />
          <TextField label="Display Name" source="display_name" />
          <TextField source="name" />
          <TextField source="timezone" />
          <TextField label="State Name" source="state.name" />
          <TimestampFields />
        </Tab>
        {checkAccess('view', 'MarketRateSetting') && (
          <Tab label="Rate settings" path="rate-settings">
            <RateSettingsTab />
          </Tab>
        )}
        {checkAccess('view', 'MarketPositionRateSetting') && (
          <Tab label="Pay Position Rate settings" path="pay-position-rate-settings">
            <PayPositionRateSettingsTab />
          </Tab>
        )}
        {checkAccess('view', 'MarketPositionRateSetting') && (
          <Tab label="Charge Position Rate settings" path="charge-position-rate-settings">
            <ChargePositionRateSettingsTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

MarketShow.query = gql`
  query market($id: Int!) {
    market(id: $id) {
      id
      display_name
      name
      state {
        id
        name
      }
      timezone
      created_at
      updated_at
    }
  }
`;

export default MarketShow;

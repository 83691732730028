import * as R from 'ramda';
import React from 'react';
import {
  TextField,
  BooleanField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  NumberField,
  ReferenceField,
  useRecordContext,
  SimpleShowLayout,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import DateTimeField from 'components/DateTimeField';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import EnumField from 'components/Enum/EnumField';
import { JobTimeField } from 'resources/jobs/JobTimeField';
import MoneyField from 'components/MoneyField';
import { formatter as phoneFormatter } from 'components/PhoneInput';
import MetabaseUrlField from 'components/MetabaseUrlField';
import useCheckAccess from 'hooks/useCheckAccess';
import usePostingFields from 'hooks/usePostingFields';
import { IPostingField } from 'components/PostingField';
import { getPostingFieldComponent } from '../utils';
import { currentTimeZone } from 'utils/time';

const JobDetailsShowTab = () => {
  const record = useRecordContext();
  const checkAccess = useCheckAccess();

  if (!record) {
    return null;
  }
  const { data: postingFieldData } = usePostingFields(record.position_ids, true);

  return (
    <SimpleShowLayout>
      <BooleanField label="Require Physical Signature" source="require_physical_signature" />p
      {record.signed_addendum !== null && (
        <BooleanField label="Signed Addendum" source="signed_addendum" />
      )}
      <BooleanField label="Is Extension" source="is_extension" />
      <BooleanField label="Legacy Per Diem Assignment" source="per_diem" />
      <BooleanField label="Attendance Policy" source="attendance_policy" />
      {record.original_assignment_id && (
        <FunctionField
          label="Original Assignment"
          render={(r) => (
            <Link to={`/Assignment/${r.original_assignment_id}/show`}>
              {r.original_assignment_id}
            </Link>
          )}
        />
      )}
      <TextField label="ID" source="id" />
      <LinkField label="Primary Location" source="primary_location.id" typename="Location" />
      <BooleanField label="Is W2" source="is_w2" />
      {!record.is_w2 && (
        <BooleanField label="Professional Can Create Job" source="professional_can_create_job" />
      )}
      <LinkField label="Professional" source="professional_id" />
      <FunctionField
        label="Markets"
        render={(v) => R.uniq(R.map((l) => l.market?.display_name, v.locations ?? [])).join(', ')}
      />
      <FunctionField
        label="States"
        render={(v) => R.uniq(R.map((l) => l.state?.name, v.locations ?? [])).join(', ')}
      />
      <FunctionField
        label="Account Manager"
        render={(r) =>
          r.administrator &&
          r.administrator.account && (
            <Link to={`/Administrator/${r.administrator_id}/show`}>
              {r.administrator.account.name}
            </Link>
          )
        }
      />
      <FunctionField
        label="Referrer"
        render={(r) =>
          r.referrer_professional &&
          r.referrer_professional.account && (
            <Link to={`/Professional/${r.referrer_professional_id}/show`}>
              {r.referrer_professional.account.name}
            </Link>
          )
        }
      />
      <FunctionField
        label="Clinical Account Manager"
        render={(r) =>
          r.recruiter &&
          r.recruiter.account && (
            <Link to={`/Administrator/${r.recruiter_id}/show`}>{r.recruiter.account.name}</Link>
          )
        }
      />
      <TextField label="VMS Type" source="primary_location.company.vms_type.label" />
      <TextField label="MSP" source="primary_location.company.msp.label" />
      <TextField label="Health System" source="primary_location.company.health_system.label" />
      <TextField label="Order Number" source="order_number" />
      <FunctionField
        label="VMS Order URL"
        render={(r) =>
          r.vms_order_url && (
            <MuiLink href={r.vms_order_url} target="_blank">
              {r.vms_order_url}
            </MuiLink>
          )
        }
      />
      <LinkField label="VMS Assignment" typename="VmsAssignment" source="vms_assignment_id" />
      <BooleanField label="Self Schedule" source="self_schedule" />
      <ArrayField label="Positions" source="positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Surgery Types" source="surgery_types">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Specialties" source="specialties">
        <SingleFieldList linkType={false}>
          <ChipField source="label" />
        </SingleFieldList>
      </ArrayField>
      {postingFieldData?.map((postingField: IPostingField) =>
        getPostingFieldComponent(postingField, record),
      )}
      <DateTimeField
        label="Starts Date"
        format="MM/dd/yyyy"
        source="starts_date"
        timeZone={currentTimeZone()}
      />
      <BooleanField label="Flexible Starts Date" source="flexible_starts_date" />
      <DateTimeField
        label="Ends Date"
        format="MM/dd/yyyy"
        source="ends_date"
        timeZone={currentTimeZone()}
      />
      <JobTimeField
        label="Starts Time"
        format="MM/dd/yyyy HH:mm zzz"
        source="starts_time"
        tzSource="primary_location.timezone_lookup"
      />
      <JobTimeField
        label="Ends Time"
        format="MM/dd/yyyy HH:mm zzz"
        source="ends_time"
        tzSource="primary_location.timezone_lookup"
      />
      <DateField label="Onboarding Due Date" source="onboarding_due_date" />
      <TextField label="Schedule Type" source="schedule_type" />
      <BooleanField label="Lunch Break" source="lunch_break" />
      <TextField label="Jobs Detail" source="jobs_detail" />
      <DateTimeField
        label="Booked At"
        source="booked_at"
        tzSource="primary_location.timezone_lookup"
      />
      <TextField label="Status" source="status" />
      <TextField label="Cancellation Reason" source="cancellation_reason" />
      <BooleanField label="Canceled by Professional" source="canceled_by_professional" />
      <BooleanField label="VMS Hold" source="on_hold" />
      <BooleanField label="Applications Paused" source="paused" />
      <NumberField label="Number of Shifts per Week" source="number_of_shifts_per_week" />
      <NumberField label="Hours per Shift" source="hours_per_shift" />
      <NumberField label="Submittial Max" source="submittal_max" />
      <TextField label="Notes" source="notes" />
      <NumberField label="Estimated Total Hours" source="estimated_total_hours" />
      <BooleanField label="Suspicious" source="suspicious" />
      <TextField label="Patient Population" source="patient_population" />
      <BooleanField label="Completed Early" source="completed_early" />
      <NumberField label="Cancellation Fee Window" source="cancellation_fee_window" />
      <NumberField label="Cancellation Fee Charge Hours" source="cancellation_fee_charge_hours" />
      <NumberField label="Guaranteed Minimum Hours" source="guaranteed_minimum_hours" />
      <NumberField
        label="Weekly Guaranteed Minimum Hours"
        source="weekly_guaranteed_minimum_hours"
      />
      <BooleanField
        label="Enforce Guaranteed Minimum Hours"
        source="enforce_guaranteed_minimum_hours"
      />
      <TextField label="Unit" source="unit" />
      <TextField label="Float Requirements" source="float_requirements" />
      <TextField label="Call Requirements" source="call_requirements" />
      <MoneyField label="Referral Reward" source="referral_reward_cents" />
      <TimestampFields />
      {checkAccess('view', 'Audit') && <MetabaseUrlField />}
      <BooleanField label="SSN Card Image" source="needs_item_url" />
      <BooleanField label="Scrubs Provided" source="scrubs_provided" />
      <BooleanField label="COVID Care" source="covid_care" />
      <BooleanField label="Track Unpaid Breaks" source="track_unpaid_breaks" />
      <BooleanField label="Local Only" source="local_only" />
      <FunctionField
        label="Covid Requirement"
        render={(v) =>
          v.locations?.find((l) => l.covid_requirement)?.covid_requirement ||
          v.locations?.find((l) => l.market?.state?.covid_requirement)?.market.state
            .covid_requirement ||
          v.locations?.find((l) => l.state?.covid_requirement)?.state.covid_requirement ||
          'none'
        }
      />
      <FunctionField
        label="Clinical Contact"
        render={(v) =>
          v.clinical_contact
            ? `${v.clinical_contact.first_name} ${
                v.clinical_contact.last_name
              }, ${phoneFormatter.format(v.clinical_contact.phone_number)}`
            : '-'
        }
      />
      <FunctionField
        label="Booked Method"
        render={(v) => (v.booked_method ? <EnumField record={v} source="booked_method" /> : '-')}
      />
      <FunctionField
        label="Booked Source"
        render={(v) => (v.booked_source ? <EnumField record={v} source="booked_source" /> : '-')}
      />
      <TextField label="VMS Contact Name" source="vms_contact_name" />
      <TextField label="VMS Contact Email" source="vms_contact_email" />
      <TextField label="VMS Contact Phone" source="vms_contact_phone" />
      <TextField label="Number of Openings" source="number_of_openings" />
      <NumberField source="facility_cancellation_hours" />
      <ReferenceField reference="EhrSystem" source="ehr_system_id">
        <TextField source="label" />
      </ReferenceField>
      <BooleanField source="variable_shift_times" />
      <TextField label="Raw jobs detail" source="jobs_detail_raw" />
      <NumberField label="Minimum Years of Experience" source="min_years_of_experience" />
      <NumberField label="Mileage Requirement" source="mileage_requirement" />
      <BooleanField label="VMS Auto Update" source="vms_auto_update" />
    </SimpleShowLayout>
  );
};

export default JobDetailsShowTab;

import React from 'react';
import * as R from 'ramda';
import { EditButton, TopToolbar, useRecordContext, useResourceContext } from 'react-admin';
import ActionButton from 'components/ActionButton';
import { gqlResourceToRails } from 'data_provider/utils';

const PositionRateSettingsShowActions = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const url = `/v3/admin/${gqlResourceToRails(resource)}/${
    record.id
  }/update_related_specialties_rates`;

  const specialRatesData = R.pick(
    ['position_id', 'location_id', 'specialty_id', 'active', 'settings'],
    record,
  );

  return (
    <TopToolbar>
      <ActionButton
        text="Update Related Specialties Rates"
        data={specialRatesData}
        url={url}
        resource={resource}
        method="POST"
      />
      <EditButton />
    </TopToolbar>
  );
};

export default PositionRateSettingsShowActions;

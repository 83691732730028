import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  TopToolbar,
  EditButton,
  BooleanField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import ActionButton from 'components/ActionButton';
import { ENUMS } from 'components/Enum/enums';

const AdminActions = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <TopToolbar>
      {record.status === ENUMS.Administrator.status.active && (
        <ActionButton
          text="Deactivate"
          method="PUT"
          data={{
            status: ENUMS.Administrator.status.disabled,
            account: { status: ENUMS.Account.status.INACTIVE },
          }}
          url={`/v3/admin/administrators/${record.id}`}
        />
      )}
      {record.status === ENUMS.Administrator.status.disabled && (
        <ActionButton
          text="Activate"
          method="PUT"
          data={{
            status: ENUMS.Administrator.status.active,
            account: { status: ENUMS.Account.status.ACTIVE },
          }}
          url={`/v3/admin/administrators/${record.id}`}
        />
      )}
      <EditButton data-testid="administrator-edit-button" />
    </TopToolbar>
  );
};

const AdministratorsShow = () => (
  <Show actions={<AdminActions />} data-testid="administrator-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" data-testid="administrator-show-id" />
      <TextField
        label="First Name"
        source="account.first_name"
        data-testid="administrator-show-first-name"
      />
      <TextField
        label="Last Name"
        source="account.last_name"
        data-testid="administrator-show-last-name"
      />
      <LinkField label="Account" source="account.id" />
      <FunctionField
        label="Permission Groups"
        render={(record) =>
          record.account?.permission_groups?.map(({ display_name }) => display_name).join(', ')
        }
      />
      <TextField
        label="Department"
        source="department"
        data-testid="administrator-show-department"
      />
      <EmailField label="Email" source="account.email" data-testid="administrator-show-email" />
      <TextField
        label="Phone Number"
        source="account.phone_number"
        data-testid="administrator-show-phone"
      />
      <TextField label="Status" source="status" data-testid="administrator-show-status" />
      <BooleanField label="Paused" source="paused" data-testid="administrator-show-paused" />
      <TimestampFields data-testid="administrator-show-time-stamp-fields" />
    </SimpleShowLayout>
  </Show>
);

AdministratorsShow.query = gql`
  query GET_ONE($id: Int!) {
    administrator(id: $id) {
      id
      account {
        id
        first_name
        last_name
        email
        phone_number
        permission_groups {
          id
          display_name
        }
      }
      role
      department
      status
      paused
      created_at
      updated_at
    }
  }
`;

export default AdministratorsShow;

import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, DateField, BooleanField, NumberInput } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import { IdFilter } from 'components/Filter';

const DisputesFilters = [
  <IdFilter alwaysOn />,
  <NumberInput label="Job ID" source="job_id" alwaysOn />,
];

const JobProfileList = () => (
  <List exporter={false} filters={DisputesFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Job" source="job_id" />
      <BooleanField source="marketplace" />
      <TextField source="payout_kind" />
      <DateField showTime options={{ hour12: false }} source="created_at" />
      <DateField showTime options={{ hour12: false }} source="updated_at" />
    </Datagrid>
  </List>
);

JobProfileList.query = gql`
  query GET_LIST($input: JobProfileQueryInput) {
    _jobProfilesMeta(input: $input) {
      count
    }
    jobProfiles(input: $input) {
      id
      job_id
      marketplace
      payout_kind
      active

      bonus_one_time_cents
      charge_base_hourly_rate_cents
      charge_callback_multiplier
      charge_double_overtime_multiplier
      charge_max_daily_ot_hours
      charge_max_daily_regular_hours
      charge_max_weekly_regular_hours
      charge_on_call_hourly_rate_cents
      charge_overtime_multiplier
      charge_seventh_day_rule_enabled

      condition_ids
      override_rates

      payout_base_hourly_rate_cents
      payout_callback_multiplier
      payout_double_overtime_multiplier
      payout_max_daily_ot_hours
      payout_max_daily_regular_hours
      payout_max_weekly_regular_hours
      payout_on_call_hourly_rate_cents
      payout_overtime_multiplier
      payout_seventh_day_rule_enabled
      payout_taxable_hourly_rate_cents

      created_at
      updated_at
    }
  }
`;

export default JobProfileList;

import React, { useEffect } from 'react';
import { useResourceDefinitions, useSidebarState, useTheme } from 'react-admin';
import { Input, InputAdornment } from '@mui/material';
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import { humanizeResourceName } from 'utils/helpers';
import { Search } from '@mui/icons-material';
import * as R from 'ramda';
import { useLocation } from 'react-router';
import inflection from 'inflection';
import _ from 'lodash';

const MenuSearchInput = ({ query, setQuery }) => {
  const [isSidebarOpen] = useSidebarState();

  if (!isSidebarOpen) {
    return null;
  }

  return (
    <Input
      sx={{ margin: 1 }}
      value={query}
      fullWidth
      onChange={(e) => setQuery(e.target.value)}
      data-testid="menu-search"
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
    />
  );
};

const MedelyMenu = (props) => {
  const [query, setQuery] = React.useState('');
  const [theme] = useTheme();
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
  const { groups } = useResources(query, resources);
  const { pathname } = useLocation();

  useEffect(() => {
    const title = _.flowRight(
      inflection.humanize,
      inflection.underscore,
      (s) => s.join(' - '),
      (s) => s.split('/'),
      (s) => s.slice(1, Infinity),
    )(pathname);
    document.title = title;
  }, [pathname]);

  const currentResource = pathname.split('/')[1];
  const openItemList = [resourcesDefinitions?.[currentResource]?.options?.group || 'root'];
  if (currentResource === 'Requirement') {
    openItemList.push('Qualifications.Requirements');
  }

  return (
    <MultiLevelMenu
      {...props}
      variant="categories"
      openItemList={openItemList}
      sx={{
        bgcolor: theme.palette?.mode === 'dark' ? '#101112' : '#FCFDFF',
        '& .MuiListItem-root': {
          fontSize: '1rem',
          padding: 0.5,

          '.RaMenuItem-link': {
            width: '100%',
            textWrap: 'wrap',
          },
        },
      }}
      data-testid="menu"
    >
      <MenuSearchInput query={query} setQuery={setQuery} />
      <MultiLevelMenu.Item name="home" to="/" label="Home" data-testid="menu-home" />
      {(groups.root || []).map((resource) => (
        <MultiLevelMenu.Item
          key={resource.name}
          name={resource.name}
          to={`/${resource.name}`}
          label={resource.options.label || humanizeResourceName(resource).name}
          data-testid="menu-groups"
        />
      ))}
      {Object.keys(groups)
        .sort()
        .filter(R.complement(R.equals('root')))
        .map((parentMenu) => (
          <MultiLevelMenu.Item key={parentMenu} name={parentMenu} label={parentMenu}>
            {groups[parentMenu].map((resource) =>
              resource.name === 'Requirement' ? (
                <RequirementsMenu key={resource.name} />
              ) : (
                <MultiLevelMenu.Item
                  key={resource.name}
                  name={resource.name}
                  to={`/${resource.name}`}
                  label={resource.options.label || humanizeResourceName(resource).name}
                />
              ),
            )}
          </MultiLevelMenu.Item>
        ))}
    </MultiLevelMenu>
  );
};

const RequirementsMenu = () => (
  <MultiLevelMenu.Item
    name="Requirement"
    label="Requirements"
    to="/Requirement?filter={}"
    data-testid="menu-requirement"
  >
    <MultiLevelMenu.Item
      name="kind"
      to={`/Requirement/Medely?filter={"search": {"medely_requirements": true}}`}
      label="Medely Requirements"
      data-testid="menu-requirement-medely"
    />
    <MultiLevelMenu.Item
      name="kind"
      to={`/Requirement/Company?filter={"search": {"company_requirements": true}}`}
      label="Company Requirements"
      data-testid="menu-requirement-company"
    />
    <MultiLevelMenu.Item
      name="kind"
      to={`/Requirement/VMS?filter={"search": {"vms_requirements": true}}`}
      label="VMS Requirements"
      data-testid="menu-requirement-VMS"
    />
    <MultiLevelMenu.Item
      name="kind"
      to={`/Requirement/State?filter={"search": {"state_requirements": true}}`}
      label="State Requirements"
      data-testid="menu-requirement-state"
    />
    <MultiLevelMenu.Item
      name="kind"
      to={`/Requirement/Location?filter={"search": {"location_requirements": true}}`}
      label="Location Requirements"
      data-testid="menu-requirement-location"
    />
  </MultiLevelMenu.Item>
);

export default MedelyMenu;

function useResources(q, resources) {
  return React.useMemo(() => {
    const getName = _.property('name');
    const testQuery = _.flowRight<any, any, any>(R.test(new RegExp(q, 'i')), getName);
    const hasList = _.overEvery([
      _.property<string, any>('hasList'),
      R.complement(R.pathEq(['options', 'group'], false)),
    ]);
    const filtered = _.flowRight<any, any, any, any>(
      R.sortBy(R.pathOr(0, ['options', 'order'])),
      (arr) => _.sortBy(arr, 'name'),
      R.filter(_.overEvery([hasList, testQuery])),
    )(resources);
    const groups = R.groupBy(groupFn, filtered);
    return { groups };
  }, [q, resources]);

  function groupFn(resource) {
    if (!resource.options || !resource.options.group) {
      return 'root';
    }
    return resource.options.group;
  }
}

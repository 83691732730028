import StateInput from './StateInput';

type KnownFieldType = 'state';

const FIELD_TYPES = {
  state: StateInput,
};

const getFieldTypeComponent = (attributeType: KnownFieldType) => {
  const component = FIELD_TYPES[attributeType];
  if (component) {
    return component;
  }

  return () => null;
};

export default getFieldTypeComponent;

import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  TextField,
  NumberField,
  FunctionField,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  EditButton,
  useRecordContext,
  TopToolbar,
} from 'react-admin';
import ImpersonateButton from 'components/ImpersonateButton';
import TimestampFields from 'components/TimestampFields';
import JsonField from 'components/JsonField';
import renderRole from './AccountRole';
import useCheckAccess from 'hooks/useCheckAccess';
import BillingGroupsTab from './tabs/BillingGroupsTab';
import LocationsTab from './tabs/LocationsTab';

const AccountActions = () => {
  const record = useRecordContext();

  return record ? (
    <TopToolbar>
      <EditButton data-testid="account-edit_button" />
      <ImpersonateButton account={record} />
    </TopToolbar>
  ) : null;
};

const AccountsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<AccountActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab data-testid="account-info_tab" label="info">
          <TextField label="ID" source="id" data-testid="account-id_field" />
          <FunctionField
            data-testid="account-permission_groups_field"
            label="Permission Groups"
            render={(record) =>
              record.permission_groups?.map(({ display_name }) => display_name).join(', ')
            }
          />
          <FunctionField
            label="Role Record"
            render={renderRole}
            data-testid="account-role_record_field"
          />
          <TextField
            label="First Name"
            source="first_name"
            data-testid="account-first_name_field"
          />
          <TextField
            label="Middle Name"
            source="middle_name"
            data-testid="account-middle_name_field"
          />
          <TextField label="Last Name" source="last_name" data-testid="account-last_name_field" />
          <TextField label="Email" source="email" data-testid="account-email_field" />
          <TextField
            label="Phone Number"
            source="phone_number"
            data-testid="account-phone_number_field"
          />
          <TextField label="Status" source="status" data-testid="account-status_field" />
          <TextField label="SSN Last 4" source="ssn_last_4" data-testid="account-ssn_field" />
          <NumberField
            label="Latitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.latitude"
            data-testid="account-latitude_field"
          />
          <NumberField
            label="Longitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.longitude"
            data-testid="account-longitude_field"
          />
          <TextField
            label="Address First"
            source="address_first"
            data-testid="account-address_first_field"
          />
          <TextField
            label="Address Second"
            source="address_second"
            data-testid="account-address_second_field"
          />
          <TextField label="City" source="address_city" data-testid="account-city_field" />
          <TextField label="State" source="state.name" data-testid="account-state_field" />
          <TextField label="Zip" source="address_zip" data-testid="account-zip_field" />
          <TextField label="External ID" source="external_id" data-testid="account-external_id" />
          <JsonField source="notification_settings" rootKey="Notifications" />
          <NumberField
            label="Sign In Count"
            source="sign_in_count"
            data-testid="account-sign_in_count_field"
          />
          <TimestampFields />
        </Tab>
        {checkAccess('view', 'Location') && (
          <Tab data-testid="account-locations_tab" label="Locations" path="locations">
            <LocationsTab />
          </Tab>
        )}
        {checkAccess('view', 'BillingGroup') && (
          <Tab
            data-testid="account-billing_groups_tab"
            label="Billing Groups"
            path="billing-groups"
          >
            <BillingGroupsTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

AccountsShow.query = gql`
  query GET_ONE($id: Int!) {
    account(id: $id) {
      id
      account_type
      first_name
      middle_name
      last_name
      email
      phone_number
      ssn_last_4
      address_first
      address_second
      address_city
      address_zip
      professional {
        id
      }
      company {
        id
      }
      administrator {
        id
      }
      state {
        id
        name
      }
      coordinates {
        latitude
        longitude
      }
      permission_groups {
        id
        display_name
      }
      locations {
        id
      }
      billing_groups {
        id
      }
      sign_in_count
      created_at
      updated_at
      notification_settings
      status
      external_id
    }
  }
`;

export default AccountsShow;

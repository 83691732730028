import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DisableableDateInput from 'components/DisableableDateInput';
import DateInput from 'components/DateInput';
import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';

const ProfessionalReferencesCreate = () => (
  <Create redirect="show">
    <SimpleForm defaultValues={{ rehire_eligibility: true }}>
      <ProfessionalInput />
      <BooleanInput
        source="contact_me"
        data-testid="professional-references_create_contact_me_field"
      />
      <BooleanInput
        source="receive_emails"
        data-testid="professional-references_create_receive_emails_field"
      />
      <TextInput
        source="first_name"
        data-testid="professional-references_create_first_name_field"
      />
      <TextInput source="last_name" data-testid="professional-references_create_last_name_field" />
      <TextInput source="title" data-testid="professional-references_create_title_field" />
      <TextInput source="email" data-testid="professional-references_create_email_field" />
      <TextInput
        source="phone_number"
        data-testid="professional-references_create_phone_number_field"
      />
      <TextInput source="location" data-testid="professional-references_create_location_field" />
      <TextInput
        source="department"
        data-testid="professional-references_create_department_field"
      />
      <BooleanInput
        source="rehire_eligibility"
        data-testid="professional-references_create_rehire_eligibility_field"
      />
      <EnumSelectInput source="status" />
      <DateInput source="date_obtained" />
      <DateInput source="start_date" />
      <DisableableDateInput dateSource="end_date" disableSource="current_position" />
      <TextInput
        source="comments"
        fullWidth
        multiline
        data-testid="professional-references_create_comments_field"
      />
    </SimpleForm>
  </Create>
);

export default ProfessionalReferencesCreate;

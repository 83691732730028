import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';

const EducationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <LinkField source="professional_profile.id" />
      <TextField source="school_name" />
      <TextField source="degree_type" />
      <TextField source="field_of_study" />
      <TextField label="State" source="location" />
      <DateTimeField source="start_date" />
      <DateTimeField source="end_date" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

EducationShow.query = gql`
  query GET_ONE($id: Int!) {
    education(id: $id) {
      id
      professional_profile {
        id
      }
      school_name
      degree_type
      field_of_study
      location
      start_date
      end_date
      created_at
      updated_at
    }
  }
`;

export default EducationShow;

import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  SelectInput,
  BooleanInput,
  ArrayField,
  SingleFieldList,
  FunctionField,
} from 'react-admin';
import LocaleDateTimeChipField from 'components/LocaleDateTimeChipField';
import List from 'components/List';
import { IdFilter } from 'components/Filter';
import LinkField from 'components/LinkField';
import PositionInput from 'components/PositionInput';
import IdField from 'components/IdField';
import ProfessionalInput from 'components/ProfessionalInput';

const ProfessionalReferencesFilters = [
  <IdFilter alwaysOn />,
  <ProfessionalInput alwaysOn source="search.professional_id" />,
  <SelectInput
    label="Status"
    alwaysOn
    source="status"
    choices={[
      {
        id: 'accepted',
        name: 'Accepted',
      },
      {
        id: 'review',
        name: 'Review',
      },
      {
        id: 'rejected',
        name: 'Rejected',
      },
      {
        id: 'checking',
        name: 'Checking',
      },
      {
        id: 'no_response',
        name: 'No Response',
      },
    ]}
  />,
  <PositionInput label="Professional's Position" alwaysOn source="search.position_id" />,
  <BooleanInput label="Contact Me" source="contact_me" alwaysOn />,
];

const ProfessionalReferencesList = () => (
  <List exporter={false} filters={ProfessionalReferencesFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Professional ID" source="professional_id" />
      <TextField label="Professional Name" source="professional.account.name" />
      <TextField source="status" />

      <FunctionField label="Reference Name" render={(v) => v.first_name + ' ' + v.last_name} />
      <TextField label="Reference Title" source="title" />
      <TextField label="Reference Location" source="location" />
      <ArrayField label="Sent Time" source="sent_times">
        <SingleFieldList>
          <LocaleDateTimeChipField source="professional_reference" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

ProfessionalReferencesList.query = gql`
  query GET_LIST($input: ProfessionalReferenceQueryInput) {
    _professionalReferencesMeta(input: $input) {
      count
    }
    professionalReferences(input: $input) {
      id
      name
      first_name
      last_name
      current_position
      title
      email
      professional_id
      professional {
        id
        account {
          id
          name
        }
      }
      phone_number
      location
      start_date
      end_date
      status
      sent_times
      department
    }
  }
`;

export default ProfessionalReferencesList;

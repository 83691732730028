const config = {
  rails_url: process.env.REACT_APP_RAILS_URL,
  graphql_url: process.env.REACT_APP_GRAPHQL_URL,
  pdf_url: process.env.REACT_APP_PDF_URL,
  resume_url: process.env.REACT_APP_RESUME_SERVER_URL,
  item_url: process.env.REACT_APP_ITEM_URL,
  client_url: {
    facility: process.env.REACT_APP_FACILITY_URL || 'http://localhost:3500',
    professional: process.env.REACT_APP_PROFESSIONAL_URL || 'http://localhost:3501',
  },
  hello_sign_client_id: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
  stripe_connect_url:
    process.env.REACT_APP_STRIPE_CONNECT_URL ||
    (process.env.NODE_ENV === 'production'
      ? 'https://dashboard.stripe.com/connect/accounts/'
      : 'https://dashboard.stripe.com/test/connect/accounts/'),
  stripe_customer_url:
    process.env.REACT_APP_STRIPE_CUSTOMER_URL ||
    (process.env.NODE_ENV === 'production'
      ? 'https://dashboard.stripe.com/customers/'
      : 'https://dashboard.stripe.com/test/customers/'),
  token_exp_ms: 86400000, // 24h,
  color: process.env.REACT_APP_COLOR || '#9c27b0',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBFXF0RZ_8RFi62eNEnuNzscb8i60JlsQw',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  },
  statsigSdkKey:
    process.env.REACT_APP_STATSIG_KEY || 'client-r9VZ6M6DNDfUWqCsTXYEBDSdo4wLgbtIrpnucXJWZ5a',
};

export default config;

import React from 'react';
import {
  Edit,
  SimpleForm,
  AutocompleteArrayInput,
  TextInput,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateTimeInput from 'components/DateTimeInput';
import MoneyInput from 'components/MoneyInput';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import Heading from 'components/Heading';
import JobBreaks from './JobBreaks';
import { Typography } from '@mui/material';
import { ENUMS } from 'components/Enum/enums';
import CancelModal from './CancelModal';
import OverrideRates from './OverrideRates';
import JobTimeInfoSection from './JobTimeInfoSection';
import ClinicalContactInput from 'components/ClinicalContactInput';
import useCheckAccess from 'hooks/useCheckAccess';
import * as R from 'ramda';
import ProfessionalInput from 'components/ProfessionalInput';
import LocationInput from 'components/LocationInput';
import PostingFields from 'components/PostingFields';
import JobFileUpload from './JobFileUpload';

const { status } = ENUMS.Job;

const JobsEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit
      mutationMode="pessimistic"
      redirect="show"
      data-testid="jobs-edit"
      transform={(data) => ({
        ...data,
        breaks: undefined,
        breaks_attributes:
          data.breaks?.map(R.pick(['id', 'duration_minutes', 'starts_time', '_destroy'])) ??
          undefined,
      })}
    >
      <SimpleForm>
        <Heading>Job Time Info</Heading>
        {checkAccess('update', 'Job') && <JobTimeInfoSection />}

        <Heading>Job Description</Heading>
        {checkAccess('update', 'Job', 'location_id') && <LocationInput />}
        {checkAccess('update', 'Job') && (
          <FormDataConsumer>
            {({ formData }) => {
              const { location_id, assignment_id } = formData;
              return (
                <>
                  <PositionInput
                    multiple
                    filter={{
                      active: true,
                      search: {
                        for_locations: location_id
                          ? {
                              location_ids: [location_id],
                              for_job: !assignment_id,
                            }
                          : undefined,
                      },
                    }}
                  />
                  <SpecialtyInput
                    multiple
                    positionSource="position_ids"
                    filter={{ active: true }}
                  />
                </>
              );
            }}
          </FormDataConsumer>
        )}
        {checkAccess('update', 'Job') && (
          <ReferenceArrayInput
            label="Surgery Types"
            reference="SurgeryType"
            source="surgery_type_ids"
          >
            <AutocompleteArrayInput
              optionText="display_name"
              data-testid="job_edit-surgery_type_field"
            />
          </ReferenceArrayInput>
        )}
        {checkAccess('update', 'Job') && <PostingFields />}
        {checkAccess('update', 'Job', 'patient_population') && (
          <EnumSelectInput label="Patient Population" source="patient_population" />
        )}
        {checkAccess('update', 'Job', 'release_to_platform') && (
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.favorite_ids.length > 0) {
                return (
                  <SelectInput
                    label="Release to Platform"
                    source="release_to_platform"
                    choices={[
                      { id: '1', name: '1' },
                      { id: '2', name: '2' },
                      { id: '4', name: '4' },
                      { id: '8', name: '8' },
                    ]}
                  />
                );
              }

              return null;
            }}
          </FormDataConsumer>
        )}
        {checkAccess('update', 'Job', 'override_minimum') && (
          <BooleanInput
            label="Override Minimum Hours"
            source="override_minimum"
            data-testid="job_edit-override_minimum_field"
          />
        )}

        {checkAccess('update', 'Job') && <OverrideRates />}

        {checkAccess('update', 'Job', 'bonus_one_time_cents') && (
          <MoneyInput label="One Time Bonus Cents" source="bonus_one_time_cents" />
        )}
        {checkAccess('update', 'Job', 'detail') && (
          <TextInput
            label="Detail"
            source="detail"
            multiline
            fullWidth
            data-testid="job_edit-detail_field"
          />
        )}
        {checkAccess('update', 'Job', 'parking_reimbursement_cents') && (
          <NumberInput
            label="Parking Reimbursement Cents"
            source="parking_reimbursement_cents"
            data-testid="job_edit-parking_reimbursement_cents_field"
          />
        )}
        {checkAccess('update', 'Job', 'offer_source') && (
          <EnumSelectInput label="Offer Source" source="offer_source" sort={false} />
        )}
        {checkAccess('update', 'Job', 'clinical_contact_account_id') && (
          <ClinicalContactInput label="Clinical Contact" source="clinical_contact_account_id" />
        )}

        <Heading>Job Status</Heading>
        {checkAccess('update', 'Job', 'status') && (
          <FormDataConsumer>{({ formData }) => <CancelModal record={formData} />}</FormDataConsumer>
        )}
        {checkAccess('update', 'Job', 'status') && (
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.status === formData.status) {
                return null;
              }
              if (
                [status.canceled, status.late_cancellation, status.canceled_with_fee].includes(
                  formData.status,
                )
              ) {
                return null;
              }
              if (
                [status.canceled, status.late_cancellation, status.canceled_with_fee].includes(
                  formData.status,
                )
              ) {
                return (
                  <Typography color="error">You might want to cancel using the button</Typography>
                );
              }
              return null;
            }}
          </FormDataConsumer>
        )}
        {checkAccess('update', 'Job', 'status') && (
          <EnumSelectInput label="Status" source="status" />
        )}
        {checkAccess('update', 'Job', 'professional_id') && <ProfessionalInput />}
        {checkAccess('update', 'Job', 'booked_at') && (
          <DateTimeInput label="Booked At" source="booked_at" />
        )}
        {/* {checkAccess('update', 'Job', 'canceled_by_location') && (
          <BooleanInput label="Canceled by Location" source="canceled_by_location" />
        )} */}
        {checkAccess('update', 'Job', 'canceled_by_professional') && (
          <BooleanInput
            label="Canceled by Professional"
            source="canceled_by_professional"
            data-testid="job_edit-canceled_by_professional_field"
          />
        )}
        {checkAccess('update', 'Job', 'extended_by_location') && (
          <BooleanInput
            label="Extended by Location"
            source="extended_by_location"
            data-testid="job_edit-extended_by_location_field"
          />
        )}
        {checkAccess('update', 'Job', 'extended_by_professional') && (
          <BooleanInput
            label="Extended by Professional"
            source="extended_by_professional"
            data-testid="job_edit-extended_by_professional_field"
          />
        )}
        {checkAccess('update', 'Job', 'professional_can_cancel') && (
          <BooleanInput
            label="Professional can Cancel"
            source="professional_can_cancel"
            data-testid="job_edit-professional_can_cancel_field"
          />
        )}
        {checkAccess('update', 'Job', 'updated_in_cancellation_window') && (
          <BooleanInput
            label="Updated in Cancellation Window"
            source="updated_in_cancellation_window"
            data-testid="job_edit-updated_in_cancellation_window_field"
          />
        )}
        {checkAccess('update', 'Job', 'suspicious') && (
          <BooleanInput
            label="Suspicious"
            source="suspicious"
            data-testid="job_edit-suspicious_field"
          />
        )}
        {checkAccess('update', 'Job', 'cancellation_fee_window') && (
          <NumberInput
            label="Cancellation Fee Window"
            source="cancellation_fee_window"
            data-testid="job_edit-cancellation_fee_window_field"
          />
        )}
        {checkAccess('update', 'Job', 'cancellation_fee_charge_hours') && (
          <NumberInput
            label="Cancellation Fee Charge Hours"
            source="cancellation_fee_charge_hours"
            data-testid="job_edit-cancellation_fee_charge_hours_field"
          />
        )}
        {checkAccess('update', 'Job', 'guaranteed_minimum_hours') && (
          <NumberInput
            label="Guaranteed Minimum Hours"
            source="guaranteed_minimum_hours"
            data-testid="job_edit-guaranteed_minimum_hours_field"
          />
        )}
        {checkAccess('update', 'Job') && <JobBreaks />}
        {checkAccess('update', 'Job', 'scrubs_provided') && (
          <BooleanInput
            label="Scrubs Provided"
            source="scrubs_provided"
            data-testid="job_edit-scrubs_provided_field"
          />
        )}
        {checkAccess('update', 'Job', 'covid_care') && (
          <BooleanInput
            label="Covid Care"
            source="covid_care"
            data-testid="job_edit-covid_care_field"
          />
        )}
        {checkAccess('update', 'Job', 'track_unpaid_breaks') && (
          <BooleanInput
            label="Track Unpaid Breaks"
            source="track_unpaid_breaks"
            data-testid="job_edit-track_unpaid_breaks_field"
          />
        )}
        <JobFileUpload type="530" />
        <JobFileUpload type="timesheet-clock-in" />
        <JobFileUpload type="geolocation-override-clock-in" />
        <JobFileUpload type="timesheet-clock-out" />
        <JobFileUpload type="geolocation-override-clock-out" />
        <JobFileUpload type="supervisor-signature" />

        <Heading>For Admin</Heading>
        {checkAccess('update', 'Job', 'admin_notes') && (
          <TextInput
            label="Notes"
            source="admin_notes"
            multiline
            fullWidth
            data-testid="job_edit-admin_notes_field"
          />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default JobsEdit;

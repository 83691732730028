import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  BooleanField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import MetabaseUrlField from 'components/MetabaseUrlField';
import useCheckAccess from 'hooks/useCheckAccess';
import ReferenceField from 'components/ReferenceField';
import { DisplayHelpers } from 'utils/helpers';
import { REFERRAL_SOURCE_ID } from 'resources/referral_sources/util';

const InfoTab = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();
  const isReferralSourceOther = record.referral_source_id === REFERRAL_SOURCE_ID.Other;

  return (
    <SimpleShowLayout>
      {checkAccess('view', 'Company', 'id') && <TextField label="ID" source="id" />}
      {checkAccess('view', 'Company', 'name') && <TextField label="Name" source="name" />}
      {checkAccess('view', 'Company', 'phone_number') && (
        <TextField label="Phone Number" source="phone_number" />
      )}
      {checkAccess('view', 'Company', 'vms_type_id') && (
        <TextField label="VMS Type" source="vms_type.label" />
      )}
      {checkAccess('view', 'Company', 'health_system_id') && (
        <TextField label="Health System" source="health_system.label" />
      )}
      <FunctionField
        label="Accepted Facility TOS"
        render={(record) => (
          <BooleanField record={{ ...record, accepted_tos: true }} source="accepted_tos" />
        )}
      />
      {['sales_associate_id', 'account_manager_id'].map((source) => (
        <ReferenceField
          key={source}
          reference="Administrator"
          source={source}
          gql={[
            ` account {
          id
          first_name
          last_name
        }`,
          ]}
        >
          <FunctionField render={DisplayHelpers.professional.fullName} />
        </ReferenceField>
      ))}
      {checkAccess('view', 'Company', 'msp_id') && <TextField label="MSP" source="msp.label" />}
      {checkAccess('view', 'Company', 'verified') && (
        <BooleanField label="Verified" source="verified" />
      )}
      <BooleanField label="SaaS Credentialing Enabled" source="saas_credentialing_enabled" />
      <BooleanField label="SaaS Scheduling Enabled" source="saas_scheduling_enabled" />
      {checkAccess('view', 'Company', 'uid') && <TextField label="UID" source="uid" />}
      {checkAccess('view', 'Company', 'onboarding_complete') && (
        <BooleanField label="Onboarding Complete" source="onboarding_complete" />
      )}
      {checkAccess('view', 'Company', 'rfm') && <TextField label="RFM" source="rfm" />}
      {checkAccess('view', 'Company', 'referral_source') && (
        <TextField label="Referral Source" source="referral_source.label" />
      )}
      {checkAccess('view', 'Company', 'other_referral_source') && isReferralSourceOther && (
        <TextField label="Other Referral Source" source="other_referral_source" />
      )}
      {checkAccess('view', 'Company', 'disabled_at') && (
        <DateTimeField label="Disabled At" source="disabled_at" format="MM/dd/yyyy HH:mm" />
      )}
      {checkAccess('view', 'Company', 'last_time_job_posted_at') && (
        <DateTimeField
          label="Last Time Job Posted"
          source="last_time_job_posted_at"
          format="MM/dd/yyyy HH:mm"
        />
      )}
      {checkAccess('view', 'Company', 'detailed_notes') && (
        <TextField label="Detailed Notes" source="detailed_notes" />
      )}
      {checkAccess('view', 'Audit') && <MetabaseUrlField />}
    </SimpleShowLayout>
  );
};

export default InfoTab;

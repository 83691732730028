import * as React from 'react';
import {
  TextField,
  Datagrid,
  DeleteWithConfirmButton,
  useRecordContext,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
  useShowContext,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import QuickCreateButton from 'components/QuickCreateButton';
import SpecialtyInput from 'components/SpecialtyInput';

const transform = (values: { professional_id: number; new_specialty_ids: number[] }) =>
  values.new_specialty_ids.map((id) => ({
    professional_id: values.professional_id,
    specialty_id: id,
  }));

const SpecialtiesTab = () => {
  const record = useRecordContext();
  const [searchParams] = useSearchParams();
  const timestamp = searchParams.get('t');
  const { refetch } = useShowContext();
  const initialValues = React.useRef({ professional_id: record.id, new_specialty_ids: [] });
  if (!record) {
    return null;
  }

  React.useEffect(() => {
    refetch();
  }, [refetch, timestamp]);

  return (
    <SimpleShowLayout>
      <QuickCreateButton
        initialValues={initialValues.current}
        resource="ProfessionalSpecialties"
        transform={transform}
      >
        <SpecialtyInput
          multiple
          positionIds={record.position_ids}
          exclude={record.specialty_ids ?? []}
          source="new_specialty_ids"
        />
      </QuickCreateButton>
      <ReferenceManyField
        label={false}
        reference="ProfessionalSpecialty"
        target="professional_id"
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false} data-testid="professional_show-specialties_tab_table">
          <TextField source="specialty_id" data-testid="professional_show-specialties_id_field" />
          <TextField
            label="Display Name"
            source="specialty.label"
            data-testid="professional_show-specialties_display_name_field"
          />
          <DeleteWithConfirmButton
            redirect={`/Professional/${record.id}/show/specialties?t=${Date.now()}`}
            data-testid="professional_show-specialties_delete_button"
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default SpecialtiesTab;

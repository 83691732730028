import React from 'react';
import { Create, SimpleForm, TextInput, required, SelectInput } from 'react-admin';
import PayloadInput from './PayloadInput';
import { validateSlug } from 'utils/custom-validations';

const ConditionCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput label="Name" source="name" validate={[required()]} />
      <TextInput label="Label" source="label" validate={[required()]} />
      <TextInput label="Slug" source="slug" validate={[required(), validateSlug]} />
      <SelectInput
        label="Condition Type"
        source="condition_type"
        choices={[
          { id: 'application_q_and_a', name: 'Application Q & A' },
          { id: 'certification', name: 'Certification' },
          { id: 'document', name: 'Document' },
          { id: 'facility_onboarding', name: 'Facility Onboarding' },
          { id: 'license', name: 'License' },
          { id: 'signed_document', name: 'Signed Document' },
          { id: 'typeform', name: 'Typeform' },
          { id: 'work_history', name: 'Work History' },
        ]}
        validate={[required()]}
      />
      <SelectInput
        label="Display Category"
        source="display_category"
        choices={[
          { id: 'facility_documents', name: 'Facility Documents' },
          { id: 'general_forms', name: 'General Forms' },
          { id: 'required_credentials', name: 'Required Credentials' },
          { id: 'state_specific', name: 'State Specific' }
        ]}
      />
      <PayloadInput />
    </SimpleForm>
  </Create>
);

export default ConditionCreate;

import React from 'react';
import {
  TextField,
  BooleanField,
  NumberField,
  SimpleShowLayout,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import ProRateField from 'components/ProRateField';
import LinkField from 'components/LinkField';
import PercentField from 'components/PercentField';
import W2And1099SubSettingsShow from './W2And1099SubSettingsShow';

const getLevelPrefix = (resource: string) => {
  if (resource === 'LocationPositionRateSetting') {
    return 'location';
  } else if (resource === 'MarketPositionRateSetting') {
    return 'market';
  } else if (resource === 'StatePositionRateSetting') {
    return 'state';
  }
  return '';
};

const PositionRateSettingsShow = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  if (!record) {
    return null;
  }
  const { user_id, market_id, state_id, settings: inheritedSettings, market, state } = record;
  const settingsField = `${getLevelPrefix(resource)}_position_rate_setting`;
  const settings = record[settingsField];
  if (!settings) {
    // react-admin optimistic rendering will cause this to be null for a second when navigating from the list view.
    return null;
  }

  return (
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      {market_id && <LinkField source="market_id" />}
      {market && <TextField label="Market Display" source="market.display_name" />}
      {user_id && <LinkField source="user_id" />}
      {state_id && <LinkField source="state_id" />}
      {state && <TextField label="State" source="state.name" />}
      <TextField label="Position Name" source="position.display_name" />
      <LinkField label="Position ID" source="position_id" />
      <TextField label="Specialty ID" source="specialty_id" />
      <LinkField label="Specialty Name" source="specialty.label" />
      <BooleanField source="active" />
      {settings.charge_rate_cents ? (
        <MoneyField label="Location Rate" source={`${settingsField}.charge_rate_cents`} />
      ) : (
        <NumberField label="Location Rate" />
      )}
      {inheritedSettings.charge_rate_cents &&
      inheritedSettings.margin_percent_1099 &&
      inheritedSettings.ten99_per_diem ? (
        <ProRateField />
      ) : (
        <NumberField label="Professional Rate" />
      )}
      {settings.charge_on_call_hourly_rate_cents ? (
        <MoneyField
          label="Location On Call Rate"
          source={`${settingsField}.charge_on_call_hourly_rate_cents`}
        />
      ) : (
        <NumberField label="Location On Call Rate" />
      )}
      <PercentField
        label="On Call Percentage Fee"
        source={`${settingsField}.on_call_fee_percent`}
        record={record}
      />
      <PercentField label="Margin 1099 Percent" source={`${settingsField}.margin_percent_1099`} />
      <PercentField
        label="Margin W2 Local Percent"
        source={`${settingsField}.margin_percent_w2_local`}
      />
      <PercentField
        label="Margin W2 Travel Percent"
        source={`${settingsField}.margin_percent_w2_travel`}
      />
      <PercentField
        label="Margin W2 TCV Percent"
        source={`${settingsField}.margin_percent_w2_tcv`}
      />
      <MoneyField label="Margin W2 TCV" source={`${settingsField}.margin_w2_tcv_cents`} />
      <MoneyField
        label="Assignment Bonus Tier 2 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_cents`}
      />
      <MoneyField
        label="Assignment Bonus Tier 2 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_tcv_cents`}
      />
      <MoneyField
        label="Assignment Bonus Tier 1 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_cents`}
      />
      <MoneyField
        label="Assignment Bonus Tier 1 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_tcv_cents`}
      />
      {resource !== 'StatePositionRateSetting' && (
        <W2And1099SubSettingsShow source={settingsField} />
      )}
    </SimpleShowLayout>
  );
};

export default PositionRateSettingsShow;

import React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import { Button, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import DateTimeField from 'components/DateTimeField';
import IdField from 'components/IdField';
import { useTimesheetDownload } from 'hooks/useTimesheetDownload';

const TimecardsTab = () => {
  const { downloadFile, downloading, timecardId } = useTimesheetDownload();
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="Timecard"
        target="assignment_id"
      >
        <Datagrid bulkActionButtons={false}>
          <IdField label="Timecard ID" sortBy="id" />
          <DateTimeField label="Starts Time" source="starts_time" />
          <DateTimeField label="Ends Time" source="ends_time" />
          <DateTimeField label="W2 Submit by Time" source="submit_by" />
          <DateTimeField label="Approve by" source="approve_by" />
          <TextField label="Status" source="status" />
          <FunctionField
            label="Timesheet (if available)"
            render={(v) =>
              v.aws_signature_url && (
                <Button
                  disabled={downloading}
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={
                    downloading && timecardId === v.id ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DownloadIcon />
                    )
                  }
                  onClick={() => downloadFile(v.id)}
                >
                  Download Contract
                </Button>
              )
            }
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(TimecardsTab);

import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, DateTimeInput, BooleanInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useCheckAccess from 'hooks/useCheckAccess';
import ProfessionalInput from 'components/ProfessionalInput';

const PayoutsEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        {checkAccess('update', 'Payout', 'amount_cents') && (
          <NumberInput label="Amount Cents" source="amount_cents" />
        )}
        {checkAccess('update', 'Payout', 'amount_currency') && (
          <TextInput label="Amount Currency" source="amount_currency" />
        )}
        {checkAccess('update', 'Payout', 'arrival_date') && (
          <DateTimeInput label="Arrival Date" source="arrival_date" />
        )}
        {checkAccess('update', 'Payout', 'status') && (
          <EnumSelectInput label="Status" source="status" />
        )}
        {checkAccess('update', 'Payout', 'account_type') && (
          <TextInput label="Account Type" source="account_type" />
        )}
        {checkAccess('update', 'Payout', 'professional_id') && <ProfessionalInput />}
        {checkAccess('update', 'Payout', 'stripe_id') && (
          <TextInput label="Stripe ID" source="stripe_id" />
        )}
        {checkAccess('update', 'Payout', 'pause') && <BooleanInput label="Pause" source="pause" />}
        {checkAccess('update', 'Payout', 'source_type') && (
          <TextInput label="Source Type" source="source_type" />
        )}
        {checkAccess('update', 'Payout', 'notes') && <TextInput label="Notes" source="notes" />}
        {checkAccess('update', 'Payout', 'adp_id') && <TextInput label="ADP ID" source="adp_id" />}
        {checkAccess('update', 'Payout', 'processed_on') && (
          <DateTimeInput label="Processed On" source="processed_on" />
        )}
        {checkAccess('update', 'Payout', 'failed_count') && (
          <NumberInput label="Failed Count" source="failed_count" />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default PayoutsEdit;

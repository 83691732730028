import { getResourceQueries } from './utils';
import { getListVariables } from '../getListVariables';
import getInputList from './getInputList';

export default function getList(client) {
  return async (resource, params) => {
    const {
      pagination: { page, perPage },
      sort: { field, order },
      filter,
    } = params;

    if ('_input' in filter) {
      return getInputList(client)(resource, params);
    }

    const queries = getResourceQueries(resource);
    const listQuery = queries.list.query;
    const variables = getListVariables({
      type: 'GET_LIST',
      resource,
      params: { pagination: { page, perPage }, sort: { field, order }, filter },
    } as any);

    const response = await client.query({
      fetchPolicy: 'network-only',
      query: listQuery,
      variables,
    });

    const data = response.data[queries.list.name];
    const metaData = response.data[queries.list.metaName];
    return {
      data,
      total: metaData ? metaData.count : data.length,
    };
  };
}

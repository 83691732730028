import { createBrowserHistory } from 'history';
import React from 'react';
import { Resource, NotFound, memoryStore } from 'react-admin';
import { Admin } from '@react-admin/ra-enterprise';
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import dataProvider from './data_provider';
import Layout from './layout';
import authProvider from './authProvider';
import * as _resources from './resources';
import { themeFromType } from './theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { i18nProvider } from './i18provider';
import useTokenController from 'hooks/useTokenController';
import { withReport } from 'hoc/withReport';
import inflection from 'inflection';
import { configureRollbar } from 'utils/rollbar';
import { QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';
import { auth } from 'data_provider/utils';
import config from './config';

const roleResources = (permissions) => {
  const { view, update: edit, create, delete: remove } = permissions;
  const resourceNames = Object.keys(_resources);
  return resourceNames.map((resourceName) => {
    const permissionName = inflection.singularize(inflection.camelize(resourceName, false));
    return {
      name: permissionName,
      list: view[permissionName] ? _resources[resourceName].list : undefined,
      show: view[permissionName] ? _resources[resourceName].show : undefined,
      edit: edit[permissionName] ? _resources[resourceName].edit : undefined,
      create: create[permissionName] ? _resources[resourceName].create : undefined,
      delete: remove[permissionName] ? _resources[resourceName].delete : undefined,
      options: view[permissionName] ? _resources[resourceName].options : undefined,
    };
  });
};

const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      structuralSharing: false,
    },
  },
});
//@ts-ignore
const NotFoundWithErrorHandler = withReport(() => Rollbar.error('Not Found'))(NotFound);

const App = () => {
  useTokenController();

  return (
    <StatsigProvider
      sdkKey={config.statsigSdkKey}
      user={{
        userID: auth.currentUser?.uid,
        email: auth.currentUser?.email ?? undefined,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <Admin
            lightTheme={themeFromType('light')}
            darkTheme={themeFromType('dark')}
            authProvider={authProvider}
            locale="en"
            queryClient={queryClient}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            layout={Layout}
            loginPage={LoginPage}
            history={history}
            dashboard={Dashboard}
            catchAll={NotFoundWithErrorHandler}
            store={memoryStore()}
          >
            {(permissions) => {
              if (permissions) {
                configureRollbar();
              }

              return roleResources(permissions.calculated_permissions).map((r) => (
                <Resource key={r.name} {...r} />
              ));
            }}
          </Admin>
        </BrowserRouter>
      </LocalizationProvider>
    </StatsigProvider>
  );
};

export default App;

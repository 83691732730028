import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, FunctionField, NumberInput, BooleanInput } from 'react-admin';
import MoneyField from 'components/MoneyField';
import { IdFilter, SearchFilter } from 'components/Filter';
import List from 'components/List';
import EnumField from 'components/Enum/EnumField';
import TransactionLink from './TransactionLink';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import MoneyInput from 'components/MoneyInput';
import DateFilter from 'components/Filter/DateFilter';
import ImportFinancialTransactions from './ImportFinancialTransactions';
import useCheckAccess from 'hooks/useCheckAccess';

const FinancialTransactionsFilters = [
  <IdFilter />,
  <SearchFilter />,
  <NumberInput label="Billing Group ID" source="billing_group_id" alwaysOn />,
  <BooleanInput label="Unapplied" source="search.unapplied" alwaysOn />,
  <EnumSelectInput alwaysOn label="Type" source="kind" />,
  <MoneyInput label="Amount" source="search.amount_cents" alwaysOn />,
  <DateFilter label="Starts Date" alwaysOn source="search.starts_date" />,
  <DateFilter label="Ends Date" alwaysOn source="search.ends_date" />,
];

const FinancialTransactionsList = () => {
  const checkAccess = useCheckAccess();
  return (
    <List
      additionalActions={<ImportFinancialTransactions />}
      exporter={false}
      filters={FinancialTransactionsFilters}
    >
      <Datagrid bulkActionButtons={false}>
        {checkAccess('view', 'FinancialTransaction', 'id') && <LinkField label="ID" source="id" />}
        {checkAccess('view', 'FinancialTransaction', ['kind', 'transaction_id']) && (
          <FunctionField
            label="Payment ID"
            render={(v) => <TransactionLink kind={v.kind} transaction={v.transaction_id} />}
          />
        )}
        {checkAccess('view', 'FinancialTransaction', 'billing_group_id') && (
          <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
        )}
        {checkAccess('view', 'FinancialTransaction', 'amount_cents') && (
          <MoneyField label="Amount" source="amount_cents" />
        )}
        {checkAccess('view', 'FinancialTransaction', 'amount_left_cents') && (
          <MoneyField label="Amount Left" source="amount_left_cents" />
        )}
        {checkAccess('view', 'FinancialTransaction', 'kind') && (
          <EnumField label="Type" source="kind" />
        )}
        {checkAccess('view', 'FinancialTransaction', 'data_source') && (
          <TextField label="Data Source" source="data_source" />
        )}
        {checkAccess('view', 'FinancialTransaction', 'revenue_date') && (
          <DateTimeField label="Revenue Date" source="revenue_date" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'FinancialTransaction', 'created_at') && (
          <DateTimeField label="Created At" source="created_at" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'FinancialTransaction', 'original_financial_transaction_id') && (
          <TextField label="Original Transation ID" source="original_financial_transaction_id" />
        )}
      </Datagrid>
    </List>
  );
};

FinancialTransactionsList.query = gql`
  query GET_LIST($input: FinancialTransactionQueryInput) {
    _financialTransactionsMeta(input: $input) {
      count
    }
    financialTransactions(input: $input) {
      id
      billing_group_id
      amount_cents
      amount_currency
      amount_left_cents
      amount_left_currency
      data_source
      revenue_date
      kind
      created_at
      transaction_id
      original_financial_transaction_id
    }
  }
`;

export default FinancialTransactionsList;

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import useDialog from 'hooks/useDialog';
import { ArchivedStatus } from '../ApplicationStatusControl';
interface ArchiveItemProps {
  updateApplicationStatus: (val: any) => void;
}

const reasonChoices = [
  'Certification',
  'Not enough work experience',
  'No work experience in US',
  'Education',
  'New grad',
  'Waitlisted',
  'Other',
];

const ArchiveItem = ({ updateApplicationStatus }: ArchiveItemProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  return (
    <>
      <MenuItem onClick={openDialog}>Archive</MenuItem>
      <ArchiveDialog
        open={open}
        closeDialog={closeDialog}
        updateApplicationStatus={updateApplicationStatus}
      />
    </>
  );
};

const ArchiveDialog = ({ open, closeDialog, updateApplicationStatus }) => {
  const [archiveReason, setArchiveReason] = useState('');
  const [other, setOther] = useState('');
  const isOtherSelected = archiveReason === 'Other';

  const submitUpdateApplicationStatus = () => {
    let reason = archiveReason;
    if (isOtherSelected) {
      reason += ` - ${other}`;
    }
    updateApplicationStatus({ application_status: ArchivedStatus, archive_reason: reason });
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Archive Reason</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={archiveReason}
          label="Select Archive Reason"
          onChange={({ target }) => setArchiveReason(target.value as string)}
          required
          style={{ width: '550px', marginBottom: '20px' }}
        >
          {reasonChoices.map((reason) => (
            <MenuItem key={reason} value={reason}>
              {reason}
            </MenuItem>
          ))}
        </Select>
        {isOtherSelected && (
          <TextField
            label="Archive Details"
            value={other}
            style={{ width: '550px' }}
            required
            onChange={({ target }) => setOther(target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          disabled={!archiveReason || (isOtherSelected && !other)}
          onClick={submitUpdateApplicationStatus}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveItem;

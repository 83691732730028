import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, FormDataConsumer } from 'react-admin';
import MoneyInput from 'components/MoneyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import _ from 'lodash';
import useCheckAccess from 'hooks/useCheckAccess';
import InvoiceInput from 'components/InvoiceInput';
import JobInput from 'components/JobInput';
import DisputeInput from 'components/DisputeInput';

const Revenue_adjustmentsEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        {checkAccess('update', 'RevenueAdjustment', 'job_id') && <JobInput />}
        {checkAccess('update', 'RevenueAdjustment', 'invoice_id') && (
          <InvoiceInput filter={{ search: { hide_locked: true } }} />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'dispute_id') && <DisputeInput />}
        {checkAccess('update', 'RevenueAdjustment', 'amount_cents') && (
          <MoneyInput label="Amount" source="amount_cents" />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'notes') && (
          <TextInput label="Notes" source="notes" />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'description') && (
          <TextInput label="Description" source="description" />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'adjustment_type') && (
          <EnumSelectInput label="Adjustment Type" source="adjustment_type" format={_.identity} />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'description') && (
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.adjustment_type === 'opt_out') {
                return <NumberInput label="Professional ID" source="description" />;
              }
            }}
          </FormDataConsumer>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default Revenue_adjustmentsEdit;

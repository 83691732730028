import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { BooleanField, Datagrid, BooleanInput } from 'react-admin';
import List from 'components/List';
import MarketInput from 'components/MarketInput';
import PositionInput from 'components/PositionInput';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';

const MarketPositionRateSettingListFilters = [
  <IdInput />,
  <PositionInput emptyText="All" alwaysOn />,
  <MarketInput alwaysOn resettable />,
  <BooleanInput alwaysOn source="active" />,
  <BooleanInput label="Below State Rate" alwaysOn source="search.below_state_rate" />,
];

const MarketPositionRateSettingList = () => (
  <List filters={MarketPositionRateSettingListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <LinkField label="Market" source="market.display_name" />
      <LinkField label="Position" source="position.display_name" />
      <LinkField label="Specialty" source="specialty.label" />
      <MoneyField label="Charge Rate" source="settings.charge_rate_cents" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

MarketPositionRateSettingList.query = gql`
  query marketPositionRateSettings($input: MarketPositionRateSettingQueryInput) {
    _marketPositionRateSettingsMeta(input: $input) {
      count
    }
    marketPositionRateSettings(input: $input) {
      id
      market_id
      market_position_rate_setting_id
      state_position_rate_setting_id
      market_position_rate_setting_active
      state_position_rate_setting_active
      position_id
      specialty_id
      market {
        id
        display_name
      }
      position {
        id
        display_name
      }
      specialty {
        id
        label
      }
      settings {
        fee_percent
        w2_fee_percent
        charge_rate_cents
        margin_percent_1099
        margin_w2_tcv_cents
        on_call_fee_percent
        margin_percent_w2_tcv
        margin_percent_w2_local
        margin_percent_w2_travel
        charge_overtime_multiplier
        payout_overtime_multiplier
        charge_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        payout_on_call_hourly_rate_currency
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        w2
        ten99_assignment
        ten99_per_diem
      }
      active
    }
  }
`;

export default MarketPositionRateSettingList;

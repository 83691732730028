import React from 'react';
import { Box } from '@mui/material';
import ActionButton from 'components/ActionButton';
import { ENUMS } from 'components/Enum/enums';
import useCheckAccess from 'hooks/useCheckAccess';
import { useResourceContext } from 'ra-core';

const {
  Professional: { status },
} = ENUMS;

const EVENT_TRANSITIONS = {
  // complete_background_check_disclosure: {
  //   from: [status.background_check_disclosure, status.deactivated],
  //   to: status.background_check,
  //   permissions: ['action', 'Professionals', 'complete_background_check_disclosure'],
  // },
  // complete_background_check: {
  //   from: [status.background_check, status.deactivated],
  //   to: status.skills,
  //   permissions: ['action', 'Professionals', 'complete_background_check'],
  // },
  activate: {
    from: [
      status.paused,
      status.paused_requirement_submitted,
      status.limited_access,
      status.limited_access_reinstatement,
      status.deactivated,
    ],
    to: status.active,
  },
  pause: {
    from: [
      status.paused_requirement_submitted,
      status.active,
      status.limited_access,
      status.limited_access_reinstatement,
      status.deactivated,
    ],
    to: status.paused,
  },
  complete_pause_requirement: {
    from: [status.paused],
    to: status.paused_requirement_submitted,
  },
  limit_access: {
    from: [status.paused, status.paused_requirement_submitted, status.active],
    to: status.limited_access,
  },
  deactivate: {
    from: [
      status.application,
      status.active,
      status.paused,
      status.paused_requirement_submitted,
      status.limited_access,
      status.limited_access_reinstatement,
    ],
    to: status.deactivated,
    confirmation: (data) => {
      const description = `Are you sure you want to deactivate this staff? ${
        data.in_progress_jobs_count ? 'This staff has booked jobs that will be dropped.' : ''
      }`;
      return {
        title: 'Confirm',
        description,
        cancel: 'Back',
      };
    },
  },
};

const ProfessionalEvents = ({ record }) => {
  const checkAccess = useCheckAccess();
  const resource = useResourceContext();
  if (!record) {
    return null;
  }

  const availableActions = Object.keys(EVENT_TRANSITIONS).filter((k) =>
    EVENT_TRANSITIONS[k].from.includes(record.status),
  );

  return (
    <Box mx={3}>
      {availableActions.map((action) => {
        const confirmation = EVENT_TRANSITIONS[action].confirmation
          ? EVENT_TRANSITIONS[action].confirmation(record)
          : null;
        if (!checkAccess('actions', 'Professional', 'status')) {
          return null;
        }

        return (
          <ActionButton
            key={action}
            text={action}
            resource={resource}
            withConfirmation={Boolean(confirmation)}
            method="POST"
            url={`/v3/admin/professionals/${record.id}/status`}
            confirmTitle={confirmation?.title}
            confirmDescription={confirmation?.description}
            confirmCancel={confirmation?.cancel}
            data={{ status: EVENT_TRANSITIONS[action].to }}
          />
        );
      })}
    </Box>
  );
};

export default ProfessionalEvents;

import DateTimeField from 'components/DateTimeField';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import React from 'react';
import { Datagrid, Pagination, ReferenceArrayField, TextField } from 'react-admin';
import { currentTimeZone } from 'utils/time';

const TimecardsTab = () => {
  const checkAccess = useCheckAccess();

  return (
    <ReferenceArrayField
      pagination={<Pagination />}
      reference="Timecard"
      source="timecard_ids"
      label=""
    >
      <Datagrid bulkActionButtons={false}>
        {checkAccess('view', 'Timecard', 'id') && <IdField />}
        <LinkField label="Assignment ID" source="assignment.id" />
        <LinkField label="Professional ID" source="professional.id" />
        {checkAccess('view', 'Timecard', 'status') && <TextField label="Status" source="status" />}
        {checkAccess('view', 'Timecard', 'starts_time') && (
          <DateTimeField
            label="Starts Time"
            format="MM/dd/yyyy HH:mm zzz"
            source="starts_time"
            timeZone={currentTimeZone()}
          />
        )}
        {checkAccess('view', 'Timecard', 'ends_time') && (
          <DateTimeField
            label="Ends Time"
            format="MM/dd/yyyy HH:mm zzz"
            source="ends_time"
            timeZone={currentTimeZone()}
          />
        )}
      </Datagrid>
    </ReferenceArrayField>
  );
};

export default React.memo(TimecardsTab);

import React from 'react';
import {
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField,
  FunctionField,
  SelectInput,
  TextInput,
  useRecordContext,
  Pagination,
  BooleanField,
} from 'react-admin';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import EnumField from 'components/Enum/EnumField';
import LinkField from 'components/LinkField';
import QuickCreateButton from 'components/QuickCreateButton';
import ReferenceInput from 'components/ReferenceInput';
import ResumeField from './ResumeField';
import { formatDate } from 'utils/date';

const toDate = (value: string | undefined, defaultValue = 'N/A') => {
  if (!value) {
    return defaultValue;
  }
  return formatDate(value);
};

const Section = ({ children, record, resource, action = undefined }: any) => (
  <Box my={4} display="inline-block">
    <Typography variant="h5" data-testid="professional_show-heading">
      {resource.split(/(?=[A-Z])/).join(' ')}&nbsp;
      {action ? (
        action
      ) : (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          to={`/${resource}/create?redirect=/Professional/${
            record.professional_id ?? record.id
          }/show/resume`}
          state={{
            record: { professional_id: record.professional_id ?? record.id },
          }}
          component={Link}
          data-testid="professional_show-create_button"
        >
          + Create
        </Button>
      )}
    </Typography>
    <Box mt={2}>{children}</Box>
  </Box>
);

const ResumeTab = ({ isApplicantResume }: { isApplicantResume?: boolean }) => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout sx={{ overflow: 'scroll' }}>
      <ResumeField source={isApplicantResume ? 'professional.resume' : 'resume'} />
      <Section resource="ProfessionalReference" record={record}>
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="ProfessionalReference"
          source={isApplicantResume && 'professional_id'}
          target="professional_id"
        >
          <Datagrid
            rowClick="show"
            bulkActionButtons={false}
            data-testid="professional_show-professional_reference_table"
          >
            <LinkField source="id" />
            <TextField
              source="title"
              data-testid="professional_show-professional_reference_title_field"
            />
            <TextField
              source="department"
              data-testid="professional_show-professional_reference_department_field"
            />
            <TextField
              source="location"
              data-testid="professional_show-professional_reference_location_field"
            />
            <FunctionField
              label="Dates"
              render={(val) => `${toDate(val?.start_date)} - ${toDate(val?.end_date)}`}
              data-testid="professional_show-professional_reference_dates_field"
            />
            <TextField
              source="status"
              data-testid="professional_show-professional_reference_status_field"
            />
          </Datagrid>
        </ReferenceManyField>
      </Section>

      <Section resource="WorkHistory" record={record}>
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="WorkHistory"
          source={isApplicantResume && 'professional_id'}
          target="professional_id"
          sort={{ field: 'start_date', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            data-testid="professional_show-work_history_table"
          >
            <LinkField label="ID" source="id" />
            <BooleanField
              label="Clinical"
              source="clinical"
              data-testid="professional_show-work_history_clinical_field"
            />
            <FunctionField
              label="Dates"
              render={(val) => `${toDate(val?.start_date)} - ${toDate(val?.end_date, 'current')}`}
              data-testid="professional_show-work_history_dates_field"
            />
            <FunctionField
              label="Position"
              render={(r) => `${r?.unit ? `${r?.unit}, ` : ''}${r?.position_title}`}
              data-testid="professional_show-work_history_position_field"
            />
            <TextField
              label="Organization Name"
              source="organization_name"
              data-testid="professional_show-work_history_organization_name_field"
            />
            <TextField
              label="City"
              source="location"
              data-testid="professional_show-work_history_city_field"
            />
            <TextField
              label="State"
              source="state.name"
              data-testid="professional_show-work_history_state_field"
            />
            <EnumField label="Employment Type" source="employment_type" />
          </Datagrid>
        </ReferenceManyField>
      </Section>

      <Section resource="WorkGap" record={record}>
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="WorkGap"
          source={isApplicantResume && 'professional_id'}
          target="professional_id"
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            data-testid="professional_show-work_gap_table"
          >
            <LinkField source="id" />
            <FunctionField
              label="Dates"
              render={(val) => `${toDate(val?.start_date)} - ${toDate(val?.end_date)}`}
              data-testid="professional_show-work_gap_dates_field"
            />
            <TextField
              label="City"
              source="location"
              data-testid="professional_show-work_gap_city_field"
            />
            <TextField
              label="State"
              source="state.name"
              data-testid="professional_show-work_gap_state_field"
            />
            <TextField
              source="explanation"
              data-testid="professional_show-work_gap_explanation_field"
            />
          </Datagrid>
        </ReferenceManyField>
      </Section>

      <Section resource="Education" record={record}>
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="Education"
          source={isApplicantResume && 'professional_id'}
          target="professional_id"
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            data-testid="professional_show-education_table"
          >
            <LinkField source="id" />
            <TextField
              source="school_name"
              data-testid="professional_show-education_school_name_field"
            />
            <TextField
              source="degree_type"
              data-testid="professional_show-education_degree_type_field"
            />
            <TextField
              source="field_of_study"
              data-testid="professional_show-education_field_of_study_field"
            />
            <FunctionField
              label="Dates"
              render={(val) => `${toDate(val?.start_date)} - ${toDate(val?.end_date)}`}
              data-testid="professional_show-education_dates_field"
            />
          </Datagrid>
        </ReferenceManyField>
      </Section>

      <Section
        resource="EhrSystem"
        action={
          <QuickCreateButton
            initialValues={{ professional_id: record.id }}
            resource="ProfessionalProfileEhrSystem"
          >
            <ReferenceInput
              perPage={100}
              sort={{ field: 'label', order: 'ASC' }}
              reference="EhrSystem"
              source="ehr_system_id"
            >
              <SelectInput
                resettable
                optionText="label"
                data-testid="professional-ehr_system_create_field"
              />
            </ReferenceInput>
          </QuickCreateButton>
        }
      >
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="EhrSystem"
          source={isApplicantResume && 'professional_id'}
          target="professional_id"
        >
          <Datagrid bulkActionButtons={false} data-testid="professional_show-ehr_system_table">
            <LinkField source="id" />
            <TextField source="label" data-testid="professional_show-ehr_system_label_field" />
          </Datagrid>
        </ReferenceManyField>
      </Section>

      <Section
        resource="CustomEhrSystem"
        action={
          <QuickCreateButton
            initialValues={{
              professional_id: record.id,
              professional_profile_id: record.id,
            }}
            resource="CustomProfessionalProfileEhrSystem"
          >
            <TextInput
              source="label"
              data-testid="professional_show-custom_ehr_system_create_label_field"
            />
          </QuickCreateButton>
        }
      >
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="CustomProfessionalProfileEhrSystem"
          source={isApplicantResume && 'professional_id'}
          target="professional_id"
        >
          <Datagrid
            bulkActionButtons={false}
            data-testid="professional_show-custom_ehr_system_table"
          >
            <LinkField source="id" />
            <TextField
              source="label"
              data-testid="professional_show-custom_ehr_system_label_field"
            />
          </Datagrid>
        </ReferenceManyField>
      </Section>
    </SimpleShowLayout>
  );
};

export default ResumeTab;

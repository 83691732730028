import React from 'react';
import getFieldTypeComponent from './getFieldTypeComponent';

const QualificationAttributeValueInput = ({
  value,
  attributeSlug,
  qualificationAttributes,
  onChange,
  fieldProps = {},
}) => {
  const selectedAttribute = qualificationAttributes?.find(({ slug }) => slug === attributeSlug);

  if (!selectedAttribute) {
    return null;
  }

  const FieldInput = getFieldTypeComponent(selectedAttribute.field_type);

  if (FieldInput) {
    return <FieldInput value={value} onChange={onChange} {...fieldProps} />;
  }

  return null;
};

export default QualificationAttributeValueInput;

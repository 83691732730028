import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  TextInput,
  CreateButton,
  ReferenceInput,
  AutocompleteInput,
  TopToolbar,
} from 'react-admin';
import List from 'components/List';
import { IdFilter } from 'components/Filter';
import IdField from 'components/IdField';
import renderRole from './AccountRole';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const accountsFilters = [
  <IdFilter data-testid="accounts-id_filter" />,
  <TextInput alwaysOn label="Email" source="email" data-testid="accounts-email_filter" />,
  <TextInput alwaysOn label="Phone" source="phone_number" data-testid="accounts-phone_filter" />,
  <ReferenceInput
    alwaysOn
    source="search.permission_group_id"
    reference="PermissionGroup"
    label="Permission Group"
    perPage={50}
    filter={{
      account_type: 'facility',
    }}
  >
    <AutocompleteInput
      optionText="display_name"
      label="Permission Group"
      data-testid="accounts-permission_group_filter"
    />
  </ReferenceInput>,
  <EnumSelectInput label="Account Type" source="account_type" alwaysOn />,
];

const ListActions = () => (
  <TopToolbar>
    <CreateButton label="Create Facility Account" data-testid="accounts-create_button" />
  </TopToolbar>
);

const AccountsList = () => (
  <List
    exporter={false}
    bulkActionButtons={false}
    filters={accountsFilters}
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={false} data-testid="accounts-table">
      <IdField />
      <TextField label="Email" source="email" data-testid="accounts-email_field" />
      <FunctionField
        label="Role Record"
        render={renderRole}
        data-testid="accounts-role_record_field"
      />
      <TextField
        label="Phone Number"
        source="phone_number"
        data-testid="accounts-phone_number_field"
      />
      <TextField label="Status" source="status" data-testid="accounts-status_field" />
      <NumberField
        label="Sign In Count"
        source="sign_in_count"
        data-testid="accounts-signin_count_field"
      />
    </Datagrid>
  </List>
);

AccountsList.query = gql`
  query GET_LIST($input: AccountQueryInput) {
    accounts(input: $input) {
      id
      email
      sign_in_count
      phone_number
      name
      status
      professional {
        id
      }
      company {
        id
      }
      administrator {
        id
      }
      state {
        id
        name
      }
      sms_notification
      sms_notification_bulk
      email_notification
      permission_groups {
        id
        display_name
      }
    }
    _accountsMeta(input: $input) {
      count
    }
  }
`;

export default AccountsList;

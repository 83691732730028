import React from 'react';
import { gql } from '@apollo/client';
import TimestampFields from 'components/TimestampFields';
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  NumberField,
  Datagrid,
  ReferenceManyField,
  Pagination,
} from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import RevenueAdjustmentsTab from 'components/tabs/RevenueAdjustmentsTab';
import ExpenseAdjustmentsTab from 'components/tabs/ExpenseAdjustmentsTab';
import MetabaseUrlField from 'components/MetabaseUrlField';
import DateTimeField from 'components/DateTimeField';
import TimesheetUpload from './TimesheetUpload';

const DisputesShow = () => {
  return (
    <Show>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Dispute">
          <IdField />
          <LinkField label="Job ID" source="job_id" />
          <LinkField label="Invoice ID" source="invoice_id" />
          <LinkField label="Assigned To Id" source="assigned_to.id" />
          <TextField label="Assigned To" source="assigned_to.name" />
          <LinkField label="Location" source="job.location.id" />
          <LinkField label="Billing Group" source="invoice.billing_group.id" />
          <DateTimeField tzSource="job.location.timezone_lookup" source="job.starts_date" />
          <DateTimeField tzSource="job.location.timezone_lookup" source="original_clock_in" />
          <DateTimeField tzSource="job.location.timezone_lookup" source="original_clock_out" />
          <DateTimeField tzSource="job.location.timezone_lookup" source="adjusted_clock_in" />
          <DateTimeField tzSource="job.location.timezone_lookup" source="adjusted_clock_out" />
          <TextField source="reason" />
          <TextField source="category" />
          <TextField source="resolution" />
          <TextField source="status" />
          <TextField source="facility_notes" />
          <TextField source="admin_notes" />
          <ReferenceManyField
            pagination={<Pagination />}
            label="Dispute Shift Witness"
            reference="dispute_shift_witnesses"
            target="dispute_id"
          >
            <Datagrid bulkActionButtons={false}>
              <LinkField label="ID" source="id" />
              <TextField source="name" />
              <TextField source="position" />
              <TextField source="phone" />
              <TextField source="email" />
            </Datagrid>
          </ReferenceManyField>
          <TextField source="professional_notes" />
          <TimesheetUpload />
          <NumberField label="Original Meal Break Length" source="original_meal_break_minutes" />
          <NumberField label="Adjusted Meal Break Length" source="adjusted_meal_break_minutes" />
          <MetabaseUrlField />
          <TimestampFields />
        </Tab>
        <Tab label="Revenue Adjustments">
          <RevenueAdjustmentsTab target="dispute_id" />
        </Tab>
        <Tab label="Expense Adjustments">
          <ExpenseAdjustmentsTab target="dispute_id" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

DisputesShow.query = gql`
  query GET_ONE($id: Int!) {
    dispute(id: $id) {
      id
      job_id
      invoice_id
      job {
        id
        billing_group_id
        location_id
        location {
          id
          timezone_lookup
        }
      }
      invoice {
        id
        billing_group_id
        billing_group {
          id
        }
      }
      assigned_to {
        id
        account {
          id
          first_name
          last_name
          name
        }
      }
      assigned_to_id
      original_clock_in
      original_clock_out
      adjusted_clock_in
      adjusted_clock_out
      reason
      status
      category
      resolution
      created_at
      updated_at
      facility_notes
      admin_notes
      professional_notes
      total_adjusted_minutes
      original_meal_break_minutes
      adjusted_meal_break_minutes
      original_total_payout_cents
      adjusted_total_payout_cents
      attachment
    }
  }
`;

export default DisputesShow;

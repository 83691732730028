import React from 'react';
import { Edit, SimpleForm, DateInput } from 'react-admin';

const BalanceSheetsEdit = () => (
  <Edit>
    <SimpleForm>
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);

export default BalanceSheetsEdit;

import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  BooleanInput,
  NumberField,
  NumberInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import MoneyField from 'components/MoneyField';
import { IdFilter, SearchFilter, MarketFilter, AccountManagerFilter } from 'components/Filter';
import List from 'components/List';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import MoneyInput from 'components/MoneyInput';
import ImportVoucherIds from './ImportVoucherIds';
import LinkField from 'components/LinkField';
import DateFilter from 'components/Filter/DateFilter';
import useCheckAccess from 'hooks/useCheckAccess';

const InvoicesFilters = [
  <IdFilter />,
  <SearchFilter />,
  <AccountManagerFilter label="Account Manager" alwaysOn source="search.account_manager_id" />,
  <NumberInput label="Billing Group ID" alwaysOn source="billing_group_id" />,
  <EnumSelectInput label="Status" alwaysOn source="status" />,
  <DateFilter label="Starts Date" alwaysOn source="search.starts_date" />,
  <DateFilter label="Ends Date" alwaysOn source="search.ends_date" />,
  <SelectInput
    alwaysOn
    label="Days Late"
    source="search.days_late"
    choices={[
      { name: '0-15', id: 0 },
      { name: '16-30', id: 1 },
      { name: '31-45', id: 2 },
      { name: '45+', id: 3 },
    ]}
  />,
  <MoneyInput label="Amount" source="search.total_cents" alwaysOn />,
  <TextInput label="Voucher ID" alwaysOn source="search.voucher_id" />,
  <BooleanInput alwaysOn label="Failing Payments" source="search.failing_payments" />,
  <BooleanInput alwaysOn label="Failed Payments" source="search.failed_payments" />,
  <MarketFilter />,
];

const InvoicesList = () => {
  const checkAccess = useCheckAccess();

  return (
    <List
      additionalActions={<ImportVoucherIds />}
      exporter={false}
      filters={InvoicesFilters}
      data-testid="invoices-list"
    >
      <Datagrid bulkActionButtons={false}>
        {checkAccess('view', 'Invoice', 'id') && <LinkField label="ID" source="id" />}
        {checkAccess('view', 'Invoice', 'billing_group_id') && (
          <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
        )}
        {checkAccess('view', 'Invoice', 'statement_id') && (
          <LinkField label="Statement ID" source="statement_id" />
        )}
        {checkAccess('view', 'Invoice', 'assignment_id') && (
          <LinkField label="Assignment ID" source="assignment_id" />
        )}
        {checkAccess('view', 'Invoice', 'total_cents') && (
          <MoneyField label="Original Amount" source="total_cents" />
        )}
        {checkAccess('view', 'Invoice', 'amount_due_cents') && (
          <MoneyField label="Current Amount" source="amount_due_cents" />
        )}
        {checkAccess('view', 'Invoice', 'status') && <TextField label="Status" source="status" />}
        {checkAccess('view', 'Invoice', 'failed_count') && (
          <NumberField label="Failed Count" source="failed_count" />
        )}
        {checkAccess('view', 'Invoice', 'failed_reason') && (
          <TextField label="Failed Reason" source="failed_reason" />
        )}
        {checkAccess('view', 'Invoice', 'due_date') && (
          <DateTimeField label="Due Date" source="due_date" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'Invoice', 'paid_date') && (
          <DateTimeField label="Paid Date" source="paid_date" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'Invoice', 'created_at') && (
          <DateTimeField label="Created At" source="created_at" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'Invoice', 'days_late') && (
          <NumberField label="Days Late" source="days_late" />
        )}
        {checkAccess('view', 'Invoice', 'voucher_id') && (
          <TextField label="Voucher ID" source="voucher_id" />
        )}
      </Datagrid>
    </List>
  );
};

InvoicesList.query = gql`
  query GET_LIST($input: InvoiceQueryInput) {
    _invoicesMeta(input: $input) {
      count
    }
    invoices(input: $input) {
      id
      total_cents
      amount_due_cents
      status
      assignment_id
      statement_id
      paid_date
      failed_reason
      failed_count
      due_date
      created_at
      days_late
      voucher_id
      billing_group_id
      billing_group {
        id
        name
      }
    }
  }
`;

export default InvoicesList;

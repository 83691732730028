import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';
import BalanceSheetShowActions from './ShowActions';

const BalanceSheetShow = () => (
  <Show title="Financial Reports" actions={<BalanceSheetShowActions />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <MoneyField source="total_cents" label="Total" />
      <BooleanField source="closed" />
      <DateTimeField label="First Date and Time" source="first_datetime" />
      <TextField source="date" />
      <TextField source="month" />
      <TextField source="year" />
    </SimpleShowLayout>
  </Show>
);

BalanceSheetShow.query = gql`
  query GET_ONE($id: Int!) {
    balanceSheet(id: $id) {
      id
      total_cents
      closed
      date
      month
      year
      first_datetime
    }
  }
`;

export default BalanceSheetShow;

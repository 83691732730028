import React from 'react';
import {
  TextField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SimpleShowLayout,
  Pagination,
} from 'react-admin';
import { Link } from 'react-router-dom';
import LinkField from 'components/LinkField';

const LocationsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      label=""
      reference="AccountLocation"
      pagination={<Pagination />}
      target="account_id"
    >
      <Datagrid bulkActionButtons={false} data-testid="accounts-locations-table">
        <LinkField label="ID" source="location_id" />
        <TextField label="Name" source="location.name" data-testid="account-locations_name_field" />
        <TextField
          label="Status"
          source="location.status"
          data-testid="account-locations_status_field"
        />
        <TextField
          label="Type"
          source="location.location_type.label"
          data-testid="account-locations_type_field"
        />
        <TextField
          label="Phone Number"
          source="location.phone_number"
          data-testid="account-locations_phone_number_field"
        />
        <FunctionField
          label="Account Manager"
          render={(r) =>
            r.location?.account_manager?.account && (
              <Link
                to={`/Administrator/${r.location?.account_manager_id}/show`}
                data-testid="account-locations_account_manager_field"
              >
                {r.location?.account_manager.account.name}
              </Link>
            )
          }
        />
        <FunctionField
          label="Sales Associate"
          render={(r) =>
            r.location?.sales_associate?.account && (
              <Link
                to={`/Administrator/${r.location?.sales_associate_id}/show`}
                data-testid="account-locations_sales_associate_field"
              >
                {r.location?.sales_associate.account.name}
              </Link>
            )
          }
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(LocationsTab);

import DateInput from 'components/DateInput';
import DateTimeInput from 'components/DateTimeInput';
import { ENUMS } from 'components/Enum/enums';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import AdministratorInput from 'components/Filter/AdministratorInput';
import MoneyInput from 'components/MoneyInput';
import PositionInput from 'components/PositionInput';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import SpecialtyInput from 'components/SpecialtyInput';
import React, { useEffect } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  minValue,
  NumberInput,
  required,
  TextInput,
  SelectInput,
  email,
  FormDataConsumer,
  ReferenceInput,
  useRecordContext,
} from 'react-admin';
import Heading from 'components/Heading';
import PhoneInput from 'components/PhoneInput';
import { DisplayHelpers } from 'utils/helpers';
import { Box, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Duration from 'components/Duration';
import CancelButton from '../CancelButton';
import * as R from 'ramda';
import ClinicalContactInput from 'components/ClinicalContactInput';
import useCheckAccess from 'hooks/useCheckAccess';
import { useFormContext } from 'react-hook-form';
import { hoursDiff } from 'utils/time';
import useLocation from 'hooks/useLocation';
import ProfessionalInput from 'components/ProfessionalInput';
import PostingFields from 'components/PostingFields';
import { getEstimatedBreakTimeMinutes } from '../calculations';

const { money } = DisplayHelpers;
const numberOfShiftsOptions = [...Array(7)].map((_, i) => ({
  id: i + 1,
  name: (i + 1).toString(),
}));

const valueChanged = R.complement((prop: string, obj1: unknown, obj2: unknown) =>
  R.eqProps(prop, obj1, obj2),
);

const validateEmail = email();

const statusChangedToCancelled = ({ values, initialValues }) =>
  initialValues &&
  valueChanged('status', values, initialValues) &&
  [ENUMS.Assignment.status.canceled, ENUMS.Assignment.status.completed].includes(values.status);

const completedEarlySwitchedOn = ({ values, initialValues }) =>
  initialValues && valueChanged('completed_early', values, initialValues) && values.completed_early;

const shouldDisplayCancelWarning = (values, initialValues) =>
  R.anyPass([statusChangedToCancelled, completedEarlySwitchedOn])({
    values,
    initialValues,
  });

const getPrimaryLocation = (id) => {
  const { data: location } = useLocation(id);
  return location;
};

const JobDetailsTimeInputs = ({ time_zone }: { time_zone?: string }) => (
  <FormDataConsumer>
    {({ formData }) => (
      <>
        <DateTimeInput
          label="Starts Time"
          source="starts_time"
          time_zone={time_zone}
          validate={[required()]}
        />
        <Grid container alignItems="flex-start">
          <DateTimeInput
            label="Ends Time"
            source="ends_time"
            time_zone={time_zone}
            validate={[required()]}
          />
          <Box ml={3} mt={1}>
            <Duration start={formData.starts_time} end={formData.ends_time} timezone={time_zone} />
          </Box>
        </Grid>
        <DateInput
          label="Onboarding Due Date"
          source="onboarding_due_date"
        />
      </>
    )}
  </FormDataConsumer>
);

const JobDetailsTab = () => {
  const record = useRecordContext();
  const isEdit = Boolean(record?.id);
  const checkAccess = useCheckAccess();
  const { watch, setValue } = useFormContext();

  const starts_time = watch('starts_time');
  const ends_time = watch('ends_time');
  const track_unpaid_breaks = watch('track_unpaid_breaks');
  const primary_location_id = watch('primary_location_id') || record?.primary_location_id;
  const primaryLocation = getPrimaryLocation(primary_location_id);
  const timeZone = primaryLocation?.timezone_lookup ?? record?.primary_location?.timezone_lookup;

  useEffect(() => {
    const shiftHoursDiff = hoursDiff(starts_time, ends_time, primaryLocation?.timezone_lookup);
    const estimatedBreakTimeMinutes = getEstimatedBreakTimeMinutes(
      shiftHoursDiff,
      record?.is_w2,
      track_unpaid_breaks,
    );
    setValue('hours_per_shift', shiftHoursDiff - estimatedBreakTimeMinutes / 60.0);
  }, [starts_time, ends_time, setValue, record, primaryLocation, track_unpaid_breaks]);

  return (
    <>
      <Heading>Job Description</Heading>
      {!isEdit && (
        <ReferenceArrayInput
          reference="Location"
          label="Locations"
          perPage={50}
          source="location_ids"
          name="location_ids"
          validate={[required()]}
        >
          <AutocompleteArrayInput optionText="id" />
        </ReferenceArrayInput>
      )}
      {!isEdit && (
        <FormDataConsumer>
          {({ formData }) => {
            const options = formData.location_ids?.map((id) => ({ id, name: id })) ?? [];
            return <SelectInput choices={options} source="primary_location_id" />;
          }}
        </FormDataConsumer>
      )}
      {isEdit && (
        <BooleanInput
          label="Require Physical Signature"
          source="require_physical_signature"
          data-testid="assignment_edit-require_physical_signature"
        />
      )}
      {record?.is_w2 && (
        <BooleanInput
          label="Attendance Policy"
          source="attendance_policy"
          data-testid="assignment_edit-attendance_policy"
        />
      )}
      {isEdit && !record?.is_w2 && (
        <BooleanInput label="Pro Can Create Job" source="professional_can_create_job" />
      )}
      <BooleanInput
        label="Is Extension"
        source="is_extension"
        data-testid="assignment_edit-is_extension"
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.is_extension && (
            <NumberInput
              label="Original Assignment ID"
              source="original_assignment_id"
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <Grid container alignItems="flex-start">
              <MoneyInput
                label="Facility Hourly Bill Rate"
                source="charge_base_hourly_rate_cents"
                validate={[required(), minValue(2000, `Must be at least ${money(2000)}`)]}
                {...rest}
              />
              {formData.charge_base_hourly_rate_cents >= 30000 && (
                <Box ml={3} mt={1}>
                  <Alert severity="warning" data-testid="assignment_edit-bill_rate_alert">
                    Bill rate is $300 or higher
                  </Alert>
                </Box>
              )}
            </Grid>
          );
        }}
      </FormDataConsumer>
      <MoneyInput label="Hourly On Call Rate" source="charge_on_call_hourly_rate_cents" />
      {isEdit && checkAccess('view', 'Job') && <ProfessionalInput />}
      <AdministratorInput
        label="Account Manager"
        source="administrator_id"
        resettable
        filter={{ role: [ENUMS.Administrator.role.account_manager] }}
        sort={{ field: 'first_name', order: 'ASC' }}
      />
      <AdministratorInput
        label="Clinical Account Manager"
        source="recruiter_id"
        resettable
        filter={{
          role: [ENUMS.Administrator.role.sales_recruiter],
        }}
        sort={{ field: 'first_name', order: 'ASC' }}
      />
      <NumberInput
        label="Referrer Pro ID"
        source="referrer_professional_id"
        data-testid="assignment_edit-referrer_professional_id"
      />
      <TextInput
        label="VMS Order Number"
        source="order_number"
        data-testid="assignment_edit-order_number"
      />
      <NumberInput
        label="VMS Assignment ID"
        source="vms_assignment_id"
        data-testid="assignment_edit-vms_assignment_id"
      />

      <FormDataConsumer>
        {({ formData }) => {
          const { location_ids } = formData;
          return (
            <>
              <PositionInput
                multiple
                label="Positions"
                filter={{
                  active: true,
                  search: {
                    for_locations: location_ids
                      ? {
                          location_ids: location_ids,
                          for_job: false,
                        }
                      : undefined,
                  },
                }}
              />
              <SpecialtyInput
                multiple
                label="Specialties"
                positionSource="position_ids"
                forJobs={false}
                hideIfEmpty
                filter={{ active: true }}
              />
            </>
          );
        }}
      </FormDataConsumer>
      <ReferenceArrayInput
        label="Surgery Types"
        reference="SurgeryType"
        source="surgery_type_ids"
        validate={[required()]}
        perPage={100}
        sort={{ field: 'display_name', order: 'ASC' }}
      >
        <AutocompleteArrayInput
          optionText="display_name"
          data-testid="assignment_edit-surgery_type"
        />
      </ReferenceArrayInput>
      <PostingFields isAssignment />
      <EnumSelectInput
        label="Patient Population"
        source="patient_population"
        validate={[required()]}
      />
      {isEdit && (
        <BooleanInput
          label="Suspicious"
          source="suspicious"
          data-testid="assignment_edit-suspicious"
        />
      )}
      <TextInput
        label="Jobs Detail"
        source="jobs_detail"
        fullWidth
        multiline
        data-testid="assignment_edit-jobs_detail"
      />
      <TextInput
        label="Raw jobs detail"
        source="jobs_detail_raw"
        fullWidth
        multiline
        data-testid="assignment_edit-jobs_detail_raw"
      />
      <NumberInput
        label="Minimum Years of Experience"
        source="min_years_of_experience"
        data-testid="assignment_edit-min_years_of_experience"
      />
      <NumberInput
        label="Mileage Requirement"
        source="mileage_requirement"
        data-testid="assignment_edit-mileage_requirement"
      />
      <NumberInput
        label="Submittal Max"
        source="submittal_max"
        fullWidth
        multiline
        data-testid="assignment_edit-submittal_max"
      />
      <TextInput
        label="Notes"
        source="notes"
        fullWidth
        multiline
        data-testid="assignment_edit-notes"
      />
      <EnumSelectInput label="Booked Method" source="booked_method" />
      <EnumSelectInput label="Booked Source" source="booked_source" />
      {isEdit && record?.id && <EnumSelectInput label="Status" source="status" />}
      {isEdit && (
        <BooleanInput
          label="Completed Early"
          source="completed_early"
          data-testid="assignment_edit-completed_early"
        />
      )}
      <BooleanInput
        label="VMS Auto Update"
        source="vms_auto_update"
        defaultValue={true}
        data-testid="assignment_edit-vms_auto_update"
      />
      <FormDataConsumer>
        {({ formData }) =>
          shouldDisplayCancelWarning(formData, record) ? (
            <Typography gutterBottom color="error">
              You might want to use the CANCEL button
            </Typography>
          ) : null
        }
      </FormDataConsumer>
      {isEdit && <CancelButton />}

      <Heading>Schedule</Heading>
      <ConditionalPaused />
      <BooleanInput label="VMS Hold" source="on_hold" data-testid="assignment_edit-on_hold" />
      <DateInput
        label="Starts Date"
        source="starts_date"
        validate={[required()]}
        time_zone={timeZone}
      />
      <BooleanInput
        label="Has Flexible Starts Date"
        source="flexible_starts_date"
        data-testid="assignment_edit-flexible_starts_date"
      />
      <DateInput
        label="Ends Date"
        source="ends_date"
        validate={[required()]}
        time_zone={timeZone}
      />
      <JobDetailsTimeInputs time_zone={timeZone} />
      <SelectInput
        label="Number of Shifts per Week"
        source="number_of_shifts_per_week"
        validate={[required()]}
        alwaysOn
        choices={numberOfShiftsOptions}
        data-testid="assignment_edit-number_of_shifts_per_week"
      />
      <EnumSelectInput label="Schedule Type" source="schedule_type" />
      <BooleanInput
        label="Schedule Known"
        source="schedule_known"
        data-testid="assignment_edit-schedule_known"
      />
      {checkAccess('view', 'Job') && (
        <NumberInput
          label="Cancellation Fee Window"
          source="cancellation_fee_window"
          data-testid="assignment_edit-cancellation_fee_window"
        />
      )}
      {checkAccess('view', 'Job') && (
        <NumberInput
          label="Cancellation Fee Charge Hours"
          source="cancellation_fee_charge_hours"
          data-testid="assignment_edit-cancellation_fee_charge_hours"
        />
      )}
      {checkAccess('view', 'Job') && (
        <NumberInput
          label="Guaranteed Minimum Hours"
          source="guaranteed_minimum_hours"
          data-testid="assignment_edit-guaranteed_minimum_hours"
        />
      )}
      {/* {checkAccess('view', 'Job') && (
        <NumberInput
          label="Weekly Guaranteed Minimum Hours"
          source="weekly_guaranteed_minimum_hours"
        />
      )} */}
      <TextInput label="Unit" source="unit" data-testid="assignment_edit-unit" />
      <TextInput
        label="Float Requirements"
        source="float_requirements"
        fullWidth
        multiline
        data-testid="assignment_edit-float_requirements"
      />
      <TextInput
        label="Call Requirements"
        source="call_requirements"
        fullWidth
        multiline
        data-testid="assignment_edit-call_requirements"
      />
      <MoneyInput label="Referral Reward" source="referral_reward_cents" />
      <BooleanInput
        label="SSN Card Image"
        source="needs_item_url"
        data-testid="assignment_edit-ssn_card_image"
      />
      <BooleanInput
        label="Scrubs Provided"
        source="scrubs_provided"
        data-testid="assignment_edit-scrubs_provided"
      />
      <BooleanInput
        label="COVID Care"
        source="covid_care"
        data-testid="assignment_edit-covid_care"
      />
      <BooleanInput
        label="Track Unpaid Breaks"
        source="track_unpaid_breaks"
        data-testid="assignment_edit-track_unpaid_breaks"
      />
      <BooleanInput
        label="Local Only"
        source="local_only"
        data-testid="assignment_edit-local_only"
      />
      <ClinicalContactInput source="clinical_contact_account_id" />
      <TextInput
        label="VMS Contact Name"
        source="vms_contact_name"
        data-testid="assignment_edit-vms_contact_name"
      />
      <TextInput
        label="VMS Contact Email"
        source="vms_contact_email"
        type="email"
        validate={validateEmail}
        data-testid="assignment_edit-vms_contact_email"
      />
      <PhoneInput label="VMS Contact Phone" source="vms_contact_phone" />
      <NumberInput
        label="Number of Openings"
        source="number_of_openings"
        data-testid="assignment_edit-number_of_openings"
      />
      <NumberInput
        source="facility_cancellation_hours"
        data-testid="assignment_edit-facility_cancellation_hours"
      />
      <ReferenceInput
        perPage={100}
        sort={{ field: 'label', order: 'ASC' }}
        reference="EhrSystem"
        source="ehr_system_id"
      >
        <SelectInput resettable optionText="label" data-testid="assignment_edit-ehr_system" />
      </ReferenceInput>
      <BooleanInput
        source="variable_shift_times"
        data-testid="assignment_edit-variable_shift_times"
      />
    </>
  );
};

export default JobDetailsTab;

function ConditionalPaused({ record = { status: ENUMS.Assignment.status.available }, ...rest }) {
  return record &&
    [ENUMS.Assignment.status.available, ENUMS.Assignment.status.matched].includes(record.status) ? (
    <BooleanInput
      label="Pause Applications"
      source="paused"
      {...rest}
      data-testid="assignment_edit-pause_applications"
    />
  ) : null;
}

import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  TextField,
  BooleanField,
  Show,
  TabbedShowLayout,
  ArrayField,
  SingleFieldList,
  ChipField,
  Tab,
  TabbedShowLayoutTabs,
  ImageField,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import RateSettingsTab from './tabs/RateSettingsTab';
import EnumField from 'components/Enum/EnumField';
import useCheckAccess from 'hooks/useCheckAccess';
import TimestampFields from 'components/TimestampFields';
import StatePositionRateSettingsTab from 'components/tabs/StatePositionRateSettingsTab';

const StateShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show data-testid="state-show">
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <NumberField label="ID" source="id" />
          <TextField source="name" />
          <TextField source="abbreviation" />
          <BooleanField label="Popular Travel States" source="popular_travel_state" />
          <ImageField source="rectangle_image" />
          <ImageField source="square_image" />
          <BooleanField source="active" />
          <BooleanField label="Display By Hourly" source="display_by_hourly" />
          <BooleanField label="NLC" source="nlc" />
          <ArrayField source="markets">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <MoneyField label="Minimum Rate Cents" source="minimum_rate_cents" />
          <EnumField label="Covid Requirement" source="covid_requirement" />
          <TimestampFields />
        </Tab>
        {checkAccess('view', 'StateRateSetting') && (
          <Tab label="Rate Settings" path="rate-settings">
            <RateSettingsTab />
          </Tab>
        )}
        {checkAccess('view', 'StatePositionRateSetting') && (
          <Tab label="Position Rate Settings" path="position-rate-settings">
            <StatePositionRateSettingsTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

StateShow.query = gql`
  query state($id: Int!) {
    state(id: $id) {
      id
      name
      abbreviation
      active
      markets {
        id
        name
      }
      nlc
      rectangle_image
      square_image
      display_by_hourly
      minimum_rate_cents
      covid_requirement
      created_at
      updated_at
      popular_travel_state
    }
  }
`;

export default StateShow;

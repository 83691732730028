import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateInput from 'components/DateInput';
import useCheckAccess from 'hooks/useCheckAccess';

const InvoicesEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit mutationMode="pessimistic" redirect="show" data-testid="invoices-edit">
      <SimpleForm>
        {checkAccess('update', 'Invoice', 'due_date') && (
          <DateInput label="Due Date" source="due_date" />
        )}
        {checkAccess('update', 'Invoice', 'failed_count') && (
          <NumberInput
            label="Failed Count"
            source="failed_count"
            data-testid="invoices-edit-failed-count"
          />
        )}
        {checkAccess('update', 'Invoice', 'voucher_id') && (
          <TextInput label="Voucher ID" source="voucher_id" />
        )}
        {checkAccess('create', 'Invoice', 'voucher_processing_status') && (
          <EnumSelectInput label="Voucher Process Status" source="voucher_processing_status" />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default InvoicesEdit;

import React from 'react';
import { SimpleShowLayout, NumberField, TextField, useRecordContext } from 'react-admin';
import { Typography, Card, CardContent, Box, CardHeader } from '@mui/material';
import MoneyField from 'components/MoneyField';

const JobAside = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Job Billing" subheader={`ID ${record.id}`} />
      <CardContent>
        <SimpleShowLayout>
          <TextField label="Invoice ID" source="invoice_id" />
          <Box my={3} />
          <Typography variant="h6">Pay</Typography>
          <NumberField label="Payout Regular Hours" source="payout_regular_hours" />
          <NumberField label="Payout Overtime Hours" source="payout_overtime_hours" />
          <NumberField label="Payout Double Hours" source="payout_double_hours" />
          <MoneyField label="Pay Adjustments Total" source="expense_adjustments_total_cents" />
          <MoneyField
            label="Total Pay"
            source="payout_total_amount_cents"
            currencySource="payout_total_amount_currency"
          />
          <Box my={3} />
          <Typography variant="h6">Charge</Typography>
          <NumberField label="Charge Regular Hours" source="charge_regular_hours" />
          <NumberField label="Charge Overtime Hours" source="charge_overtime_hours" />
          <NumberField label="Charge Double Hours" source="charge_double_hours" />
          <MoneyField label="Charge Adjustments Total" source="revenue_adjustments_total_cents" />
          <MoneyField
            label="Total Charge"
            source="charge_total_amount_cents"
            currencySource="charge_total_amount_currency"
          />
        </SimpleShowLayout>
      </CardContent>
    </Card>
  );
};

export default JobAside;

import React from 'react';
import { useGetList, Loading, maxValue, minValue } from 'react-admin';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Checkbox,
  Typography,
} from '@mui/material';
import { DisplayHelpers } from 'utils/helpers';
import { useFormContext } from 'react-hook-form';
import MoneyInput from 'components/MoneyInput';
import { capitalize } from 'inflection';

const PayInvoicesTable = ({ record, amountLeft, showBillingGroupIDField }) => {
  const { setValue, watch } = useFormContext();
  const invoices = watch('invoices');
  const billing_group_balances = watch('billing_group_balances');
  const billing_group_ids = watch('billing_group_ids');

  const { data, isLoading } = useGetList('Invoice', {
    pagination: { page: 1, perPage: 9999 },
    sort: { field: 'ends_time', order: 'asc' },
    filter: {
      search: {
        billing_group_ids,
        statuses: ['past_due', 'disputed', 'due'],
      },
    },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return null;
  }

  const amount = amountLeft(record, invoices, billing_group_balances);

  return (
    <Box style={{ width: '100%' }}>
      <Typography color={amount < 0 ? 'error' : 'textSecondary'}>
        Transaction balance: {DisplayHelpers.money(amount)}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Invoice Id</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Voucher ID</TableCell>
            {showBillingGroupIDField && <TableCell>BillingGroup ID</TableCell>}
            <TableCell>Amount Due</TableCell>
            <TableCell>Amount To Pay</TableCell>
            <TableCell>Internal Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length && (
            <TableRow>
              <TableCell colSpan={4}>No invoices found for given users</TableCell>
            </TableRow>
          )}
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Checkbox
                  color="primary"
                  disabled={
                    (!invoices.map((v) => v.id).includes(row.id) && amount <= 0) ||
                    row.amount_due_cents <= 0
                  }
                  onChange={() => {
                    const amountToPay = Math.min(row.amount_due_cents, amount);

                    const mutableCopy = JSON.parse(JSON.stringify(row));
                    mutableCopy.amount_cents = amountToPay;

                    const wasPreviouslyChecked = invoices.map((v) => v.id).includes(row.id);
                    const rows = wasPreviouslyChecked
                      ? invoices.filter((v) => v.id !== row.id)
                      : [...invoices, mutableCopy];

                    setValue('invoices', rows);
                    setValue(`${row.id}.amount_cents`, wasPreviouslyChecked ? '' : amountToPay, {
                      shouldDirty: !wasPreviouslyChecked,
                      shouldValidate: true,
                    });
                    const currentAmount = amountLeft(record, invoices, billing_group_balances);
                    setValue('blockNextButton', currentAmount < 0);
                  }}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{capitalize(row.status.split('_').join(' '))}</TableCell>
              <TableCell>{row.voucher_id}</TableCell>
              {showBillingGroupIDField && <TableCell>{row.billing_group_id}</TableCell>}
              <TableCell>{DisplayHelpers.money(row.amount_due_cents)}</TableCell>
              <TableCell height={75} width={192}>
                {invoices.map((v) => v.id).includes(row.id) && (
                  <MoneyInput
                    sx={{ minWidth: '150px' }}
                    disabled={!invoices.map((v) => v.id).includes(row.id)}
                    name={`${row.id}.amount_cents`}
                    label=""
                    validate={[
                      minValue(1, 'Cannot less than $0.01'),
                      maxValue(
                        row.amount_due_cents,
                        `Cannot be more than ${DisplayHelpers.money(row.amount_due_cents)}`,
                      ),
                    ]}
                    onChange={({ target }) => {
                      const value = target?.value.length
                        ? parseInt(target.value.replace(/\D/g, ''))
                        : 0;
                      const rows = invoices.map((v) => {
                        if (v.id === row.id) {
                          v.amount_cents = value;
                        }
                        return v;
                      });
                      setValue('invoices', rows);
                      setValue(`${row.id}.amount_cents`, value);

                      const currentAmount = amountLeft(record, invoices, billing_group_balances);
                      const isTransactionBalanceLessThanZero = currentAmount < 0;
                      const isValueLessThanOrEqualToZero = value <= 0;
                      const isValueGreaterThanInvoiceAmount = value > row.amount_due_cents;

                      setValue(
                        'blockNextButton',
                        isTransactionBalanceLessThanZero ||
                          isValueLessThanOrEqualToZero ||
                          isValueGreaterThanInvoiceAmount,
                      );
                    }}
                  />
                )}
              </TableCell>
              <TableCell height={75} width={250}>
                {invoices.map((v) => v.id).includes(row.id) && (
                  <TextField
                    disabled={
                      !invoices.map((v) => v.id).includes(row.id) || row.amount_due_cents === 0
                    }
                    style={{ paddingBottom: '23px', width: '250px' }}
                    label=""
                    onChange={({ target }) => {
                      const rows = invoices.map((v) => {
                        if (v.id === row.id) {
                          v.notes = target.value;
                        }
                        return v;
                      });
                      setValue('invoices', rows);
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PayInvoicesTable;

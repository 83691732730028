import React from 'react';
import { Edit } from 'react-admin';
import RateSettingsForm from '../RateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const MarketRateSettingEdit = () => (
  <Edit mutationMode="pessimistic" transform={getTransform('market_id', 'settings')}>
    <RateSettingsForm />
  </Edit>
);

export default MarketRateSettingEdit;

import { useEffect } from 'react';
import { expirationStrategy } from 'utils/token';
import config from 'config';

export default function useTokenController() {
  useEffect(() => {
    const strategy = expirationStrategy(config.token_exp_ms / 2);
    strategy.run();
    return strategy.clear;
  }, []);
}

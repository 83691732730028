import React from 'react';
import { Create } from 'react-admin';
import RateSettingsForm from '../RateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const MarketRateSettingCreate = () => (
  <Create transform={getTransform('market_id', 'settings')}>
    <RateSettingsForm />
  </Create>
);

export default MarketRateSettingCreate;

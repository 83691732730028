import React from 'react';
import { gql } from '@apollo/client';
import { Show } from 'react-admin';
import PositionRateSettingsShow from '../PositionRateSettingsShow';
import PositionRateSettingsShowActions from '../PositionRateSettingsShowActions';

const MarketPositionRateSettingShow = () => (
  <Show actions={<PositionRateSettingsShowActions />}>
    <PositionRateSettingsShow />
  </Show>
);

MarketPositionRateSettingShow.query = gql`
  query marketPositionRateSetting($id: Int!, $include_parents: Boolean = true) {
    marketPositionRateSetting(id: $id, include_parents: $include_parents) {
      id
      market_id
      market {
        id
        display_name
      }
      position_id
      specialty_id
      position {
        id
        display_name
      }
      specialty {
        id
        label
      }
      active
      settings {
        fee_percent
        w2_fee_percent
        charge_rate_cents
        margin_percent_1099
        margin_w2_tcv_cents
        on_call_fee_percent
        margin_percent_w2_tcv
        margin_percent_w2_local
        margin_percent_w2_travel
        charge_overtime_multiplier
        payout_overtime_multiplier
        charge_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        payout_on_call_hourly_rate_currency
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        w2
        ten99_assignment
        ten99_per_diem
      }
      market_position_rate_setting {
        fee_percent
        w2_fee_percent
        charge_rate_cents
        margin_percent_1099
        margin_w2_tcv_cents
        on_call_fee_percent
        margin_percent_w2_tcv
        margin_percent_w2_local
        margin_percent_w2_travel
        charge_overtime_multiplier
        payout_overtime_multiplier
        charge_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        payout_on_call_hourly_rate_currency
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        w2
        ten99_assignment
        ten99_per_diem
      }
    }
  }
`;

export default MarketPositionRateSettingShow;
